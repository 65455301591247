import React from "react";
import black from "./../assets/final-black.png";
import white from "./../assets/final-white.png";

export default function SyncnftPage() {
  return (
    <section>
      <h1 className="text-3xl font-bold text-center text-gray-600 dark:text-gray-200 lg:text-4xl mb-10 mt-44">
        Always-in-sync NFT
      </h1>
      <div className="grid md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-2 items-center px-6 mx-auto">
        <div className="flex text-center justify-center md:justify-start">
          <img
            src={black}
            alt="Cellphone Illustration"
            className="w-2/3 h-2/3 block dark:hidden"
          />
          <img
            src={white}
            alt="Cellphone Illustration"
            className="w-2/3 h-2/3 dark:block hidden "
          />
        </div>

        <div className="md:order-first gap-8 grid px-4 lg:px-24 lg:pl-64 ">
          <p className="font-normal text-gray-600 dark:text-gray-300  text-xl">
            This breakthrough NFT provides an intuitive experience with content,
            access and rewards clearly visible and always-in-sync with the
            Ethereum blockchain network
            <br />
            <br /> Access to information is through a decentralised network,
            with support servers and nodes working 24/7 to display up-to-date
            information.
            <br />
            <br /> The technology package behind the scenes, ensures each VIP
            NFT delivers a smooth end-user experience including verification of
            access levels, unique content, rewards and invitations.
          </p>
        </div>
      </div>
    </section>
  );
}
