import React from 'react'

const card = ({ image, description, bg }) => {
    return (
        <React.Fragment>
            <div className="flex justify-center mb-4">
                <div className={"bg-white/80 shadow-2xl dark:bg-blue-800/50  dark:shadow-2xl md:h-full rounded-3xl dark:group-hover:shadow-white transition-all duration-300"}>
                    <img src={image} alt="bottom_image_2" className="text-center justify-center flex w-full h-full" />
                </div>
            </div>
            <div className=" rounded-xl shadow-2xl justify-center p-4 mt-4" style={bg}>
                <div className="flex justify-center">
                    <p className="font-regular text-md dark:text-white text-gray-600">{description}</p>
                </div>
            </div>
        </React.Fragment>
    )
}

export default card;
