import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
// import { Web3Provider } from "@ethersproject/providers";
import {
  ChainId,
  ThirdwebProvider,
  ThirdwebSDKProvider,
} from "@thirdweb-dev/react";
import { ethers } from "ethers";

const root = ReactDOM.createRoot(document.getElementById("root"));

const activeChainId = ChainId.Goerli;

const clientID = process.env.REACT_APP_THIRDWEB_CLIENT_ID;

root.render(
  <React.StrictMode>
    <ThirdwebProvider clientId={clientID} desiredChainId={activeChainId}>
      <ThirdwebSDKProvider
        activeChain={"goerli"}
        // Thirdweb Services are now required to include API Key
        // https://blog.thirdweb.com/changelog/api-keys-to-access-thirdweb-infra/
        clientId={clientID}
        // signer={new ethers.providers.Web3Provider(window.ethereum).getSigner()}
      >
        <App />
      </ThirdwebSDKProvider>
    </ThirdwebProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
