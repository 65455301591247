import React, { useContext } from "react";
import AppNavbar from "../../../components/app-navbar";
import AppFooter from "../../../components/footer";
import { ThemeContext } from "../../../App";
import img_background_light from "../../../components/assets/backgrounds/Bg-light2.png";
import img_background_dark from "../../../components/assets/backgrounds/Dark-desktop-1.png";
import Helmet from "../../../components/helmet";
import HeroPage from "./components/hero";
import AboutPage from "./components/about";
import BenefitsPage from "./components/benefits";
import SyncnftPage from "./components/syncnft";
import Levels from "./components/levels";

export default function PacificPage() {
  const { theme } = useContext(ThemeContext);
  return (
    <div
      className={`pt-16 overflow-hidden ${
        theme === "dark" ? "dark" : "light"
      } transition-all duration-300`}
      style={{
        backgroundImage: theme === "dark" ? `` : `url(${img_background_light})`,
        backgroundColor: theme === "dark" ? "rgb(5,3,115)" : "",
        backgroundSize: "cover",
        width: "100vw",
        minWidth: "100vw",
      }}
    >
      <Helmet title={"VIP NFT | Pacific Airshow"} />
      <AppNavbar />
      <HeroPage />
      <AboutPage />
      <Levels />
      <SyncnftPage />
      <BenefitsPage />
      <AppFooter />
    </div>
  );
}
