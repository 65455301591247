import React, { useState, useEffect } from "react";
import { BsCheck2Circle, BsStar } from "react-icons/bs";
import { AiOutlineThunderbolt } from "react-icons/ai";

const BenefitsDiv = (props) => {
  return (
    <div className="bg-[#F6F1E5] rounded-lg p-8">
      <div className="flex gap-2">
        <div className="rounded-full p-2 bg-[#EAEDFA]">
          {props.icon === "check" ? <BsCheck2Circle className="text-[#2A4DD0]" /> : ""}
          {props.icon === "thunder" ? <AiOutlineThunderbolt className="text-[#2A4DD0]" /> : ""}
          {props.icon === "star" ? <BsStar className="text-[#2A4DD0]" /> : ""}
        </div>
        <p className="font-bold text-xs text-[#2A4DD0] uppercase mt-2">
          {props.title}
        </p>
      </div>
      <p className="font-bold text-xl text-[#2A4DD0] my-4">{props.benefit}</p>
      <p className="text-xs text-black">{props.desc}</p>
    </div>
  );
};

export default function Benefits({ loadingListings, listings }) {

  const [listing, setListing] = useState([]);

  useEffect(() => {
    if(!loadingListings && listings[0].asset?.attributes[1]?.value) {
      const listingValue = JSON.parse(listings[0].asset?.attributes[1]?.value);
      setListing(listingValue);
    }
  }, [listings, loadingListings]);

  const getIconFromTitle = (title) => {
    switch(title) {
      case 'Discount':
      case 'Networking':
        return 'check';
      case 'Upgrade':
      case 'Rarity':
        return 'thunder';
      case 'Content':
      case 'Features':
        return 'star';
      default:
        return 'check';
    }
  }

  return (
    <>
      {!loadingListings &&  (
        <section className="container px-6 py-4 mt-4 mb-4 mx-auto">
          <div className="grid grid-cols-1 md:gap-8 mt-8 xl:mt-4 xl:gap-16 lg:grid-cols-4 xl:grid-cols-3">
            <div className="col-span-2">
              <div className="rounded-lg drop-shadow-2xl bg-white p-8 mt-8 xl:mt-4">
                <h1 className="font-semibold text-2xl text-center text-[#212429]">
                  Own First Access Rights
                </h1>
                <div class="hidden sm:block" aria-hidden="true">
                  <div class="py-5">
                    <div class="border-t border-gray-200"></div>
                  </div>
                </div>
                <h1 className="font-bold text-xl text-[#2A4DD0] mb-4">Benefits</h1>
                <div class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-4">
                  {/* Map the listing to BenefitsDiv */}
                  {listing.map((item, index) => 
                    <BenefitsDiv
                      key={index}
                      title={item.title}
                      benefit={item.benefit}
                      desc={item.description}
                      icon={getIconFromTitle(item.title)}
                    />
                  )}

                  {/* Existing Static Code */}
                  {/* <BenefitsDiv
                    title={"Discount"}
                    benefit={"10% off"}
                    desc={"10% discount on Premium Entry Tickets."}
                    icon={"check"}
                  />

                  <BenefitsDiv
                    title={"Upgrade"}
                    benefit={"VIP Diamond"}
                    desc={"Upgrade to VIP Diamond for Chalets"}
                    icon={"thunder"}
                  />

                  <BenefitsDiv
                    title={"Content"}
                    benefit={"Unique"}
                    desc={"NFT with unique Pacific Airshow content."}
                    icon={"star"}
                  />

                  <BenefitsDiv
                    title={"Networking"}
                    benefit={"Private Chat"}
                    desc={"Private Chat for NFT holders only."}
                    icon={"check"}
                  />

                  <BenefitsDiv
                    title={"Rare"}
                    benefit={"1 of 40"}
                    desc={"1 of only 40 Palladium NFTs."}
                    icon={"thunder"}
                  />

                  <BenefitsDiv
                    title={"Features"}
                    benefit={"Dynamic"}
                    desc={
                      "More exciting features to be revealed for 'Dynamic' NFTs."
                    }
                    icon={"star"}
                  /> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}
