import React from "react";
import future1 from "./../assets/future1.png";
import future2 from "./../assets/future2.png";
import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io";
import AliceCarousel from "react-alice-carousel";

export default function Future() {
  const bg_btn = {
    background: "linear-gradient(90.11deg, #28A2C3 2.03%, #DA3E3F 97.3%)",
  };

  const line = {
    width: 0,
    height: "100%",
    bottom: 0,
    borderBottom: "250px solid #7388F2",
    borderRightWidth: "98vw",
    borderRightStyle: "solid",
    borderRightColor: "transparent",
    left: 0,
    right: 0,
    top: 0,
    position: "absolute",
    zIndex: "0",
  };

  const responsive = {
    0: { items: 1 },
    // 568: { items: 2 },
    // 1024: { items: 3 },
  };
  return (
    <section className="bg-[#EAF3FD] relative text-center">
      <div id="triangle-topleft" style={line} className="hidden md:block"></div>
      <div className="container px-6 py-10 mx-auto pb-10 justify-center">
        <p className="text-gray-600 uppercase font-semibold text-xl my-8">
          Smart ticket benefits
        </p>
        <h1 className="text-3xl font-bold text-center text-gray-600 lg:text-4xl">
          The future of Ticketing has arrived!
        </h1>
        <p className="text-gray-600 md:px-60 my-16">
          Gone are the days of dusty ticket stubs in your jeans and lost
          memories of epic past experiences. Through NFT technology, tickets
          become permanent memorabilia, whilst maintaining privacy. These assets
          unlock benefits, which can be sold or transferred without third party
          intervention.
        </p>
        <div className="justify-center text-center align-center flex">
          <img src={future1} alt="" className="z-10" />
          <img src={future2} alt="" className="absolute" />
        </div>

        <div className="md:pt-36 md:p-7 md:w-4/6 mx-auto md:mt-56">
          <div className="justify-center p-5 container w-9/12 mx-auto h-56">
            <p
              // id="title"
              className="text-3xl font-bold text-center text-gray-600 lg:text-4xl pt-24 relative"
            >
              NFT Ticketing considerations
            </p>
          </div>
          <div className="relative ">
            <AliceCarousel
              mouseTracking
              items={items}
              controlsStrategy="default"
              fadeOutAnimation={true}
              mouseDragEnabled={true}
              playButtonEnabled={false}
              autoHeight={true}
              responsive={responsive}
              infinite
              renderPrevButton={() => {
                return (
                  <IoIosArrowDropleft
                    className="text-[#7953E9] absolute left-0 top-1/3 hover:opacity-50 hover:cursor-pointer hover:scale-125 transition-all duration-300"
                    size={30}
                  />
                );
              }}
              renderNextButton={() => {
                return (
                  <IoIosArrowDropright
                    className="text-[#7953E9] absolute right-0 top-1/3 hover:opacity-50 hover:cursor-pointer hover:scale-125 transition-all duration-300"
                    size={30}
                  />
                );
              }}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

const items = [
  <div className="justify-center p-5 container w-9/12 mx-auto rounded-xl bg-gradient-to-r from-[#A591F1] to-[#7953E9]  flex h-80">
    <div className="my-auto h-fit">
      <h1 className="h1 text-center text-3xl text-white font-bold">Revenue</h1>
      <p className="text-white text-lg mt-3 text-center">
        Ticket issuers can collect royalties every time a ticket is traded,
        generating significant additional revenue streams for many events.
        Royalties are typically between 5% and 10%.
      </p>
    </div>
  </div>,

  <div className="justify-center p-5 container w-9/12 mx-auto rounded-xl bg-gradient-to-r from-[#A591F1] to-[#7953E9]  h-full flex">
    <div className="my-auto h-fit">
      <h1 className="h1 text-center text-3xl text-white font-bold">
        No Fake Ticket (NFT)
      </h1>
      <p className="text-white text-lg mt-3 text-center">
        The benefit of a blockchain is that records are immutable, which means
        they can never be changed. Hence, tickets-as-NFTs can be verified by
        everyone, not just the issuer.
      </p>
    </div>
  </div>,

  <div className="justify-center p-5 container w-9/12 mx-auto rounded-xl bg-gradient-to-r from-[#A591F1] to-[#7953E9]  h-full flex">
    <div className="my-auto h-fit">
      <h1 className="h1 text-center text-3xl text-white font-bold">
        Transparency
      </h1>
      <p className="text-white text-lg mt-3 text-center">
        The marketplace for trading NFTs is immediately accessible and globally
        tradeable, as ownership is verified and traded directly on the
        blockchain. The transparency of the blockchain ledger ensures the
        marketplace is transparent. You can see how many tickets there are, what
        they have sold for, what they are listed for, how many are for
        sale...etc etc. No third party can manipulate the numbers.
      </p>
    </div>
  </div>,

  <div className="justify-center p-5 container w-9/12 mx-auto rounded-xl bg-gradient-to-r from-[#A591F1] to-[#7953E9]  h-full flex">
    <div className="my-auto h-fit">
      <h1 className="h1 text-center text-3xl text-white font-bold">
        Collectible
      </h1>
      <p className="text-white text-lg mt-3 text-center">
        The term "Digital Collectable" is rapidly becoming the preferred term to
        replace "NFT". The term also accurately encapsulates the essence of
        "NFTs as tickets". You can have a permanent memory of every event you
        have attended, the experiences and moments in history perfectly stored.
        Value could be added and curated, depending on how the NFT is crafted by
        the issuer.
      </p>
    </div>
  </div>,

  <div className="justify-center p-5 container w-9/12 mx-auto rounded-xl bg-gradient-to-r from-[#A591F1] to-[#7953E9]  h-full flex">
    <div className="my-auto h-fit">
      <h1 className="h1 text-center text-3xl text-white font-bold">Control</h1>
      <p className="text-white text-lg mt-3 text-center">
        Once you own a digital asset, you control what you do with it. You can
        collect it, add value to it, transfer it, sell it or store it off-line
        for future access. The world of blockchain ownership is in its infancy,
        with new ideas on how to empower owners of these digital assets emerging
        every day.
      </p>
    </div>
  </div>,

  <div className="justify-center p-5 container w-9/12 mx-auto rounded-xl bg-gradient-to-r from-[#A591F1] to-[#7953E9]  h-full flex">
    <div className="my-auto h-fit">
      <h1 className="h1 text-center text-3xl text-white font-bold">
        Community
      </h1>
      <p className="text-white text-lg mt-3 text-center">
        Through the creation of NFTs connected to specific artists and specific
        events or locations, you can choose to connect with like-minded fans and
        even directly with artists and creators. Connect anonymously or openly,
        the choice is yours. Direct, open, exclusive access and communications
        enabled through NFT ownership.
      </p>
    </div>
  </div>,
  <div className="justify-center p-5 container w-9/12 mx-auto rounded-xl bg-gradient-to-r from-[#A591F1] to-[#7953E9]  h-full flex">
    <div className="my-auto h-fit">
      <h1 className="h1 text-center text-3xl text-white font-bold">
        Aren't NFTs bad for the environment?
      </h1>
      <p className="text-white text-lg mt-3 text-center">
        No. Since Ethereum moved to Proof Of Stake (POS), NFTs are
        environmentally friendly.
        {` `}
        <a
          href="https://ethereum.org/en/energy-consumption/"
          target="_blank"
          rel="noreferrer"
          className=" text-teal-300 hover:underline"
        >
          Visit the ethereum.org website for more information.
        </a>
      </p>
    </div>
  </div>,
  <div className="justify-center p-5 container w-9/12 mx-auto rounded-xl bg-gradient-to-r from-[#A591F1] to-[#7953E9]  h-full flex">
    <div className="my-auto h-fit">
      <h1 className="h1 text-center text-3xl text-white font-bold">
        Why are you creating NFTs?
      </h1>
      <p className="text-white text-lg mt-3 text-center">
        Digital collectibles offer a range of benefits. From direct connection
        to artists, other fans and organisers through to early ticket access and
        lasting memories of your favourite experiences. Turn your fandom into an
        asset you can hold, build or sell.
      </p>
    </div>
  </div>,
];
