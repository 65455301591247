import React from 'react'

export default function divider() {
    return (
        <div class="hidden sm:block" aria-hidden="true">
            <div class="py-5">
                <div class="border-t border-gray-800 dark:border-gray-200"></div>
            </div>
        </div>
    )
}
