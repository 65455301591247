import React, { useContext, useState } from "react";
import AppNavbar from "../../components/app-navbar";
import AppFooter from "../../components/footer";
import img_background_light from "../../components/assets/backgrounds/Bg-light2.png";
import img_background_dark from "../../components/assets/backgrounds/Dark-desktop-1.png";
import { ThemeContext } from "../../App";
import Helmet from "./../../components/helmet";
import heroimg from "./assets/heroimg.png";
import heroimglayer from "./assets/heroimglayer.png";
import goldcardimg from "./assets/goldcard.png";
import logogold from "./assets/logogold.png";
import palladiumintersect from "./assets/palladiumintersect.png";
import palladiumlogo from "./assets/palladiumlogo.png";
import diamondlogo from "./assets/diamondlogo.png";
import diamondintersect from "./assets/diamondintersect.png";
import Works from "./components/works";
import Collectibles from "./components/collectibles";
import palogo from "./assets/palogo.png";

export default function MarketplacePage() {
  const { theme } = useContext(ThemeContext);
  const [submenu, setSubmenu] = useState(false);

  const goldcard = {
    backgroundImage: `url(${goldcardimg})`,
    backgroundSize: "cover",
    height: "218px",
    backgroundRepeat: "no-repeat",
    backgroundColor: "#B79435",
  };

  const palladiumcard = {
    backgroundImage: `url(${palladiumintersect})`,
    backgroundSize: "cover",
    height: "218px",
    backgroundRepeat: "no-repeat",
    backgroundColor: "#B97362",
  };

  const diamondcard = {
    backgroundImage: `url(${diamondintersect})`,
    backgroundSize: "cover",
    height: "218px",
    backgroundRepeat: "no-repeat",
    backgroundColor: "#8FACB3",
  };

  return (
    <div className="pt-16 lg:pt-20 overflow-hidden light transition-all duration-300">
      <Helmet title={"VIPNFT | Pacific Airshow"} />
      <AppNavbar />
      <div className="relative">
        <div
          className="hero w-full h-screen z-0"
          style={{
            backgroundImage: `url(${heroimg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}>
          <div
            className="absolute top-0 left-0 w-full h-screen xl:py-[12rem] xl:px-[12rem] z-0"
            style={{
              backgroundImage: `url(${heroimglayer})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}>
            <div className="mt-10 ml-10 mr-10 lg:m-0 grid grid-cols-1 xl:grid-cols-2">
              <div>
                <img src={palogo} alt="palogo" className="" />
                <p className="text-white text-[60px] font-bold">
                  VIPNFT landing soon...
                </p>
                <p className="text-[24px] text-white my-2">
                  We've re-defined the Airshow experience,
                  <br />
                  now own the ultimate fan experience!
                </p>

                <div className="grid grid-cols-1 xl:grid-cols-2 my-12">
                  <div className="ml-5">
                    <ul className="list-disc text-[16px] text-white">
                      <li>Permanent memento of event</li>
                      <li>Opportunity to connect with performers</li>
                      <li>Networking ability for fans</li>
                    </ul>
                  </div>
                  <div className="ml-5">
                    <ul className="list-disc text-[16px] text-white">
                      <li>Enhanced experience - Bragging rights</li>
                      <li>Unique content allocated to NFT holders</li>
                      <li>First access to all things NFT</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <img src={heroimg} alt="" className="w-screen" /> */}
        </div>

        <div className="flex flex-wrap top-16 lg:top-10 lg:-mb-10 lg:-mt-60 container mx-auto relative z-[2]">
          <div
            className="p-8 w-full sm:w-1/2 lg:w-1/3 sm:mb-0 mb-6 hover:cursor-pointer"
            // onClick={() => {
            //   window.location.href = "/marketplace";
            // }}
          >
            <div className="bg-white shadow-2xl dark:shadow-2xl md:h-full rounded-3xl dark:hover:shadow-white transition-all duration-300 relative">
              <div className="rounded-2xl p-3 body-font " style={goldcard}>
                <div className="flex">
                  <div className="grid flex-auto md:w-1/2 pl-4">
                    <h2 className="text-[18px] font-bold text-left title-font text-white mt-6 mb-2">
                      Gold VIPNFT
                    </h2>
                    <ul className="list-disc text-[12px] font-normal ml-2 text-left title-font text-white">
                      <li>Early Bird <b>Premier</b> Tickets</li>
                      <li>10% off on ticket purchase until next years event</li>
                      <li>Private Chat Forum</li>
                      <li>Own <b>unique</b> Gold Coast inaugural airshow content</li>
                      <li>Invites to exclusive events</li>
                    </ul>
                  </div>
                  <div className="flex-auto md:w-1/2">
                    <img src={logogold} alt="" className="w-100" />
                  </div>
                </div>
              </div>
              <div className="">
                <div className="px-2 m-8">
                  <div className="flex justify-start">
                    <p className="text-[16px] font-light">1 of 80 NFTS</p>
                  </div>
                  <div className="flex justify-between">
                    <h2 className="text-[30px] font-medium title-font text-[#212429]">
                      Gold VIPNFT
                    </h2>
                    <h2 className="text-[30px] font-medium text-center title-font text-[#2A4DD0]">
                      $299
                    </h2>
                  </div>

                  <div className="my-6 lg:my-8">
                    <p className="text-[16px] font-light">
                      Beat the Queue with Early Bird Ticket Access as a 
                      Gold VIPNFT holder. Receive a 10% discount on ticket purchase 
                      based on category. Access private community chat for NFT holders 
                      only. Receive invitations to exclusive events. Own unique Digital
                      Content, and entry to prize draws!
                    </p>
                  </div>
                  <ul className="list-disc mt-4 lg:mt-10 text-[16px] font-light">
                    <li>Early Bird Access to Premier Tickets</li>
                    <li>10% off on ticket purchase until next years event</li>
                    <li>Private Chat Forum for NFT holders only</li>
                    <li>Own unique Gold Coast Airshow content</li>
                    <li>More exciting features to be revealed for these "Dynamic" NFTs</li>
                  </ul>
                </div>
                <div className="px-4 lg:mt-28">
                  <button
                    className="relative lg:absolute bottom-0 lg:bottom-4 w-full lg:w-11/12 p-4 mb-4 md:mb-0 bg-gradient-to-r from-[#2A4DD0] to-[#6E8CFF] text-white hover:border-2 border-white/70 border-2 font-semibold rounded-lg transition-all duration-300 py-4 shadow-md hover:opacity-60"
                    onClick={() => {
                      window.location.href =
                        "https://vipnft.fan/creator/pacific-airshow";
                    }}>
                    <p className="text-md md:text-md ">Learn More</p>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            className="p-8  w-full sm:w-1/2 lg:w-1/3 sm:mb-0 mb-6 hover:cursor-pointer"
            // onClick={() => {
            //   window.location.href = "/marketplace";
            // }}
          >
            <div className="bg-white shadow-2xl dark:shadow-2xl md:h-full rounded-3xl dark:hover:shadow-white transition-all duration-300 relative">
              <div className="rounded-2xl p-3 body-font" style={palladiumcard}>
                <div className="flex">
                  <div className="grid flex-auto md:w-1/2 pl-4">
                    <h2 className="text-[18px] font-bold text-left title-font text-white mt-6 mb-4">
                      Palladium VIPNFT
                    </h2>
                    <ul className="list-disc text-[12px] font-normal ml-2 text-left title-font text-white">
                      <li>Early Bird <b>Cabana</b> Tickets</li>
                      <li>10% off on ticket purchase until next years event</li>
                      <li>Private Chat Forum</li>
                      <li>Own <b>unique</b> Gold Coast inaugural airshow content</li>
                      <li>Invites to VIP-only events</li>
                    </ul>
                  </div>
                  <div className="flex-auto md:w-1/2">
                    <img src={palladiumlogo} alt="" className="w-100" />
                  </div>
                </div>
              </div>
              <div className="">
                <div className="px-2 m-8">
                  <div className="flex justify-start">
                    <p className="text-[16px] font-light">1 of 4 NFTS</p>
                  </div>
                  <div className="flex justify-between">
                    <h2 className="text-[30px] font-medium title-font text-[#212429]">
                      Palladium VIPNFT
                    </h2>
                    <h2 className="text-[30px] font-medium text-center title-font text-[#2A4DD0]">
                      $4,990
                    </h2>
                  </div>

                  <div className="my-6 lg:my-8">
                    <p className="text-[16px] font-light">
                      Palladium NFT unlocks First Access to Cabana tickets
                      plus all the Gold NFT benefits. Be the first to receive
                      NFT content offered by performers and invitations to
                      special events. 10% off premium ticket prices (limits
                      apply). Gold VIP Benefits plus:
                    </p>
                  </div>
                  <ul className="list-disc mt-4 lg:mt-10 text-[16px] font-light">
                    <li>First Access to Cabanas Tickets</li>
                    <li>10% off on ticket purchase valid for 1 year</li>
                    <li>Invitations to VIP-only events</li>
                    <li>Private Chat Forum for NFT holders only</li>
                    <li>Own an NFT with unique Gold Coast Airshow content</li>
                    <li>More exciting features to be revealed for these "Dynamic"NFTs</li>
                  </ul>
                </div>
                <div className="px-4 lg:mt-28">
                  <button
                    className="relative lg:absolute bottom-0 lg:bottom-4 w-full lg:w-11/12 p-4 mb-4 md:mb-0 bg-gradient-to-r from-[#2A4DD0] to-[#6E8CFF] text-white hover:border-2 border-white/70 border-2 font-semibold rounded-lg transition-all duration-300 py-4 shadow-md hover:opacity-60"
                    onClick={() => {
                      window.location.href =
                        "https://vipnft.fan/creator/pacific-airshow";
                    }}>
                    <p className="text-md md:text-md ">Learn More</p>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            className="p-8  w-full sm:w-1/2 lg:w-1/3 sm:mb-0 mb-6 hover:cursor-pointer"
            // onClick={() => {
            //   window.location.href = "/marketplace";
            // }}
          >
            <div className="bg-white shadow-2xl dark:shadow-2xl md:h-full rounded-3xl dark:hover:shadow-white transition-all duration-300 relative">
              <div className="rounded-2xl p-3 body-font " style={diamondcard}>
                <div className="flex">
                  <div className="grid flex-auto md:w-1/2 pl-4">
                    <h2 className="text-[18px] font-bold text-left title-font text-white mt-6 mb-4">
                      Diamond VIPNFT
                    </h2>
                    <ul className="list-disc text-[12px] font-normal ml-2 text-left title-font text-white">
                      <li>Early Bird <b>Chalet</b> Tickets</li>
                      <li>10% off on ticket purchase until next years event</li>
                      <li>Private Chat Forum</li>
                      <li>Own <b>unique</b> Gold Coast inaugural airshow content</li>
                      <li>Invites to meet performers</li>
                    </ul>
                  </div>
                  <div className="flex-auto md:w-1/2">
                    <img src={diamondlogo} alt="" className="w-100" />
                  </div>
                </div>
              </div>
              <div className="">
                <div className="px-2 m-8">
                  <div className="flex justify-start">
                    <p className="text-[16px] font-light">1 of 2 NFTS</p>
                  </div>
                  <div className="flex justify-between">
                    <h2 className="text-[30px] font-medium title-font text-[#212429]">
                      Diamond VIPNFT
                    </h2>
                    <h2 className="text-[30px] font-medium text-center title-font text-[#2A4DD0]">
                      $14,990
                    </h2>
                  </div>

                  <div className="my-6 lg:my-8">
                    <p className="text-[16px] font-light">
                      The ultimate VIP NFT from Pacific Airshow. Besides first
                      access to Chalets, you receive invitations to private
                      VIP-only events, and invitations to meet performers.
                      All the features of Palladium and 10% off all tickets 
                      (limits apply). Palladium VIP benefits plus:
                    </p>
                  </div>
                  <ul className="list-disc mt-4 lg:mt-10 text-[16px] font-light">
                    <li>First Access to Chalets Tickets</li>
                    <li>10% off on ticket purchase valid for 1 year</li>
                    <li>Behind the scenes VIP Access</li>
                    <li>Invitations to meet performers</li>
                    <li>Private Chat Forum for NFT holders only</li>
                    <li>Own an NFT with unique Gold Coast Airshow content</li>
                    <li>1 of only 2 Diamond VipNFTs ever</li>
                    <li>More exciting features to be revealed for these "Dynamic" NFTs</li>
                  </ul>
                </div>
                <div className="px-4 lg:mt-28">
                  <button
                    className="relative lg:absolute bottom-0 lg:bottom-4 w-full lg:w-11/12 p-4 mb-4 md:mb-0 bg-gradient-to-r from-[#2A4DD0] to-[#6E8CFF] text-white hover:border-2 border-white/70 border-2 font-semibold rounded-lg transition-all duration-300 py-4 shadow-md hover:opacity-60"
                    onClick={() => {
                      window.location.href =
                        "https://vipnft.fan/creator/pacific-airshow";
                    }}>
                    <p className="text-md md:text-md ">Learn More</p>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Collectibles />

      <section className="container px-6 py-4 mt-4 mb-4 mx-auto">
        <div className="grid grid-cols-1 md:gap-8 mt-8 xl:mt-4 xl:gap-16 lg:grid-cols-4 xl:grid-cols-4"></div>
      </section>

      <AppFooter />
    </div>
  );
}
