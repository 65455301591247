import React from 'react'
import { IoLocationSharp } from "react-icons/io5"

export default function EventCard() {
    return (
        <div className="flex items-center space-x-4 my-2 bg-white/5 rounded-xl shadow-2xl p-4 hover:bg-white/30 transition-all duration-500 hover:shadow-yellow-500 hover:shadow-lg">
            <div className="flex-1 min-w-0">
                <p className="text-sm font-medium text-yellow-500 truncate">
                    Gold Class Perk
                </p>
                <p className="text-2xl font-bold text-gray-500 truncate dark:text-gray-400">
                    Dua Lipa Meet & Greet
                </p>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                    The ten hours meet and greet with Dua Lipa, taking place on a rainy day in the central on Sydney
                </p>
                <p className="text-md text-blue-500 truncate mt-4 flex">
                    <IoLocationSharp className="text-blue-500 mt-1" /> Sydney Harbour Bridge
                </p>
            </div>
            <div className="flex-shrink-0">
                <img
                    className="inline-block h-20 w-20 rounded-full ring-4 ring-white"
                    src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    alt=""
                />
            </div>
        </div>
    )
}
