import React from "react";
import { AiOutlineShoppingCart, AiOutlineDollarCircle } from "react-icons/ai";
import { SiOpenaccess } from "react-icons/si";
import bgimagehiw from "./../assets/bgimagehiw.png";

export default function howitworks() {
  return (
    <React.Fragment>
      <div
        className="py-20"
        style={{
          backgroundImage: `url(${bgimagehiw})`,
          backgroundSize: "cover",
          width: "100vw",
          minWidth: "100vw",
        }}
      >
        <section className="container-fluid md:container md:mx-auto xl:mt-60 mx-8">
          <div className="grid grid-cols-1 xl:grid-cols-2">
            <div>
              <p className="text-base font-semibold tracking-widest text-transparent bg-clip-text bg-gradient-to-r from-[#2a4dd0] to-[#6e8cff] uppercase">
                How it works
              </p>
              <h4 className="text-[#212429] text-6xl font-bold my-8 xl:my-0">
                3 Easy Steps
              </h4>
            </div>
            <div>
              <div className="flex justify-end mt-8">
                {/* <button
                  className="bg-gradient-to-r from-[#2a4dd0] to-[#6e8cff] text-white font-semibold rounded-lg transition-all duration-300 py-4 px-24 shadow-md hover:opacity-50"
                  onClick={() => {
                    window.location.href = "/create";
                  }}
                >
                  <p className="text-lg md:text-lg ">Create</p>
                </button> */}
              </div>
              <p className="text-base text-right text-black">
                {/* VIPNFT is a marketplace for artists and event organisers to
                create NFTs with unique content and utility for fans. Create,
                own, access and connect through VIPNFT. */}
              </p>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-12 my-12">
            <div className="bg-[#F3F4F8] rounded-lg text-center p-12 relative drop-shadow-2xl">
              <div className="flex justify-center">
                <AiOutlineShoppingCart className="text-6xl text-[#2B4ED1]" />
              </div>
              <h4 className="font-bold text-2xl text-[#2B4ED1] my-2">Buy</h4>
              <div className="flex justify-center">
                <ul class="list-disc my-8 text-base text-left">
                  <li>Purchase with credit card or web3 wallet.</li>
                  <li>No wallet? No problem, we will provide one!</li>
                </ul>
              </div>
              <div className="rounded-full px-8 py-6 absolute -bottom-10 left-0 right-0 mx-auto bg-white w-24">
                <p className="text-[#B8C3ED] text-5xl font-bold">1</p>
              </div>
            </div>

            <div className="bg-white rounded-lg text-center p-12 relative drop-shadow-2xl">
              <div className="flex justify-center">
                <SiOpenaccess className="text-6xl text-[#2B4ED1]" />
              </div>
              <h4 className="font-bold text-2xl text-[#2B4ED1] my-2">Access</h4>
              <div className="flex justify-center">
                <ul class="list-disc my-8 text-base text-left">
                  <li>Unlock early ticket purchase option.</li>
                  <li>Exclusive invitations, chats and rewards.</li>
                  <li>Receive new offers from artists and creators.</li>
                </ul>
              </div>
              <div className="rounded-full px-8 py-6 absolute -bottom-10 left-0 right-0 mx-auto bg-[#F3F4F8] w-24">
                <p className="text-[#B8C3ED] text-5xl font-bold">2</p>
              </div>
            </div>

            <div className="bg-[#F3F4F8] rounded-lg text-center p-12 relative drop-shadow-2xl">
              <div className="flex justify-center">
                <AiOutlineDollarCircle className="text-6xl text-[#2B4ED1]" />
              </div>
              <h4 className="font-bold text-2xl text-[#2B4ED1] my-2">Sell</h4>
              <div className="flex justify-center">
                <ul class="list-disc my-8 text-base text-left">
                  <li>Sell or rent out your digital asset.</li>
                  <li>Set the price and terms of sale.</li>
                </ul>
              </div>
              <div className="rounded-full px-8 py-6 absolute -bottom-10 left-0 right-0 mx-auto bg-white w-24">
                <p className="text-[#B8C3ED] text-5xl font-bold">3</p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
}
