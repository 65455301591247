import React from "react";
import accessdiamond from "./../assets/accessdiamond.png";
import accessgold from "./../assets/accessgold.png";
import accesspalladium from "./../assets/accesspalladium.png";
import uniongold from "./../assets/uniongold.png";
import uniondiamond from "./../assets/uniondiamond.png";
import unionpalladium from "./../assets/unionpalladium.png";

export default function Access() {
  return (
    <section
      id="collections"
      className="md:grid md:grid-cols-1 gap-4 items-center container px-6 py-2 mx-auto pb-10 my-60"
    >
      <div className="flex justify-center gap-4 items-center">
        <h1 className="text-[56px] text-black font-bold text-center">Access</h1>
      </div>
      <div className="flex justify-center text-[18px]">
        <p>
          Features and benefits are defined across three levels. Creators and
          artists moderate VIPNFT levels to create unique offerings for fans.
        </p>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 mt-20">
        <div className="relative">
          <div className="flex justify-center">
            <img src={accessgold} alt="accessgold" />
          </div>
          <img src={uniongold} alt="uniongold" className="absolute left-24" />
          <div className="grid grid-cols-1 ml-8 mt-8 px-24">
            <p className="text-[18px] font-semibold">Priority VIPNFT Access</p>
            <p className="text-[16px]">
              Generally used for first right of refusal to purchase general
              entry tickets.
            </p>
          </div>
        </div>
        <div className="relative">
          <div className="flex justify-center">
            <img src={accesspalladium} alt="accesspalladium" />
          </div>
          <img
            src={unionpalladium}
            alt="unionpalladium"
            className="absolute left-24"
          />
          <div className="grid grid-cols-1 ml-8 mt-8 px-24">
            <p className="text-[18px] font-semibold">
              The Premium VIPNFT Package
            </p>
            <p className="text-[16px]">
              Give premium guests first access to premium tickets. Invitations
              to exclusive events.
            </p>
          </div>
        </div>

        <div className="relative">
          <div className="flex justify-center">
            <img src={accessdiamond} alt="accessdiamond" />
          </div>
          <img
            src={uniondiamond}
            alt="uniondiamond"
            className="absolute left-24"
          />
          <div className="grid grid-cols-1 ml-8 mt-8 px-24">
            <p className="text-[18px] font-semibold">
              The Ultimate Fan Experience
            </p>
            <p className="text-[16px]">
              Only for the most passionate and loyal fans. Backstage access,
              meet performers, invitations to VIP invitations to limited access
              events.
            </p>
          </div>
        </div>
      </div>
      <div className="flex justify-end mt-24">
        <div className="grid w-1/3">
          <p>
            * These features are a <span className="font-bold">guide only</span>
            . Each collection will have features, benefit and access defined by
            the creator and clearly visible on the NFT purchased.
          </p>
        </div>
      </div>
    </section>
  );
}
