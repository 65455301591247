import React, { useState, useEffect } from "react";
import { IoPersonCircleOutline } from "react-icons/io5";
import { TiThMenu } from "react-icons/ti";
import { FiSliders } from "react-icons/fi";
import { FaInbox } from "react-icons/fa";
import { IoIosSettings } from "react-icons/io";
import axios from "axios";

const divStyles = {
  boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.25)",
  borderRadius: "25px",
  backdropFilter: "blur(2px)",
  background: "rgba(255, 255, 255, 0.5)",
};

export default function ProfileResponsive({ publicKey }) {
  const [submenu, setSubmenu] = useState(false);
  const [data, setData] = useState(null);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://api2.ezy3.io/api/users?filters[wallet][publicKey]=${publicKey}`
      );
      console.log("response 1", response.data[0].phoneNumber);
      setData({
        phoneNumber: response.data[0]?.phoneNumber,
      });
      return response.data;
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    publicKey && fetchData();
  }, []);

  return (
    <React.Fragment>
      <div
        className="grid md:hidden bg-white/5 rounded-xl shadow-2xl p-6 w-full mb-4"
        style={divStyles}
      >
        <div className="flex items-center justify-between md:block">
          {/* <img
            className="inline-block h-8 w-8 rounded-full ring-4 ring-white"
            src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
            alt=""
          /> */}
          <IoPersonCircleOutline className="inline-block h-8 w-8 rounded-full text-gray-500" />
          <div className="ml-3 text-xl font-bold text-gray-700 dark:text-gray-300">
            {data ? "+" + data.phoneNumber : "No phone number"}
          </div>
          <div
            className="inline-flex rounded-md shadow-sm ml-auto"
            role="group"
          >
            <button
              className="p-2 dark:text-gray-200 text-gray-600 rounded-md outline-none focus:border-gray-400 "
              onClick={() => setSubmenu(!submenu)}
            >
              <TiThMenu className="w-6 h-6" />
            </button>
          </div>
        </div>
        <div>
          <div
            className={`flex-1 justify-self-center pb-3 mt-8 md:block md:pb-0 md:mt-0 ${
              submenu ? "block" : "hidden"
            }`}
          >
            <ul className="items-center justify-center space-y-8 md:flex md:space-x-6 md:space-y-0 dark:text-gray-200 text-gray-600">
              <li
                className="hover:text-blue-500
              cursor-pointer transition-colors duration-300 font-semibold flex gap-2"
              >
                <IoPersonCircleOutline className="w-6 h-6" />
                <a href={`/dashboard/overview/${publicKey ? publicKey : ""}`}>
                  Overview
                </a>
              </li>
              <li
                className="hover:text-blue-500
              cursor-pointer transition-colors duration-300 font-semibold flex gap-2"
              >
                <FiSliders className="w-6 h-6" />
                <a
                  href={`/dashboard/collections/${publicKey ? publicKey : ""}`}
                >
                  NFT Collection
                </a>
              </li>
              <li
                className="hover:text-blue-500
              cursor-pointer transition-colors duration-300 font-semibold flex gap-2"
              >
                <FaInbox className="w-6 h-6" />
                <a href={`/dashboard/events/${publicKey ? publicKey : ""}`}>
                  Events
                </a>
              </li>
              <li
                className="hover:text-blue-500
              cursor-pointer transition-colors duration-300 font-semibold flex gap-2"
              >
                <IoIosSettings className="w-6 h-6" />
                <a href={`/dashboard/settings/${publicKey ? publicKey : ""}`}>
                  Settings
                </a>
              </li>
              <li
                className="hover:text-blue-500
              cursor-pointer transition-colors duration-300 font-semibold justify-center text-center"
              >
                <div
                  className="inline-flex rounded-full shadow-sm mb-6 mt-24 p-1 bg-gray-300 dark:bg-gray-600/30"
                  role="group"
                >
                  <button
                    type="button"
                    className="rounded-full inline-flex items-center py-3 px-6 text-sm font-medium text-gray-900 bg-white dark:bg-white/70 border-0 hover:bg-gray-900 hover:opacity-50 focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white duration-300 transition-all"
                  >
                    <svg
                      aria-hidden="true"
                      className="mr-2 w-4 h-4 fill-current"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Log out
                  </button>
                  <button
                    type="button"
                    className="inline-flex items-center py-3 px-6 text-sm font-medium text-gray-900 bg-transparent rounded-r-md dark:text-white hover:opacity-50 transition-all duration-300"
                  >
                    <svg
                      aria-hidden="true"
                      className="mr-2 w-4 h-4 fill-current"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M2 9.5A3.5 3.5 0 005.5 13H9v2.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 15.586V13h2.5a4.5 4.5 0 10-.616-8.958 4.002 4.002 0 10-7.753 1.977A3.5 3.5 0 002 9.5zm9 3.5H9V8a1 1 0 012 0v5z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Support
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
