import React from "react";

const articlelist = ({ title, onChildClick }) => {
  return (
    <div
      className="text-lg ml-8 cursor-pointer p-2 dark:text-gray-200 text-gray-600 hover:text-blue-600 dark:hover:text-blue-600"
      onClick={onChildClick}
    >
      {title}
    </div>
  );
};

export default articlelist;
