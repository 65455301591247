import React from "react";
import { GoChecklist } from "react-icons/go";
import { IoNewspaperOutline } from "react-icons/io5";
import { FaEthereum } from "react-icons/fa";
import img_join from "../assets/purkle-join.png";
import img_survey from "../assets/purkle-survey.png";
import img_earn from "../assets/purkle-earn.png";
import img_vip from "../assets/purkle-vip.png";

export default function HowPage() {
  const bg_btn = {
    background: "linear-gradient(90.11deg, #28A2C3 2.03%, #DA3E3F 97.3%)",
  };

  return (
    <section className="container mx-auto mb-20">
      <div className="grid justify-center">
        <h1 className="h1 text-center text-3xl mt-14 text-gray-300">
          For each survey completed, receive an NFT with unique digital content
          and rewards
        </h1>
        <p className="text-gray-300 text-2xl lg:mx-60 mt-2 text-center mb-14">
          Complete the entire series for VIP access to experiences.
        </p>

        <div className="grid grid-cols-4 text-center text-3xl text-gray-300 mx-24 font-bold gap-14 items-stretch">
          <div className="grid justify-center border-2 border-[#28A2C3] p-5 rounded-2xl space-y-4 w-52 hover:scale-110 transition-all ease-in-out duration-300 hover:cursor-pointer hover:shadow-lg hover:shadow-[#DA3E3F] hover:border-[#DA3E3F]">
            <img src={img_survey} alt="survey" className="mx-auto" />
            <p>Survey</p>
          </div>
          <div className="grid justify-center border-2 border-[#28A2C3] p-5 rounded-2xl space-y-4 w-52 hover:scale-110 transition-all ease-in-out duration-300 hover:cursor-pointer hover:shadow-lg hover:shadow-[#DA3E3F] hover:border-[#DA3E3F]">
            <img src={img_earn} alt="earn" className="mx-auto" />
            <p>Earn</p>
          </div>
          <div className="grid justify-center border-2 border-[#28A2C3] p-5 rounded-2xl space-y-4 w-52 hover:scale-110 transition-all ease-in-out duration-300 hover:cursor-pointer hover:shadow-lg hover:shadow-[#DA3E3F] hover:border-[#DA3E3F]">
            <img src={img_join} alt="join" className="mx-auto" />
            <p>Rewards</p>
          </div>
          <div className="grid justify-center border-2 border-[#28A2C3] p-5 rounded-2xl space-y-4 w-52 hover:scale-110 transition-all ease-in-out duration-300 hover:cursor-pointer hover:shadow-lg hover:shadow-[#DA3E3F] hover:border-[#DA3E3F]">
            <img src={img_vip} alt="earn" className="mx-auto my-5" />
            <p>Access</p>
          </div>
        </div>

        <div className="justify-center flex mt-10">
          <button
            className="p-12 text-white font-semibold rounded-full transition-all duration-300 py-2 shadow-md my-8 hover:opacity-50"
            style={bg_btn}
            onClick={() => {
              window.open("/resources", "_blank");
            }}
          >
            <p className="text-lg md:text-xl font-bold">Learn more</p>
          </button>
        </div>
      </div>
    </section>
  );
}
