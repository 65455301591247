import React from "react";

export default function AboutPage() {
  return (
    <section className="grid grid-cols-1 container mx-auto gap-16 mb-20 mt-36 pt-0 px-4 lg:px-32">
      <div>
        <h1 className="text-3xl font-bold text-center text-gray-600 dark:text-gray-200 lg:text-4xl mb-10">
          Breathtaking flypasts... now own a piece of it!
        </h1>
        <p className="font-normal text-center text-gray-600 dark:text-gray-300 lg:px-20">
          We are building a community who value VIP experiences. As a Pacific
          Airshow VIP NFT holder you have First Access RIghts to Tickets, before
          main ticket release. These NFTs are packed with dynamic qualities and
          benefits which will evolve over time. Receive invitations to "money
          can't buy experiences".
          <br />
          <br />
          <span className="font-bold">
            Welcome to the First Edition Pacific Airshow VIPNFT Series:
          </span>
        </p>
      </div>
    </section>
  );
}
