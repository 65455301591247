import React, { useContext } from "react";
import AppNavbar from "../../components/app-navbar";
import AppFooter from "../../components/footer";
import img_background_light from "../../components/assets/backgrounds/Bg-light2.png";
import img_background_dark from "../../components/assets/backgrounds/Dark-desktop-1.png";
import { ThemeContext } from "../../App";
import Helmet from "./../../components/helmet";
import Profile from "./../../components/profile";
import { AiOutlineDown } from "react-icons/ai";
import img_card_dua from "./../../components/assets/DuaLipaQR.png";
import ProfileResponsive from "../../components/profile-responsive";
import { useParams } from "react-router-dom";

export default function CollectionsPage() {
  const { theme } = useContext(ThemeContext);
  const { param } = useParams();
  return (
    <div
      className={`pt-32 overflow-hidden ${theme === "dark" ? "dark" : "light"
        } transition-all duration-300`}
      style={{
        backgroundImage:
          theme === "dark"
            ? `url(${img_background_dark})`
            : `url(${img_background_light})`,
        backgroundSize: "cover",
        width: "100vw",
        minWidth: "100vw",
      }}
    >
      <Helmet title={"VIP NFT | Overview"} />
      <AppNavbar />
      <section className="container px-6 py-4 mt-4 mb-4 mx-auto">
        <div className="grid grid-cols-1 md:gap-8 mt-8 xl:mt-4 xl:gap-16 md:grid-cols-2 xl:grid-cols-4">
          <ProfileResponsive publicKey={param} />
          <Profile publicKey={param} />
          <div className="col-span-3 ...">
            <div className="text-gray-600 dark:text-gray-200">
              <h1 className="text-4xl font-bold text-gray-600 dark:text-gray-200 lg:text-5xl ">
                Your Collection
              </h1>
            </div>
            <div className="md:flex bg-white/5 rounded-xl shadow-2xl p-6 my-8 hidden ">
              <div className="grid grid-cols-3 gap-4 w-full text-center">
                <div className="text-xl font-medium text-gray-600 dark:text-gray-200 cursor-pointer">
                  <p>
                    Collections{" "}
                    <AiOutlineDown className="inline-flex text-white" />
                  </p>
                </div>
                <div className="text-xl font-medium text-gray-600 dark:text-gray-200 cursor-pointer">
                  <p>
                    Activity{" "}
                    <AiOutlineDown className="inline-flex text-white" />
                  </p>
                </div>
                <div className="text-xl font-medium text-gray-600 dark:text-gray-200 cursor-pointer">
                  <p>
                    Type <AiOutlineDown className="inline-flex text-white" />
                  </p>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-3 mt-4 mx-auto">
              {/* {Array.from({ length: 15 }, (_, i) => (
                <img
                  src={img_card_dua}
                  alt="/"
                  className="hover:drop-shadow-lg shadow-blue-400 transition-all duration-300 hover:opacity-50"
                />
              ))} */}
              <img
                src="https://pa-demo.vipnft.fan/static/media/NFT-landed.ab6a1c92a5264f2d4a2e.gif"
                alt="/"
                className="hover:drop-shadow-lg shadow-blue-400 transition-all duration-300 hover:opacity-50 shadow-md hover:cursor-pointer"
                onClick={() =>
                  window.open(
                    `https://goerli.etherscan.io/address/${param}#nfttransfers`,
                    "_blank"
                  )
                }
              />
            </div>
          </div>
        </div>
      </section>

      <AppFooter />
    </div>
  );
}
