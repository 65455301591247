import React from "react";
import vipbg from "./../assets/vipbg.png";
import vip1 from "./../assets/vip1.png";
import vip2 from "./../assets/vip2.png";
import vip3 from "./../assets/vip3.png";

export default function vip() {
  return (
    <React.Fragment>
      <div className="">
        <section className="container-fluid md:container md:mx-auto xl:mt-60 mx-8 h-[959px]">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 h-full">
            <div className="flex items-center">
              <div>
                <h1 className="text-[#212429] text-6xl font-bold my-6">
                  VIP Re-Imagined
                </h1>
                <h2 className="text-4xl font-bold text-[#385ADA] my-6">
                  Experiences money can't buy....
                  <br />
                  but VIPNFT owners can.
                </h2>
                <div className="flex text-base text-black">
                  <p className="">
                    Have you ever missed your favourite event or artist as
                    tickets sold out in minutes? Frustrated by the current
                    ticketing system? VIPNFTs offer dedicated fans access before
                    main ticket release, plus invitations to exclusive VIP
                    events.
                  </p>
                </div>
                <div className="flex justify-start my-8">
                  <button
                    className="bg-gradient-to-r from-[#2a4dd0] to-[#6e8cff] text-white font-semibold rounded-lg transition-all duration-300 py-4 px-24 shadow-md hover:opacity-50"
                    onClick={() => {
                      window.location.href = "/";
                    }}
                  >
                    <p className="text-lg md:text-lg ">Learn More</p>
                  </button>
                </div>
              </div>
            </div>
            <div
              className="md:relative "
              style={{
                backgroundImage: `url(${vipbg})`,
                backgroundSize: "auto",
                // width: "100vw",
                // minWidth: "100vw",
                height: "100%",
              }}
            >
              <img src={vip1} alt="vip1" className="md:absolute md:top-28" />
              <img
                src={vip2}
                alt="vip2"
                className="md:absolute md:bottom-64 md:right-0"
              />
              <img src={vip3} alt="vip3" className="md:absolute md:bottom-20" />
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
}
