import React from "react";
import collection1 from "./../assets/collection1.png";
import collection2 from "./../assets/collection2.png";
import collection3 from "./../assets/collection3.png";
import collectionsbg from "./../assets/collectionsbg.png";
import CollectionCard from "./../../../components/collection-card";

export default function Collections() {
  return (
    <div
      className="bg-white pt-20"
      style={{
        backgroundImage: `url(${collectionsbg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        width: "100%",
      }}
    >
      <section
        id="collections"
        className="md:grid md:grid-cols-1 gap-4 items-center container px-6 py-2 mx-auto pb-10 mt-40"
      >
        <div className="flex flex-col justify-center gap-4 items-center">
          <h1 className="text-[56px] text-black font-bold text-center">
            Top Collections
          </h1>
          <p className="text-xl align-middle">Over 100+ Collections</p>
        </div>

        <div className="grid grid-cols-3 gap-8 mt-20">
          <CollectionCard
            img={collection1}
            title={"Pacific Airshow GC 2023"}
            desc={
              "With stunning visuals, immersive content and exclusive benefits, the Pacific Airshow VIP..."
            }
            total={"22"}
          />
          <CollectionCard
            img={collection2}
            title={"Ticketsocket"}
            desc={
              "Exclusive benefits, from early access to tickets and Invitations to VIP experiences."
            }
            total={"8"}
          />
          <CollectionCard
            img={collection3}
            title={"Rewards or Pearls"}
            desc={
              "Perks redefined. Unlock access to earn rewards and receive unique digital content."
            }
            total={"32"}
          />
        </div>
        <div className="grid grid-cols-3 gap-8 mt-4 mb-20">
          <CollectionCard
            img={collection1}
            title={"Pacific Airshow GC 2023"}
            desc={
              "With stunning visuals, immersive content and exclusive benefits, the Pacific Airshow VIP..."
            }
            total={"19"}
          />
          <CollectionCard
            img={collection2}
            title={"Ticketsocket"}
            desc={
              "Exclusive benefits, from early access to tickets and Invitations to VIP experiences."
            }
            total={"16"}
          />
          <CollectionCard
            img={collection3}
            title={"Rewards or Pearls"}
            desc={
              "Perks redefined. Unlock access to earn rewards and receive unique digital content."
            }
            total={"10"}
          />
        </div>
        <div className="flex justify-center">
          <button
            className="bg-gradient-to-r from-[#2a4dd0] to-[#6e8cff] text-white font-semibold rounded-lg transition-all duration-300 py-4 px-24 shadow-md hover:opacity-50"
            onClick={() => {
              window.location.href = "/explore";
            }}
          >
            <p className="text-lg md:text-lg ">View More</p>
          </button>
        </div>
      </section>
    </div>
  );
}
