import React, { useContext } from "react";
import AppNavbar from '../../components/app-navbar';
import AppFooter from '../../components/footer';
import { ThemeContext } from "../../App";
import img_background_light from "../../components/assets/backgrounds/Bg-light2.png";
import img_background_dark from "../../components/assets/backgrounds/Dark-desktop-1.png";
import Helmet from "./../../components/helmet";
import diamond_logo from "./assets/diamond_logo.png"
import gold_logo from "./assets/gold_logo.png"
import palladium_logo from "./assets/palladium_logo.png"
import gold_image_1 from "./assets/gold_image_1.png"
import palladium_image_1 from "./assets/palladium_image_1.png"
import bottom_image_1 from "./assets/bottom_image_1.png"
import bottom_image_2 from "./assets/bottom_image_2.png"
import bottom_image_3 from "./assets/bottom_image_3.png"
import Card from "./components/card"
import List from "./components/list"
import Title from "./components/title"
import Divider from "./components/divider"
import StickyBox from "react-sticky-box";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "./css/styles.css";
import { Carousel } from 'react-responsive-carousel';

export default function ComparisonPage
    () {
    const { theme } = useContext(ThemeContext);
    const goldstyle = {
        background: "linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(239,126,36,0.95) 100%)"
    }

    const palladiumstyle = {
        background: "linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(195,122,100,0.95) 100%)"
    }

    const diamondstyle = {
        background: "linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(72,178,209,0.95) 100%)"
    }

    return (
        <div
            className={`pt-24 ${theme === "dark" ? "dark" : "light"
                } transition-all duration-300`}
            style={{
                backgroundImage:
                    theme === "dark"
                        ? `url(${img_background_dark})`
                        : `url(${img_background_light})`,
                backgroundSize: "cover",
                width: "100vw",
                minWidth: "100vw",
            }}
        >
            <Helmet title={"VIP NFT | VIPNFT Overview"} />
            <AppNavbar />
            <section className="container mx-auto gap-4 text-center px-8 md:px-0 mb-16">
                <div className="flex justify-center">
                    <h1 className="text-5xl font-semibold text-center text-gray-600 dark:text-white underline underline-offset-8">Pacific Airshow Hungtington Beach</h1>
                </div>
            </section>
            <section className="container mx-auto gap-4 text-center px-8 md:px-0 my-4">
                <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-16">
                    <div className="group gold-section cursor-pointer">
                        <StickyBox offsetTop={100} offsetBottom={100} style={{ zIndex: "1" }}>
                            <Title logo={gold_logo} title={"Gold"} color="text-white" bg={goldstyle} />
                        </StickyBox>

                        <div className="grid justify-center my-32">
                            {/* <div className="flex justify-center">
                                <IoIosArrowUp className="text-[#EF7E24] w-20 h-20" />
                            </div> */}
                            <Carousel axis={"vertical"} verticalSwipe={"standard"} showArrows={true} showStatus={false} showIndicators={false} style={{ height: "472px" }} className="gold-carousel">
                                <div>
                                    <Card image={gold_image_1} description={"Lorem ipsum dolor sit amet, consectitur adipisci elit, sed eismod tempor incidunt ut labore"} bg={goldstyle} />
                                </div>
                                <div>
                                    <Card image={gold_image_1} description={"Lorem ipsum dolor sit amet, consectitur adipisci elit, sed eismod tempor incidunt ut labore"} bg={goldstyle} />
                                </div>
                                <div>
                                    <Card image={gold_image_1} description={"Lorem ipsum dolor sit amet, consectitur adipisci elit, sed eismod tempor incidunt ut labore"} bg={goldstyle} />
                                </div>
                            </Carousel>
                            {/* <div className="flex justify-center my-4">
                                <IoIosArrowDown className="text-[#EF7E24] w-20 h-20" />
                            </div> */}
                        </div>
                        <Divider />
                        <div className="grid justify-items-center text-lg font-semibold dark:text-white text-gray-600 group-hover:bg-slate-800/70 rounded-xl">
                            <List />
                        </div>
                        <Divider />
                        <div className="my-32">
                            <Card image={bottom_image_1} description={"Lorem ipsum dolor sit amet, consectitur adipisci elit, sed eismod tempor incidunt ut labore"} bg={goldstyle} />
                        </div>
                        <div className="my-32">
                            <Card image={bottom_image_2} description={"Lorem ipsum dolor sit amet, consectitur adipisci elit, sed eismod tempor incidunt ut labore"} bg={goldstyle} />
                        </div>
                        <div className="my-32">
                            <Card image={bottom_image_3} description={"Lorem ipsum dolor sit amet, consectitur adipisci elit, sed eismod tempor incidunt ut labore"} bg={goldstyle} />
                        </div>
                    </div>
                    <div className="group palladium-section cursor-pointer">
                        <StickyBox offsetTop={100} offsetBottom={100}>
                            <Title logo={palladium_logo} title={"Palladium"} color="text-white" bg={palladiumstyle} />
                        </StickyBox>
                        <div className="grid justify-center my-32">
                            {/* <div className="flex justify-center">
                                <IoIosArrowUp className="text-[#C37A64] w-20 h-20" />
                            </div> */}
                            <Carousel axis={"vertical"} verticalSwipe={"standard"} showArrows={true} showStatus={false} showIndicators={false} style={{ height: "472px" }} className="palladium-carousel">
                                <Card image={palladium_image_1} description={"Lorem ipsum dolor sit amet, consectitur adipisci elit, sed eismod tempor incidunt ut labore"} bg={palladiumstyle} />
                                <Card image={palladium_image_1} description={"Lorem ipsum dolor sit amet, consectitur adipisci elit, sed eismod tempor incidunt ut labore"} bg={palladiumstyle} />
                                <Card image={palladium_image_1} description={"Lorem ipsum dolor sit amet, consectitur adipisci elit, sed eismod tempor incidunt ut labore"} bg={palladiumstyle} />
                            </Carousel>
                            {/* <div className="flex justify-center my-4">
                                <IoIosArrowDown className="text-[#C37A64] w-20 h-20" />
                            </div> */}
                        </div>
                        <Divider />
                        <div className="grid justify-items-center text-lg font-semibold dark:text-white text-gray-600 group-hover:bg-slate-800/70 rounded-xl">
                            <List />
                        </div>
                        <Divider />
                        <div className="my-32">
                            <Card image={bottom_image_1} description={"Lorem ipsum dolor sit amet, consectitur adipisci elit, sed eismod tempor incidunt ut labore"} bg={palladiumstyle} />
                        </div>
                        <div className="my-32">
                            <Card image={bottom_image_2} description={"Lorem ipsum dolor sit amet, consectitur adipisci elit, sed eismod tempor incidunt ut labore"} bg={palladiumstyle} />
                        </div>
                        <div className="my-32">
                            <Card image={bottom_image_3} description={"Lorem ipsum dolor sit amet, consectitur adipisci elit, sed eismod tempor incidunt ut labore"} bg={palladiumstyle} />
                        </div>
                    </div>
                    <div className="group diamond-section cursor-pointer">
                        <StickyBox offsetTop={100} offsetBottom={100}>
                            <Title logo={diamond_logo} title={"Diamond"} color="text-white" bg={diamondstyle} />
                        </StickyBox>
                        <div className="grid justify-center my-32 group3">
                            {/* <div className="flex justify-center">
                                <IoIosArrowUp className="text-[#48B2D1] w-20 h-20" />
                            </div> */}
                            <Carousel axis={"vertical"} verticalSwipe={"standard"} showArrows={true} showStatus={false} showIndicators={false} style={{ height: "472px" }} className="diamond-carousel">
                                <Card image={gold_image_1} description={"Lorem ipsum dolor sit amet, consectitur adipisci elit, sed eismod tempor incidunt ut labore"} bg={diamondstyle} />
                                <Card image={gold_image_1} description={"Lorem ipsum dolor sit amet, consectitur adipisci elit, sed eismod tempor incidunt ut labore"} bg={diamondstyle} />
                                <Card image={gold_image_1} description={"Lorem ipsum dolor sit amet, consectitur adipisci elit, sed eismod tempor incidunt ut labore"} bg={diamondstyle} />
                            </Carousel>
                            {/* <div className="flex justify-center my-4">
                                <IoIosArrowDown className="text-[#48B2D1] w-20 h-20" />
                            </div> */}
                        </div>
                        <Divider />
                        <div className="grid justify-items-center text-lg font-semibold dark:text-white text-gray-600 group-hover:bg-slate-800/70 rounded-xl">
                            <List />
                        </div>
                        <Divider />
                        <div className="my-32">
                            <Card image={bottom_image_1} description={"Lorem ipsum dolor sit amet, consectitur adipisci elit, sed eismod tempor incidunt ut labore"} bg={diamondstyle} />
                        </div>
                        <div className="my-32">
                            <Card image={bottom_image_2} description={"Lorem ipsum dolor sit amet, consectitur adipisci elit, sed eismod tempor incidunt ut labore"} bg={diamondstyle} />
                        </div>
                        <div className="my-32">
                            <Card image={bottom_image_3} description={"Lorem ipsum dolor sit amet, consectitur adipisci elit, sed eismod tempor incidunt ut labore"} bg={diamondstyle} />
                        </div>
                    </div>
                </div>
            </section >
            <AppFooter />
        </div >
    )
}
