import React from 'react'
import cpillus from "./../assets/cp_illus.png"

export default function SyncnftPage() {
    return (
        <section>
            <div>
                <h1 className="dark:text-gray-200 text-[#4C6474] font-bold text-4xl text-center mb-8">Always-on-Sync NFT</h1>
            </div>
            <div className="grid md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-2 items-center px-6 relative mx-auto">
                <div className="flex text-center justify-center md:justify-start"><img src={cpillus} alt="Cellphone Illustration" className="w-2/3 h-2/3" /></div>
                <div className="order-last md:order-first gap-8 grid px-4 lg:px-24">
                    <p className="dark:text-gray-200 text-[#4C6474] font-normal text-2xl">This breakthrough from the VIPNFT standard allows NFTs to be intuitive and always in sync with the data that is being displayed.</p>
                    <p className="dark:text-gray-200 text-[#4C6474] font-normal text-2xl">Every Ticketek NFT has an intuitive-live design, all the traits are saved and synced with the blockchain network combined with our clever support servers and nodes to make sure all the data that is displayed are up-to-date.</p>
                    <p className="dark:text-gray-200 text-[#4C6474] font-normal text-2xl">This makes all the reward points, unique contents, levels and traits always up-to-date with the latest data.</p>
                </div>
            </div>
        </section>
    )
}
