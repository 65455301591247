import React from "react";
import benefit1 from "./../assets/benefit1.png";
import benefit2 from "./../assets/benefit2.png";
import benefit3 from "./../assets/benefit3.png";

const blueLayer = {
  background:
    "linear-gradient(180deg, rgba(112, 72, 232, 0.2) 0%, rgba(33, 36, 41, 0.92) 100%)",
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
};

export default function Benefits() {
  return (
    <section className="bg-[#EAF3FD] pb-36" id="features">
      <div className="container px-6 pt-10 mx-auto">
        <h1 className="text-3xl font-bold text-center text-gray-600 lg:text-4xl">
          Why NFT?
        </h1>
        <p className="font-normal text-lg mt-10 text-center text-gray-600 max-w-4xl mx-auto mb-24">
          With a web3 ticketing solution, scalpers and fraudulent tickets are a
          distant memory. Creators and Artists can choose how they engage
          directly with fans after the event. Building communities and enhancing
          the fan experience.
          <br />
          NFT Tickets provide <span className="font-bold">Ownership</span>,{" "}
          <span className="font-bold"> Control </span>
          and <span className="font-bold"> Transparency</span>.
        </p>
        <h1 className="text-3xl font-bold text-center text-gray-600 lg:text-4xl mb-10">
          How it works
        </h1>
        <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 ">
          <LayoutCard title="Event Ticket" icon={benefit1}>
            <p className="text-base leading-relaxed mt-2 mb-4 text-gray-600">
              Ticket purchased through existing ticketing solution.
            </p>
          </LayoutCard>
          <LayoutCard title="Smart Ticket Upgrade" icon={benefit2}>
            <p className="text-base leading-relaxed mt-2 mb-4 text-gray-600">
              At checkout, buyers are presented with an upsell to a SmartTicket.
              Turning their ticket into a digital collectible, which is
              fraud-proof, transferable and can be sold in a transparent
              marketplace.
            </p>
          </LayoutCard>
          <LayoutCard title="Benefits" icon={benefit3}>
            <p className="text-base leading-relaxed mt-2 mb-4 text-gray-600">
              Benefits include direct artist engagement, pre-release of future
              content, re-sellable, transferable, collectible to name just a
              few. Increase the value of your Digital Asset (NFT) through
              gamification and content release assigned to NFTs directly by
              artists.
            </p>
          </LayoutCard>
        </div>
      </div>
    </section>
  );
}

const LayoutCard = ({ children, title, icon }) => {
  return (
    <div className="p-8 md:w-1/3 sm:mb-0 mb-6 hover:cursor-pointer transition-all hover:duration-300 hover:scale-110">
      <div className="bg-white/80 shadow-2xl p-8 md:h-full rounded-3xl transition-all duration-300 md:py-32">
        <div className="rounded-lg p-3 body-font border-blue-700 h-64 align-middle flex">
          <img alt="content" className="max-h-60 mx-auto my-auto" src={icon} />
        </div>
        <h2 className="text-xl font-medium title-font dark:text-white text-gray-600 mt-10">
          {title}
        </h2>
        <p className="mt-2 font-normal dark:text-white">{children}</p>
      </div>
    </div>
  );
};
