import React, { useState } from "react";
import Calendar from "react-calendar";
import "./css/calendar.css";

const divStyles = {
  boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.25)',
  borderRadius: '25px',
  backdropFilter: 'blur(2px)',
  background: 'rgba(255, 255, 255, 0.5)'
};

const calendarDivStyles = {
  boxShadow: '0px 1px 8px rgba(0, 0, 0, 0.25)',
  borderRadius: '25px',
  backdropFilter: 'blur(2px)',
  background: "#FFFFFF",
}

export default function CalendarPage() {
  const [value, onChange] = useState(new Date());

  return (
    <div className="flex flex-col p-6" style={divStyles}>
      <Calendar
        onChange={onChange}
        value={value}
        className="dark:text-white transition-all duration-700"
      />
      <div className="flex items-center space-x-4 my-2 p-4 hover:bg-white/30 transition-all duration-500 hover:shadow-yellow-500 hover:shadow-lg" style={calendarDivStyles}>
        <div className="flex-shrink-0">
          <img
            className="inline-block h-12 w-12 rounded-full ring-4 ring-white"
            src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
            alt=""
          />
        </div>
        <div className="flex-1 min-w-0">
          <p className="text-base font-bold text-black truncate dark:text-white">
            3 x 3 With James Lebron
          </p>
          <p className="text-xs text-black truncate dark:text-gray-400">
            20:00 PM - 22:00 PM
          </p>
        </div>
      </div>
      <div className="flex items-center space-x-4 my-2 p-4 hover:bg-white/30 transition-all duration-500 hover:shadow-yellow-500 hover:shadow-lg" style={calendarDivStyles}>
        <div className="flex-shrink-0">
          <img
            className="inline-block h-12 w-12 rounded-full ring-4 ring-white"
            src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
            alt=""
          />
        </div>
        <div className="flex-1 min-w-0">
          <p className="text-base font-bold text-black truncate dark:text-white">
            3 x 3 With James Lebron
          </p>
          <p className="text-xs text-black truncate dark:text-gray-400">
            20:00 PM - 22:00 PM
          </p>
        </div>
      </div>
    </div>
  );
}
