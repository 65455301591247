import React, { useContext, useState } from "react";
import AppNavbar from "../../components/app-navbar";
import { ThemeContext } from "../../App";
import img_background_light from "../../components/assets/backgrounds/Bg-light2.png";
import img_background_dark from "../../components/assets/backgrounds/Dark-desktop-1.png";
import Helmet from "./../../components/helmet";
import AccountModal from "./../../components/account-modal";
import AppFooter from "../../components/footer";
import StepWizard from "react-step-wizard";

export default function BuildPage() {
    const { theme } = useContext(ThemeContext);
    const [modal, setModal] = useState("hidden");
    const [page, setPage] = useState("login");

    function changePage(page) {
        setPage(page);
    }

    return (
        <React.Fragment>
            <div
                className={`pt-32 overflow-hidden ${theme === "dark" ? "dark" : "light"
                    } transition-all duration-300 `}
                style={{
                    backgroundImage:
                        theme === "dark"
                            ? `url(${img_background_dark})`
                            : `url(${img_background_light})`,
                    backgroundSize: "cover",
                    width: "100vw",
                    minWidth: "100vw",
                }}
            >
                <Helmet title={"VIP NFT"} />
                <AppNavbar openModal={setModal} modal={modal} />
                <AccountModal
                    modal={modal}
                    setModal={setModal}
                    theme={theme}
                    page={page}
                    changePage={changePage}
                />

                <div className={modal === "hidden" ? "..." : "modal-bg-blur"}>
                    <section className="container mx-auto mb-8">
                        <div className="flex flex-col p-6">
                            <div className="grid grid-cols-1 gap-4">
                                <StepWizard>
                                    <First />
                                    <Second />
                                    <Last />

                                </StepWizard>
                            </div>
                        </div>
                    </section>

                </div>
                <AppFooter />
            </div>
        </React.Fragment>
    )
}

const First = props => {
    // const update = (e) => {
    //     props.update(e.target.name, e.target.value);
    // };

    return (
        <div>
            <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                    <div className="px-4 sm:px-0">
                        <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-white">Create Account</h3>
                        <p className="mt-1 text-sm text-gray-600 dark:text-white">This information will be displayed publicly so be careful what you share.</p>
                    </div>
                </div>
                <div className="mt-5 md:col-span-2 md:mt-0 dark:bg-slate-800/50 rounded-xl shadow-2xl">
                    <form action="#" method="POST">
                        <div className="shadow sm:overflow-hidden sm:rounded-md">
                            <div className="space-y-6 px-4 py-5 sm:p-6">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700"></label>
                                    <div className="mt-1 flex items-center">
                                        <span className="inline-block h-12 w-12 overflow-hidden rounded-full bg-gray-100">
                                            <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                            </svg>
                                        </span>
                                        <button type="button" className="ml-5 rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Change</button>
                                    </div>
                                </div>

                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-6 sm:col-span-3">
                                        <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">First Name</label>
                                        <input type="email" name="email" id="email" className="mt-1 px-3 py-2 bg-transparent border shadow-sm border-slate-300 placeholder-slate-400 dark:placeholder-gray-200 dark:text-white focus:outline-none focus:border-orange-300 focus:ring-orange-300 block w-full rounded-lg sm:text-sm focus:ring-1" placeholder="John" required />
                                    </div>
                                    <div className="col-span-6 sm:col-span-3">
                                        <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Last Name</label>
                                        <input type="email" name="email" id="email" className="mt-1 px-3 py-2 bg-transparent border shadow-sm border-slate-300 placeholder-slate-400 dark:placeholder-gray-200 dark:text-white focus:outline-none focus:border-orange-300 focus:ring-orange-300 block w-full rounded-lg sm:text-sm focus:ring-1" placeholder="Doe" required />
                                    </div>
                                </div>

                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-6 sm:col-span-3">
                                        <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Username</label>
                                        <input type="email" name="email" id="email" className="mt-1 px-3 py-2 bg-transparent border shadow-sm border-slate-300 placeholder-slate-400 dark:placeholder-gray-200 dark:text-white focus:outline-none focus:border-orange-300 focus:ring-orange-300 block w-full rounded-lg sm:text-sm focus:ring-1" placeholder="Username" required />
                                    </div>
                                    <div className="col-span-6 sm:col-span-3">
                                        <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                                        <input type="email" name="email" id="email" className="mt-1 px-3 py-2 bg-transparent border shadow-sm border-slate-300 placeholder-slate-400 dark:placeholder-gray-200 dark:text-white focus:outline-none focus:border-orange-300 focus:ring-orange-300 block w-full rounded-lg sm:text-sm focus:ring-1" placeholder="Email" required />
                                    </div>
                                </div>

                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-6 sm:col-span-3">
                                        <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                                        <input type="email" name="email" id="email" className="mt-1 px-3 py-2 bg-transparent border shadow-sm border-slate-300 placeholder-slate-400 dark:placeholder-gray-200 dark:text-white focus:outline-none focus:border-orange-300 focus:ring-orange-300 block w-full rounded-lg sm:text-sm focus:ring-1" placeholder="Password" required />
                                    </div>
                                    <div className="col-span-6 sm:col-span-3">
                                        <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Confirm Password</label>
                                        <input type="email" name="email" id="email" className="mt-1 px-3 py-2 bg-transparent border shadow-sm border-slate-300 placeholder-slate-400 dark:placeholder-gray-200 dark:text-white focus:outline-none focus:border-orange-300 focus:ring-orange-300 block w-full rounded-lg sm:text-sm focus:ring-1" placeholder="Confirm Password" required />
                                    </div>
                                </div>

                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-6 sm:col-span-3">
                                        <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Phone Number</label>
                                        <input type="email" name="email" id="email" className="mt-1 px-3 py-2 bg-transparent border shadow-sm border-slate-300 placeholder-slate-400 dark:placeholder-gray-200 dark:text-white focus:outline-none focus:border-orange-300 focus:ring-orange-300 block w-full rounded-lg sm:text-sm focus:ring-1" placeholder="Phone Number" required />
                                    </div>

                                </div>
                            </div>
                            <Stats step={1} {...props} />
                        </div>
                    </form>
                </div>
            </div>

        </div>
    );
};

const Second = props => {
    // const validate = () => {
    //     if (confirm('Are you sure you want to go back?')) { // eslint-disable-line
    //         props.previousStep();
    //     }
    // };

    return (
        <div className="mt-10 sm:mt-0">
            <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                    <div className="px-4 sm:px-0">
                        <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-white">KYB</h3>
                        <p className="mt-1 text-sm text-gray-600 dark:text-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce at facilisis lacus.</p>
                    </div>
                </div>
                <div className="mt-5 md:col-span-2 md:mt-0 dark:bg-slate-800/50 rounded-xl shadow-2xl">
                    <form action="#" method="POST">
                        <div className="overflow-hidden shadow sm:rounded-md">
                            <div className="space-y-6 px-4 py-5 sm:p-6">
                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-6 sm:col-span-3">
                                        <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Management Email</label>
                                        <input type="email" name="email" id="email" className="mt-1 px-3 py-2 bg-transparent border shadow-sm border-slate-300 placeholder-slate-400 dark:placeholder-gray-200 dark:text-white focus:outline-none focus:border-orange-300 focus:ring-orange-300 block w-full rounded-lg sm:text-sm focus:ring-1" placeholder="Management Email" required />
                                    </div>
                                    <div className="col-span-6 sm:col-span-3">
                                        <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Company Name</label>
                                        <input type="email" name="email" id="email" className="mt-1 px-3 py-2 bg-transparent border shadow-sm border-slate-300 placeholder-slate-400 dark:placeholder-gray-200 dark:text-white focus:outline-none focus:border-orange-300 focus:ring-orange-300 block w-full rounded-lg sm:text-sm focus:ring-1" placeholder="Company Name" required />
                                    </div>
                                </div>

                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-6 sm:col-span-3">
                                        <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Legal Entity Name</label>
                                        <input type="email" name="email" id="email" className="mt-1 px-3 py-2 bg-transparent border shadow-sm border-slate-300 placeholder-slate-400 dark:placeholder-gray-200 dark:text-white focus:outline-none focus:border-orange-300 focus:ring-orange-300 block w-full rounded-lg sm:text-sm focus:ring-1" placeholder="Legal Entity Name  " required />
                                    </div>
                                    <div className="col-span-6 sm:col-span-3">
                                        <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Legal Entity Type</label>
                                        <input type="email" name="email" id="email" className="mt-1 px-3 py-2 bg-transparent border shadow-sm border-slate-300 placeholder-slate-400 dark:placeholder-gray-200 dark:text-white focus:outline-none focus:border-orange-300 focus:ring-orange-300 block w-full rounded-lg sm:text-sm focus:ring-1" placeholder="Legal Entity Type" required />
                                    </div>
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-900 dark:text-white">Document of Incorporation or Establishment</label>
                                    <div className="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                                        <div className="space-y-1 text-center">
                                            <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                                <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                            <div className="flex text-sm text-gray-600">
                                                <label for="file-upload" className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500">
                                                    <span>Upload a file</span>
                                                    <input id="file-upload" name="file-upload" type="file" className="sr-only" />
                                                </label>
                                                <p className="pl-1 text-gray-900 dark:text-white">or drag and drop</p>
                                            </div>
                                            <p className="text-xs  text-gray-900 dark:text-white">PNG, JPG, GIF up to 10MB</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-6 sm:col-span-3">
                                        <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Legal Entity Registration Number</label>
                                        <input type="email" name="email" id="email" className="mt-1 px-3 py-2 bg-transparent border shadow-sm border-slate-300 placeholder-slate-400 dark:placeholder-gray-200 dark:text-white focus:outline-none focus:border-orange-300 focus:ring-orange-300 block w-full rounded-lg sm:text-sm focus:ring-1" placeholder="Legal Entity Registration Number" required />
                                    </div>
                                    <div className="col-span-6 sm:col-span-3">
                                        <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Tax ID number</label>
                                        <input type="email" name="email" id="email" className="mt-1 px-3 py-2 bg-transparent border shadow-sm border-slate-300 placeholder-slate-400 dark:placeholder-gray-200 dark:text-white focus:outline-none focus:border-orange-300 focus:ring-orange-300 block w-full rounded-lg sm:text-sm focus:ring-1" placeholder="Tax ID number" required />
                                    </div>
                                </div>

                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-6 sm:col-span-3">
                                        <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Country of Registration</label>
                                        <input type="email" name="email" id="email" className="mt-1 px-3 py-2 bg-transparent border shadow-sm border-slate-300 placeholder-slate-400 dark:placeholder-gray-200 dark:text-white focus:outline-none focus:border-orange-300 focus:ring-orange-300 block w-full rounded-lg sm:text-sm focus:ring-1" placeholder="Country of Registration" required />
                                    </div>
                                    <div className="col-span-6 sm:col-span-3">
                                        <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Creator's main space</label>
                                        <input type="email" name="email" id="email" className="mt-1 px-3 py-2 bg-transparent border shadow-sm border-slate-300 placeholder-slate-400 dark:placeholder-gray-200 dark:text-white focus:outline-none focus:border-orange-300 focus:ring-orange-300 block w-full rounded-lg sm:text-sm focus:ring-1" placeholder="Creator's main space" required />
                                    </div>
                                </div>

                                <fieldset>
                                    <legend className="contents text-base font-medium text-gray-900 dark:text-white">Business or Creator</legend>
                                    {/* <p className="text-sm text-gray-500">These are delivered via SMS to your mobile phone.</p> */}
                                    <div className="mt-4 space-y-4">
                                        <div className="flex items-center">
                                            <input id="push-everything" name="push-notifications" type="radio" className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                                            <label for="push-everything" className="ml-3 block text-sm font-medium text-gray-900 dark:text-white">Business</label>
                                        </div>
                                        <div className="flex items-center">
                                            <input id="push-email" name="push-notifications" type="radio" className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                                            <label for="push-email" className="ml-3 block text-sm font-medium text-gray-900 dark:text-white">Creator</label>
                                        </div>
                                    </div>
                                </fieldset>

                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-6 sm:col-span-3">
                                        <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Website URL</label>
                                        <input type="email" name="email" id="email" className="mt-1 px-3 py-2 bg-transparent border shadow-sm border-slate-300 placeholder-slate-400 dark:placeholder-gray-200 dark:text-white focus:outline-none focus:border-orange-300 focus:ring-orange-300 block w-full rounded-lg sm:text-sm focus:ring-1" placeholder="Website URL" required />
                                    </div>
                                    <div className="col-span-6 sm:col-span-3">
                                        <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Supported links or articles</label>
                                        <input type="email" name="email" id="email" className="mt-1 px-3 py-2 bg-transparent border shadow-sm border-slate-300 placeholder-slate-400 dark:placeholder-gray-200 dark:text-white focus:outline-none focus:border-orange-300 focus:ring-orange-300 block w-full rounded-lg sm:text-sm focus:ring-1" placeholder="Supported links or articles" required />
                                    </div>
                                </div>

                            </div>
                            <Stats step={2} {...props} />
                        </div>
                    </form>
                </div>
            </div>

        </div>
    );
};

const Last = (props) => {
    // const submit = () => {
    //     alert('You did it! Yay!') // eslint-disable-line
    // };

    return (
        <div className="mt-10 sm:mt-0">
            <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                    <div className="px-4 sm:px-0">
                        <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-white">Create NFT and Smart Contract Data</h3>
                        <p className="mt-1 text-sm text-gray-600 dark:text-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce at facilisis lacus.</p>
                    </div>
                </div>
                <div className="mt-5 md:col-span-2 md:mt-0 dark:bg-slate-800/70 rounded-xl shadow-2xl">
                    <form action="#" method="POST">
                        <div className="overflow-hidden shadow sm:rounded-md">
                            <div className="space-y-6 px-4 py-5 sm:p-6">
                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-6 sm:col-span-3">
                                        <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Collection Name</label>
                                        <input type="email" name="email" id="email" className="mt-1 px-3 py-2 bg-transparent border shadow-sm border-slate-300 placeholder-slate-400 dark:placeholder-gray-200 dark:text-white focus:outline-none focus:border-orange-300 focus:ring-orange-300 block w-full rounded-lg sm:text-sm focus:ring-1" placeholder="John" required />
                                    </div>
                                </div>

                                <div>
                                    <label for="about" className="block text-sm font-medium text-gray-900 dark:text-white">Collection Description</label>
                                    <div className="mt-1">
                                        <textarea id="about" name="about" rows="3" className="mt-1 px-3 py-2 bg-transparent border shadow-sm border-slate-300 placeholder-slate-400 dark:placeholder-gray-200 dark:text-white focus:outline-none focus:border-orange-300 focus:ring-orange-300 block w-full rounded-lg sm:text-sm focus:ring-1" placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce at facilisis lacus."></textarea>
                                    </div>
                                    <p className="mt-2 text-sm text-gray-900 dark:text-white">Brief description for your profile. URLs are hyperlinked.</p>
                                </div>

                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-6 sm:col-span-3">
                                        <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Collection Royalty (Optional)</label>
                                        <input type="email" name="email" id="email" className="mt-1 px-3 py-2 bg-transparent border shadow-sm border-slate-300 placeholder-slate-400 dark:placeholder-gray-200 dark:text-white focus:outline-none focus:border-orange-300 focus:ring-orange-300 block w-full rounded-lg sm:text-sm focus:ring-1" placeholder="Collection Royalty" required />
                                    </div>
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-900 dark:text-white">Upload Logo</label>
                                    <div className="mt-1 flex items-center">
                                        <span className="inline-block h-12 w-12 overflow-hidden rounded-full bg-gray-100">
                                            <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                            </svg>
                                        </span>
                                        <button type="button" className="ml-5 rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Change</button>
                                    </div>
                                </div>

                                <fieldset>
                                    <div className="text-base font-medium text-gray-900 dark:text-white" aria-hidden="true">Select or decide the rules of pausable, burnable, mintable, supply tracking, access control</div>
                                    <div className="mt-4 space-y-4">
                                        <div className="flex items-start">
                                            <div className="flex h-5 items-center">
                                                <input id="comments" name="comments" type="checkbox" className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                                            </div>
                                            <div className="ml-3 text-sm">
                                                <label for="comments" className="font-medium text-gray-900 dark:text-white">Pausable</label>
                                                <p className="text-gray-500">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce at facilisis lacus.</p>
                                            </div>
                                        </div>
                                        <div className="flex items-start">
                                            <div className="flex h-5 items-center">
                                                <input id="candidates" name="candidates" type="checkbox" className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                                            </div>
                                            <div className="ml-3 text-sm">
                                                <label for="candidates" className="font-medium text-gray-900 dark:text-white">Burnable</label>
                                                <p className="text-gray-500">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce at facilisis lacus.</p>
                                            </div>
                                        </div>
                                        <div className="flex items-start">
                                            <div className="flex h-5 items-center">
                                                <input id="offers" name="offers" type="checkbox" className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                                            </div>
                                            <div className="ml-3 text-sm">
                                                <label for="offers" className="font-medium text-gray-900 dark:text-white">Mintable</label>
                                                <p className="text-gray-500">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce at facilisis lacus.</p>
                                            </div>
                                        </div>
                                        <div className="flex items-start">
                                            <div className="flex h-5 items-center">
                                                <input id="offers" name="offers" type="checkbox" className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                                            </div>
                                            <div className="ml-3 text-sm">
                                                <label for="offers" className="font-medium text-gray-900 dark:text-white">Supply Tracking</label>
                                                <p className="text-gray-500">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce at facilisis lacus.</p>
                                            </div>
                                        </div>
                                        <div className="flex items-start">
                                            <div className="flex h-5 items-center">
                                                <input id="offers" name="offers" type="checkbox" className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                                            </div>
                                            <div className="ml-3 text-sm">
                                                <label for="offers" className="font-medium text-gray-900 dark:text-white">Access Control</label>
                                                <p className="text-gray-500">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce at facilisis lacus.</p>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>

                                <div className="hidden sm:block" aria-hidden="true">
                                    <div className="py-5">
                                        <label className="block text-sm font-medium text-gray-900 dark:text-white">Levels</label>
                                        <div className="border-t border-gray-600"></div>
                                    </div>
                                </div>

                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-6 sm:col-span-3">
                                        <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Level Name</label>
                                        <input type="email" name="email" id="email" className="mt-1 px-3 py-2 bg-transparent border shadow-sm border-slate-300 placeholder-slate-400 dark:placeholder-gray-200 dark:text-white focus:outline-none focus:border-orange-300 focus:ring-orange-300 block w-full rounded-lg sm:text-sm focus:ring-1" placeholder="Level Name" required />
                                    </div>
                                    <div className="col-span-6 sm:col-span-3">
                                        <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Price</label>
                                        <input type="email" name="email" id="email" className="mt-1 px-3 py-2 bg-transparent border shadow-sm border-slate-300 placeholder-slate-400 dark:placeholder-gray-200 dark:text-white focus:outline-none focus:border-orange-300 focus:ring-orange-300 block w-full rounded-lg sm:text-sm focus:ring-1" placeholder="Price" required />
                                    </div>
                                </div>

                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-6 sm:col-span-3">
                                        <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Naming Preference</label>
                                        <input type="email" name="email" id="email" className="mt-1 px-3 py-2 bg-transparent border shadow-sm border-slate-300 placeholder-slate-400 dark:placeholder-gray-200 dark:text-white focus:outline-none focus:border-orange-300 focus:ring-orange-300 block w-full rounded-lg sm:text-sm focus:ring-1" placeholder="Naming Preference" required />
                                    </div>
                                </div>

                                <div>
                                    <label for="about" className="block text-sm font-medium text-gray-900 dark:text-white">Description</label>
                                    <div className="mt-1">
                                        <textarea id="about" name="about" rows="3" className="mt-1 px-3 py-2 bg-transparent border shadow-sm border-slate-300 placeholder-slate-400 dark:placeholder-gray-200 dark:text-white focus:outline-none focus:border-orange-300 focus:ring-orange-300 block w-full rounded-lg sm:text-sm focus:ring-1" placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce at facilisis lacus."></textarea>
                                    </div>
                                    <p className="mt-2 text-sm text-gray-500">Brief description for your profile. URLs are hyperlinked.</p>
                                </div>

                                <div>
                                    <label for="about" className="block text-sm font-medium text-gray-900 dark:text-white">Back Attributes</label>
                                    <div className="mt-1">
                                        <textarea id="about" name="about" rows="3" className="mt-1 px-3 py-2 bg-transparent border shadow-sm border-slate-300 placeholder-slate-400 dark:placeholder-gray-200 dark:text-white focus:outline-none focus:border-orange-300 focus:ring-orange-300 block w-full rounded-lg sm:text-sm focus:ring-1" placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce at facilisis lacus."></textarea>
                                    </div>
                                    <p className="mt-2 text-sm text-gray-500">Brief description for your profile. URLs are hyperlinked.</p>
                                </div>

                                <div>
                                    <label for="about" className="block text-sm font-medium text-gray-900 dark:text-white">Optional Attributes</label>
                                    <div className="mt-1">
                                        <textarea id="about" name="about" rows="3" className="mt-1 px-3 py-2 bg-transparent border shadow-sm border-slate-300 placeholder-slate-400 dark:placeholder-gray-200 dark:text-white focus:outline-none focus:border-orange-300 focus:ring-orange-300 block w-full rounded-lg sm:text-sm focus:ring-1" placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce at facilisis lacus."></textarea>
                                    </div>
                                    <p className="mt-2 text-sm text-gray-500">Brief description for your profile. URLs are hyperlinked.</p>
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-900 dark:text-white">Upload a batch of images (or any type of content)</label>
                                    <div className="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                                        <div className="space-y-1 text-center">
                                            <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                                <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                            <div className="flex text-sm text-gray-600">
                                                <label for="file-upload" className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500">
                                                    <span>Upload a file</span>
                                                    <input id="file-upload" name="file-upload" type="file" className="sr-only" />
                                                </label>
                                                <p className="pl-1">or drag and drop</p>
                                            </div>
                                            <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-900 dark:text-white">Upload batch list of animation URL</label>
                                    <div className="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                                        <div className="space-y-1 text-center">
                                            <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                                <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                            <div className="flex text-sm text-gray-600">
                                                <label for="file-upload" className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500">
                                                    <span>Upload a file</span>
                                                    <input id="file-upload" name="file-upload" type="file" className="sr-only" />
                                                </label>
                                                <p className="pl-1">or drag and drop</p>
                                            </div>
                                            <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <label for="about" className="block text-sm font-medium text-gray-900 dark:text-white">Collection social media details</label>
                                    <div className="mt-1">
                                        <textarea id="about" name="about" rows="3" className="mt-1 px-3 py-2 bg-transparent border shadow-sm border-slate-300 placeholder-slate-400 dark:placeholder-gray-200 dark:text-white focus:outline-none focus:border-orange-300 focus:ring-orange-300 block w-full rounded-lg sm:text-sm focus:ring-1" placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce at facilisis lacus."></textarea>
                                    </div>
                                    <p className="mt-2 text-sm text-gray-500">Brief description for your profile. URLs are hyperlinked.</p>
                                </div>

                                <fieldset>
                                    <legend className="contents text-base font-medium text-gray-900 dark:text-white">Select minter address</legend>
                                    {/* <p className="text-sm text-gray-500">These are delivered via SMS to your mobile phone.</p> */}
                                    <div className="mt-4 space-y-4">
                                        <div className="flex items-center">
                                            <input id="push-everything" name="push-notifications" type="radio" className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                                            <label for="push-everything" className="ml-3 block text-sm font-medium text-gray-900 dark:text-white">wallet</label>
                                        </div>
                                        <div className="flex items-center">
                                            <input id="push-email" name="push-notifications" type="radio" className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                                            <label for="push-email" className="ml-3 block text-sm font-medium text-gray-900 dark:text-white">seedsafe</label>
                                        </div>
                                    </div>
                                </fieldset>

                                <div className="px-4 py-3 text-right sm:px-6">
                                    <button type="submit" className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Add more</button>
                                </div>

                                <div className="hidden sm:block" aria-hidden="true">
                                    <div className="py-5">
                                        <div className="border-t border-gray-600"></div>
                                    </div>
                                </div>

                            </div>
                            <Stats step={3} {...props} />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

const Stats = ({
    currentStep,
    // firstStep,
    // goToStep,
    // lastStep,
    nextStep,
    previousStep,
    totalSteps,
    step,
}) => (
    <div>
        <div className="flex justify-between px-4 py-3 sm:px-6">
            {step > 1 &&
                <button className='bg-gray-200 p-4 dark:text-gray-200 hover:bg-gray-300 dark:bg-sky-500/30 dark:hover:border-blue-100 hover:border-2 dark:border-white/70 border-transparent border-2 hover:shadow-md text-gray-600 font-semibold rounded-full transition-all duration-300 py-1 shadow-md hover:opacity-50' onClick={previousStep}>Go Back</button>
            }
            <legend className="contents text-base font-medium text-gray-900 dark:text-white">Step {currentStep}</legend>
            {step < totalSteps ?
                <button className='bg-gray-200 p-4 dark:text-gray-200 hover:bg-gray-300 dark:bg-sky-500/30 dark:hover:border-blue-100 hover:border-2 dark:border-white/70 border-transparent border-2 hover:shadow-md text-gray-600 font-semibold rounded-full transition-all duration-300 py-1 shadow-md hover:opacity-50' onClick={nextStep}>Continue</button>
                :
                <button className='bg-gray-200 p-4 dark:text-gray-200 hover:bg-gray-300 dark:bg-sky-500/30 dark:hover:border-blue-100 hover:border-2 dark:border-white/70 border-transparent border-2 hover:shadow-md text-gray-600 font-semibold rounded-full transition-all duration-300 py-1 shadow-md hover:opacity-50' onClick={nextStep}>Finish</button>
            }
        </div>
    </div>
);