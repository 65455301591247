import React from "react";
import checkmark from "./../assets/checkmark.png";
import { IoIosPeople } from "react-icons/io";
import { MdEmojiPeople } from "react-icons/md";
import { VscOrganization } from "react-icons/vsc";

export default function Benefits() {
  return (
    <section className="container-fluid md:container md:mx-auto xl:mt-60 mx-8 xl:mb-40">
      <div className="grid grid-cols-1 xl:grid-cols-4">
        <div>
          <p className="text-base uppercase font-semibold tracking-widest text-transparent bg-clip-text bg-gradient-to-r from-[#2a4dd0] to-[#6e8cff]">
            Access
          </p>
          <h4 className="text-[#212429] text-6xl font-bold my-8 xl:my-0">
            Benefits
          </h4>
        </div>
        <div className="col-span-3 w-1/2">
          {/* <p className="text-base text-black">
            Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
            sint. Velit officia consequat duis enim velit mollit.
          </p> */}
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8">
        <div className="rounded-lg drop-shadow-2xl bg-white p-12 my-12 cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-[#F3F4F8] duration-300 text-center">
          <span className="inline-block p-3 text-gray-200 bg-blue-700 rounded-full ">
            <MdEmojiPeople size={70} />
          </span>
          <h1 className="text-2xl font-semibold text-gray-600 dark:text-gray-200 capitalize my-6">
            Artist
          </h1>
          <div className="flex justify-center">
            <ul class="list-none text-left">
              <li>Create VIP experiences</li>
              <li>Build direct links with Fans</li>
              <li>Release your NFT collection</li>
              <li>NFT-Content sales and royalties</li>
            </ul>
          </div>
        </div>

        <div className="rounded-lg drop-shadow-2xl bg-white p-12 my-12 cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-[#F3F4F8] duration-300 text-center">
          <span className="inline-block p-3 text-gray-200 bg-blue-700 rounded-full ">
            <IoIosPeople size={70} />
          </span>
          <h1 className="text-2xl font-semibold text-gray-600 dark:text-gray-200 capitalize my-6">
            Fan
          </h1>
          <div className="flex justify-center">
            <ul class="list-none text-left">
              <li>Guaranteed ticket access</li>
              <li>Exclusive VIP experiences</li>
              <li>Ownership of access rights</li>
              <li>Own unique digital content</li>
              <li>Buy, sell or rent-out your NFT</li>
            </ul>
          </div>
        </div>

        <div className="rounded-lg drop-shadow-2xl bg-white p-12 my-12 cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-[#F3F4F8] duration-300 text-center">
          <span className="inline-block p-3 text-gray-200 bg-blue-700 rounded-full ">
            <VscOrganization size={70} />
          </span>
          <h1 className="text-2xl font-semibold text-gray-600 dark:text-gray-200 capitalize my-6">
            Ticket / Event Organizer
          </h1>
          <div className="flex justify-center">
            <ul class="list-none text-left">
              <li>Build fan loyalty and network</li>
              <li>Create NFT infrastructure</li>
              <li>Implement rewards programs</li>
              <li>NFT-Content sales and royalties</li>
              <li>Create NFTs with utility</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}


const Layout = ({ children, title, icon }) => {
  return (
    <div className="flex flex-col p-6 space-y-3 bg-white/5 rounded-xl shadow-2xl duration-200 transform hover:border-blue-700 hover:shadow-lime-500">
      <div className="my-auto items-center text-center pt-6">
        <span className="inline-block p-3 text-gray-200 bg-blue-700 rounded-full ">
          {icon}
        </span>
        <h1 className="text-2xl font-semibold text-gray-600 dark:text-gray-200 capitalize mb-3 mt-3">
          {title}
        </h1>
        <p className="dark:text-gray-300 text-gray-600">{children}</p>
      </div>
    </div>
  );
};