import React from "react";
import { Widget } from "@typeform/embed-react";

export default function ApplyPage() {
  return (
    // <div className="h-screen w-screen bg-yellow-300">
    //   <h1>Test</h1>
    // </div>
    <Widget id="VeOABmg5" className="my-form w-screen h-screen" />
  );
}
