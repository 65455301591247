import React from "react";
import heroimgbg from "./../assets/heroimgbg.png";
import heroimg1 from "./../assets/heroimg1.png";
import heroimg2 from "./../assets/heroimg2.png";
import heroimg3 from "./../assets/heroimg3.png";
import "./Hero.css";

export default function Hero() {
  return (
    <div className="bg-white">
      <div className="cus-div">
        <section
          id="hero"
          className="md:grid md:grid-cols-2 gap-4 items-center container px-6 py-2 mx-auto pb-10"
        >
          <div className="... ">
            <div className="flex flex-col static dark:text-gray-200 text-gray-600 md:text-4xl xl:text-6xl 2xl:text-6xl xl:mt-16 xl:mr-20">
              <div className="flex flex-wrap md:justify-start justify-center drop-shadow-xl subpixel-antialiased">
                <h1 className="text-[#212429] dark:text-white text-[80px] font-bold leading-normal">
                  Explore your{" "}
                  <span className="text-[80px] font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-[#2a4dd0] to-[#6e8cff]">
                    Collections
                  </span>{" "}
                </h1>
              </div>

              <p className="mt-10 mb-10 md:place-self-start text-base md:text-base font-base">
                Unlock early ticket access, VIPNFT-only offers and VIP experiences
                money can't buy. Connecting fans with artists, event organisers and
                content creators like never before.
              </p>
            </div>
          </div>
          {/* <div className="h-96 md:w-5/6 max-w-xl shadow-xl rounded-xl grid place-items-center bg-slate-900/40 shadow-sky-900 md:ml-auto mx-auto">
        <span className="text-slate-100/60 text-xl font-normal italic items-center text-center">
          <FaPlay size={50} className="mx-auto my-auto mb-3" />
          Explainer Video Coming Soon
        </span>
      </div> */}
          <div
            className="h-96 md:w-5/6 max-w-xl grid place-items-center md:ml-auto mx-auto h-[580px] relative"
            style={{
              backgroundImage: `url(${heroimgbg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              width: "100%",
            }}
          >
            <img src={heroimg1} className="absolute right-4 top-0" alt="/" />
            <img src={heroimg2} className="absolute bottom-8" style={{ zIndex: 2 }} alt="/" />
            <img src={heroimg3} className="absolute -left-4 top-12" alt="/" />
          </div>
        </section>
      </div>
    </div>

  );
}
