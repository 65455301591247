import React from "react";
import gold_logo from "./../assets/gold_logo_1.png";
import gold_img from "./../assets/gold_img_1.png";
import palladium_img from "./../assets/palladium_img_1.png";
import palladium_logo from "./../assets/palladium_logo_1.png";
import diamond_logo from "./../assets/diamond_logo_1.png";
import diamond_img from "./../assets/diamond_img_1.png";
import hero_visuals from "./../assets/hero_visual.png";

import logo_gold from "../assets/logo/logo_gold_nocap.png";
import logo_palladium from "../assets/logo/logo_palladium_nocap.png";
import logo_diamond from "../assets/logo/Logo-Diamond.png";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from "react-icons/fa";
import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io";

export default function LevelsPage() {
  const bg_btn = {
    background: "linear-gradient(90.11deg, #28A2C3 2.03%, #DA3E3F 97.3%)",
  };

  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 3 },
  };

  return (
    <section className="container mx-auto space-y-28 mb-72">
      <div className="grid grid-cols-1 lg:grid-cols-2 space-x-12">
        <div className="my-auto">
          <div className="flex gap-8 justify-between items-center text-3xl">
            <p className="text-[#28A2C3] font-semibold">Gold VIPNFT</p>
            <img src={logo_gold} alt="Logo" className="w-1/12" />
          </div>
          <p className="text-gray-300 text-xl mt-8 text-left">
            Receive a Digital Collectible (NFT) for each completed survey.
            Complete the series for your chance to win a Gold VIPNFT (1 of 20)
            or $5,000 (cash or Ethereum).
          </p>
          <ul className="list-disc text-gray-300 text-xl text-left leading-loose ml-8 mt-2">
            <li>
              Short surveys for this entry level NFT ({`<`}2min per survey)
            </li>
            <li>Each NFT is created with unique digital content.</li>
            <li>Unlock Early Ticket Access (4 General Entry Tickets)</li>
            <li>5% Ticket Discount (next booking only)</li>
            <li>Event Treasure Hunts (collect more benefits)</li>
            <li>Load Purkle Dollars ($2 per survey)</li>
            <li>Build the value of your NFT over time</li>
            <li>Sell, Transfer or Hold</li>
            <li>Exciting Future developments</li>
            <li>Early access to future NFT releases</li>
          </ul>
        </div>
        <div className="flex justify-center items-center">
          <img
            src={gold_img}
            alt="gold img"
            className=" w-9/12 my-auto mx-auto"
          />
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 space-x-12">
        <div className="order-last lg:order-first">
          <img
            src={palladium_img}
            alt="palladium img"
            className=" w-9/12 my-auto mx-auto"
          />
        </div>
        <div className="my-auto">
          <div className="flex gap-8 justify-between items-center text-3xl">
            <p className="text-[#28A2C3] font-semibold">Palladium VIPNFT</p>
            <img src={logo_palladium} alt="Logo" className="w-1/12 " />
          </div>
          <p className="text-gray-300 text-xl mt-8 text-left">
            Receive a Premium Digital Collectible (NFT) for each completed
            survey. Complete the series for your chance to win a Palladium
            VIPNFT (1 of 10) or $10,000 (cash or Ethereum).
          </p>
          <ul className="list-disc text-gray-300 text-xl text-left leading-loose ml-8 mt-2">
            <li>
              Short-ish surveys for this premium NFT ({`<`}4min per survey)
            </li>
            <li>Each NFT created with unique digital content</li>
            <li>Unlock Early Access to VIP experiences</li>
            <li>Unlock Early Ticket Access (4 Premium Seating Tickets)</li>
            <li>10% Ticket Discount (next booking only)</li>
            <li>Event Treasure Hunts (collect more benefits)</li>
            <li>Load Purkle Dollars ($3 per survey)</li>
            <li>Build the value of your NFT over time</li>
            <li>Sell, Transfer or Hold</li>
            <li>Exciting Future developments</li>
            <li>First access to future NFT releases</li>
          </ul>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 space-x-12">
        <div className="my-auto">
          <div className="flex gap-8 justify-between items-center text-3xl">
            <p className="text-[#28A2C3] font-semibold">Diamond VIPNFT</p>
            <img src={logo_diamond} alt="Logo" className="w-1/12 " />
          </div>
          <p className="text-gray-300 text-xl mt-8 text-left">
            Receive a VIP Digital Collectible (NFT) for each completed survey.
            Complete the series for your chance to win a Diamond VIPNFT (1 of 5)
            or $15,000 (cash or Ethereum).
          </p>
          <ul className="list-disc text-gray-300 text-xl text-left leading-loose ml-8 mt-2">
            <li>Longer surveys for this VIP NFT (10-15 min per survey)</li>
            <li>Unlock First Access to VIP experiences</li>
            <li>Unlock Early Ticket Access (4 VIP Tickets)</li>
            <li>20% Ticket Discount (next booking only)</li>
            <li>Event Treasure Hunts (collect more benefits)</li>
            <li>Load Purkle Dollars ($5 per survey)</li>
            <li>Build the value of your NFT over time</li>
            <li>Sell, Transfer or Hold this digital asset</li>
            <li>Exciting future developments</li>
            <li>First access to future NFT releases</li>
          </ul>
        </div>
        <div className="">
          <img
            src={diamond_img}
            alt="diamond img"
            className=" w-9/12 my-auto mx-auto"
          />
        </div>
      </div>

      <section className="h-48 overflow-y-scroll border-2 border-[#28A2C3] rounded-md hidden">
        <div className="justify-center p-5 container max-w-6xl mx-auto rounded-xl">
          <p className="text-center text-2xl text-gray-300 font-bold">
            Don't just capture the moment...own it!
          </p>
          <p className="text-gray-300 text-xl mt-2 text-center max-w-6xl">
            We are building an exciting rewards platform where you own and
            control a unique digital asset. Earn your right to claim one of
            these limited release NFT series. Benefits include access to VIP
            experiences, ownership of unique digital content and gamification
            linked to your favourite artists and events.
          </p>
        </div>

        <div className="mt-14 justify-center p-5 container max-w-6xl mx-auto rounded-xl">
          <h1 className="h1 text-center text-2xl text-gray-300 font-bold">
            Where can I access my NFT?
          </h1>
          <p className="text-gray-300 text-xl mt-3 text-center">
            Ethereum NFT - Visible in your Purkle wallet or any Ethereum
            compatible platform like{" "}
            <a href="https://opensea.io" className="text-blue-400">
              {" "}
              Opensea.io
            </a>
          </p>
        </div>
        <div className="mt-14 justify-center p-5 container max-w-6xl mx-auto rounded-xl">
          <h1 className="h1 text-center text-2xl text-gray-300 font-bold">
            What is an NFT?
          </h1>
          <p className="text-gray-300 text-xl mt-3 text-center">
            NFTs are digital assets you own and can be sold.
          </p>
        </div>
        <div className="mt-14 justify-center p-5 container max-w-6xl mx-auto rounded-xl">
          <h1 className="h1 text-center text-2xl text-gray-300 font-bold">
            Can I simply purchase the NFT?
          </h1>
          <p className="text-gray-300 text-xl mt-3 text-center">
            Yes. You can visit our collection at Opensea to purchase your
            preferred NFT from a range within the collection. A dedicated number
            will only be available to those who complete the surveys.
          </p>
        </div>
      </section>
      <div className="grid grid-cols-1 lg:grid-cols-2 py-36">
        <div>
          <img
            src={hero_visuals}
            alt="hero visuals"
            className="w-9/12 my-auto mx-auto"
          />
        </div>
        <div className="h-fit my-auto">
          <h1 className="text-[#28A2C3]  font-semibold text-5xl text-center tracking-wide">
            Don't miss out on this{" "}
            <span className="text-gray-300">limited edition</span> series of
            NFTs!
          </h1>

          <div className="justify-center flex mt-8 gap-2">
            <input
              type="text"
              className="input bg-white/10 rounded-md w-96 h-12 text-white text-xl text-center my-auto active:border-[#28A2C3] focus:border-[#28A2C3] placeholder:text-white"
              placeholder="Enter your email"
            />

            <button
              className="p-12 text-white font-semibold rounded-full transition-all duration-300 py-2 shadow-md hover:opacity-50"
              style={bg_btn}
            >
              <p className="text-lg md:text-xl ">Join the waitlist</p>
            </button>
          </div>
        </div>
      </div>
      <div className=" pt-36 md:p-0 p-7">
        <div className="justify-center p-5 container w-9/12 mx-auto mb-12">
          <p className="text-center text-xl text-gray-300 font-bold">
            Don't just capture the moment... Own it!
          </p>
          <p className="text-gray-300 text-lg mt-2 text-center">
            We are building an exciting rewards platform where you own and
            control a unique digital asset.
            <br /> Benefits include access to VIP experiences, ownership of
            unique digital content and gamification linked to your favourite
            artists and events.
          </p>
        </div>
        <div className="relative ">
          <AliceCarousel
            mouseTracking
            items={items}
            controlsStrategy="default"
            fadeOutAnimation={true}
            mouseDragEnabled={true}
            playButtonEnabled={false}
            autoHeight={true}
            responsive={responsive}
            infinite
            renderPrevButton={() => {
              return (
                <IoIosArrowDropleft
                  className="text-gray-300 absolute left-0 top-1/3 hover:opacity-50 hover:cursor-pointer"
                  size={30}
                />
              );
            }}
            renderNextButton={() => {
              return (
                <IoIosArrowDropright
                  className="text-gray-300 absolute right-0 top-1/3 hover:opacity-50 hover:cursor-pointer"
                  size={30}
                />
              );
            }}
          />
        </div>
      </div>
    </section>
  );
}

const items = [
  <div className="justify-center p-5 container w-9/12 mx-auto rounded-xl bg-slate-500/50 h-full flex max-h-56">
    <div className="my-auto h-fit">
      <h1 className="h1 text-center text-xl text-gray-300 font-bold">
        Where can I access my NFT?
      </h1>
      <p className="text-gray-300 text-lg mt-3 text-center">
        Your NFT will be visible in your Purkle/Ticketek dashboard, in your
        VIPNFT wallet or on{" "}
        <a
          href="https://opensea.io"
          className="text-blue-400 hover:underline"
          target="_blank"
          rel="noreferrer"
        >
          Opensea.io
        </a>
      </p>
    </div>
  </div>,

  <div className="justify-center p-5 container w-9/12 mx-auto rounded-xl bg-slate-500/50 h-full flex">
    <div className="my-auto h-fit">
      <h1 className="h1 text-center text-xl text-gray-300 font-bold">
        What is an NFT?
      </h1>
      <p className="text-gray-300 text-lg mt-3 text-center">
        A Non-Fungible Token (NFT) is a unique digital asset or "digital
        collectible" that can be owned, transferred or sold.
      </p>
    </div>
  </div>,

  <div className="justify-center p-5 container w-9/12 mx-auto rounded-xl bg-slate-500/50 h-full flex">
    <div className="my-auto h-fit">
      <h1 className="h1 text-center text-xl text-gray-300 font-bold">
        What type of NFT is this?
      </h1>
      <p className="text-gray-300 text-lg mt-3 text-center">
        TEG NFTs are built on the Ethereum network and use the ERC-1155
        standard. They are unique and issued by TEG.
      </p>
    </div>
  </div>,

  <div className="justify-center p-5 container w-9/12 mx-auto rounded-xl bg-slate-500/50 h-full flex">
    <div className="my-auto h-fit">
      <h1 className="h1 text-center text-xl text-gray-300 font-bold">
        Do I need a web3 wallet?
      </h1>
      <p className="text-gray-300 text-lg mt-3 text-center">
        Not if you want to access the benefits and hold your asset. If you wish
        to list or sell on other platforms, we will provide a streamlined
        process to access your web3 wallet.
      </p>
    </div>
  </div>,

  <div className="justify-center p-5 container w-9/12 mx-auto rounded-xl bg-slate-500/50 h-full flex">
    <div className="my-auto h-fit">
      <h1 className="h1 text-center text-xl text-gray-300 font-bold">
        Will TEG release more NFTs?
      </h1>
      <p className="text-gray-300 text-lg mt-3 text-center">
        Each offering is a limited release. There will be other NFT products
        with different benefits and attributes. As a TEG NFT holder, you will
        receive advance notice of future NFT releases.
      </p>
    </div>
  </div>,

  <div className="justify-center p-5 container w-9/12 mx-auto rounded-xl bg-slate-500/50 h-full flex">
    <div className="my-auto h-fit">
      <h1 className="h1 text-center text-xl text-gray-300 font-bold">
        Can I purchase a TEG NFT?
      </h1>
      <p className="text-gray-300 text-lg mt-3 text-center">
        Yes, a limited number of NFT offerings will be available for purchase.
        The collection can be purchased on VIPNFT and may also be listed on
        Opensea. Once an NFT has been earned through a survey, the owner may
        also decide to offer it for sale.
      </p>
    </div>
  </div>,
  <div className="justify-center p-5 container w-9/12 mx-auto rounded-xl bg-slate-500/50 h-full flex">
    <div className="my-auto h-fit">
      <h1 className="h1 text-center text-xl text-gray-300 font-bold">
        Aren't NFTs bad for the environment?
      </h1>
      <p className="text-gray-300 text-lg mt-3 text-center">
        No. Since Ethereum moved to Proof Of Stake (POS), NFTs are
        environmentally friendly.
        {` `}
        <a
          href="https://ethereum.org/en/energy-consumption/"
          target="_blank"
          rel="noreferrer"
          className="text-blue-400 hover:underline"
        >
          Visit the ethereum.org website for more information.
        </a>
      </p>
    </div>
  </div>,
  <div className="justify-center p-5 container w-9/12 mx-auto rounded-xl bg-slate-500/50 h-full flex">
    <div className="my-auto h-fit">
      <h1 className="h1 text-center text-xl text-gray-300 font-bold">
        Why are you creating NFTs?
      </h1>
      <p className="text-gray-300 text-lg mt-3 text-center">
        Digital collectibles offer a range of benefits. From direct connection
        to artists, other fans and organisers through to early ticket access and
        lasting memories of your favourite experiences. Turn your fandom into an
        asset you can hold, build or sell.
      </p>
    </div>
  </div>,
];
