import React from "react";
import hero_img_1 from "./../assets/HERO-VISUAL-2.png";
import purkle_logo from "./../assets/purkle_logo.png";
import teg_logo from "./../assets/teg_logo.png";
import teg_logo2 from "./../assets/TEG_2018_logo_Dark.png";

export default function HeroPage() {
  const bg_btn = {
    background: "linear-gradient(90.11deg, #28A2C3 2.03%, #DA3E3F 97.3%)",
  };

  return (
    <section
      id="header"
      className="md:grid md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-2 items-center px-16 py-8 mx-auto container"
    >
      <div className="flex text-center justify-center md:justify-start">
        {" "}
        <img src={hero_img_1} alt="hero img" />
      </div>
      <div className="order-last md:order-first">
        <img src={purkle_logo} alt="purkle logo" />
        <h1 className="text-[#DA3E3F] font-semibold text-6xl text-left  uppercase tracking-wide">
          Have your say
        </h1>
        <h1 className="text-[#DA3E3F] font-semibold text-6xl text-left  uppercase tracking-wide">
          {" "}
          Earn Digital Perks
        </h1>
        <p className="text-gray-200 font-semibold text-3xl flex gap-2 mt-8">
          Complete surveys, claim your
          <img src={teg_logo2} alt="teg logo" className="inline h-11" />
        </p>
        <p className="text-gray-200 font-semibold text-3xl flex gap-4">
          Digital Collectibles for VIP rewards.
        </p>
        <button
          className="p-12 text-white font-semibold rounded-full transition-all duration-300 py-2 shadow-md my-8 hover:opacity-50"
          style={bg_btn}
        >
          <p className="text-lg md:text-xl font-bold">Start now</p>
        </button>
      </div>
    </section>
  );
}
