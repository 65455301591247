import React from "react";
import ReactMarkdown from "react-markdown";

const article = ({ title, description }) => {
  return (
    <div className="xl:mt-8 mb-8">
      <h1 className="text-3xl font-bold text-header lg:text-4xl dark:text-gray-200 text-gray-600">
        {title}
      </h1>

      <p className="py-2 mt-8 text-base leading-relaxed dark:text-gray-200 text-gray-600">
        <ReactMarkdown
          className="prose dark:prose-invert dark:text-gray-300"
          children={description}
        />
      </p>
    </div>
  );
};

export default article;
