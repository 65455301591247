import React, { useState, useContext } from "react";
import { MdOutlineFlipCameraAndroid } from "react-icons/md";
import ReactCardFlip from "react-card-flip";
import img_level_diamond from "./assets/logo/Logo-Diamond.png";
import img_level_palladium from "./assets/logo/logo_palladium_nocap.png";
import img_level_gold from "./assets/logo/logo_gold_nocap.png";
import logo_pa from "./assets/logo/logo_PA.png";
import logo_ticketek from "./assets/logo/logo_Ticketek.png";
import ic_flip_black from "./assets/icons/ic_flip_black.png";
import ic_flip_white from "./assets/icons/ic_flip_white.png";
import { ThemeContext } from "../App";

const Card = ({
  title,
  level,
  description,
  picture,
  price,
  children,
  color,
  width,
  company,
  className,
  extra,
}) => {
  const { theme } = useContext(ThemeContext);
  const [isFlipped, setIsFlipped] = useState(false);
  const ic_level =
    level === "Diamond"
      ? img_level_diamond
      : level === "Palladium"
      ? img_level_palladium
      : img_level_gold;

  return (
    <>
      <ReactCardFlip
        isFlipped={isFlipped}
        flipDirection="horizontal"
        flipSpeedBackToFront={1}
        flipSpeedFrontToBack={1}
      >
        {/* FRONT */}
        <div
          className={`max-w-sm mx-auto overflow-hidden bg-white/5 rounded-lg shadow-xl hover:shadow-lg transform duration-200 dark:hover:shadow-orange-300 hover:shadow-sky-500 h-max mb-0 ${width} dark:bg-slate-900 group transition-all duration-200 ease-in-out ${className}`}
        >
          <img
            src={
              company === "PA"
                ? logo_pa
                : company === "Ticketek"
                ? logo_ticketek
                : null
            }
            alt={title}
            className={`absolute right-0 h-12 opacity-50 transition-all duration-200 ease-in-out`}
          />
          <div
            className="overflow-hidden w-80 h-80 transition-all duration-200 ease-in-out"
            style={{
              backgroundImage: `url(${picture})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: "100%",
            }}
          >
            {/* <img
              className="object-cover object-center"
              src={picture}
              alt="avatar"
            /> */}
          </div>
          <div className="px-6 py-2 h-52">
            <div className="flex items-center mb-1 justify-items-center">
              <img src={ic_level} alt="level" className="h-7 mr-2" />
              <h1 className="text-normal sentence font-mono text-gray-600 dark:text-gray-200">
                {level}
              </h1>
            </div>

            <h1 className="text-xl font-semibold text-gray-600 dark:text-gray-200">
              {title}
            </h1>
            <p className="py-1 text-gray-600 dark:text-gray-300">
              {description}
            </p>
            <div className="bottom-4 left-4 right-4 absolute">
              <div className="grid grid-cols-3 gap-8 mt-4 xl:gap-16 md:grid-cols-3 xl:grid-cols-3 content-center relative">
                <h1 className="text-lg font-bold text-gray-600 dark:text-gray-200 self-center absolute bottom-1.5 left-0 ">
                  {extra !== undefined ? extra : <>US${price}</>}
                </h1>
                <button
                  className="text-lg font-bold text-gray-600 dark:text-gray-200 mx-0 justify-self-center transform duration-700 rounded-3xl hover:text-blue-900 focus:bg-gray-400 focus:outline-none absolute bottom-2"
                  onClick={() => setIsFlipped(!isFlipped)}
                >
                  <img
                    src={theme === "dark" ? ic_flip_white : ic_flip_black}
                    alt="flip"
                    className="group-hover:w-8 transition-all duration-300 dark:bg-gray-900 rounded-lg w-6 shadow-none group-hover:border-2 group-hover:border-gray-400 "
                  />
                </button>
                <button className="px-5 py-1 text-xs font-semibold text-white uppercase transition-colors duration-200 transform bg-blue-700 rounded hover:bg-gray-200 focus:bg-gray-400 focus:outline-none absolute bottom-1.5 right-0">
                  Buy
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* BACK */}
        <div
          className={`max-w-sm mx-auto overflow-hidden bg-white/5 rounded-lg shadow-xl hover:shadow-lg transform duration-200 dark:hover:shadow-orange-300 hover:shadow-sky-500 h-max mb-0 ${width} dark:bg-slate-900 group ${className}`}
        >
          <div className="h-80 px-6 py-4 text-gray-600 dark:text-gray-200 text-center mx-auto">
            <h1 className="text-xl font-semibold mt-3 text-gray-600 dark:text-gray-200 ">
              {title}
            </h1>
            <img
              className={`${`h-24 w-24`} self-center mx-auto`}
              src={ic_level}
              alt="avatar"
            />
            {children}
          </div>
          <div className="px-6 py-4 h-52">
            {/* PROGRESS BAR */}
            <div className="bottom-4 left-4 right-4 absolute">
              <div className="grid grid-cols-3 gap-8 mt-4 xl:gap-16 md:grid-cols-3 xl:grid-cols-3 content-center relative">
                <h1 className="text-lg font-bold text-gray-600 dark:text-gray-200 self-center absolute bottom-1.5 left-0 ">
                  {extra !== undefined ? extra : <>US${price}</>}
                </h1>
                <button
                  className="text-lg font-bold text-gray-600 dark:text-gray-200 mx-0 justify-self-center transform duration-700 rounded-3xl hover:text-blue-900 focus:bg-gray-400 focus:outline-none absolute bottom-2"
                  onClick={() => setIsFlipped(!isFlipped)}
                >
                  <img
                    src={theme === "dark" ? ic_flip_white : ic_flip_black}
                    alt="flip"
                    className="group-hover:w-8 transition-all duration-300 dark:bg-gray-900 rounded-lg w-6 shadow-none group-hover:border-2 group-hover:border-gray-400 "
                  />
                </button>
                <button className="px-5 py-1 text-xs font-semibold text-white uppercase transition-colors duration-200 transform bg-blue-700 rounded hover:bg-gray-200 focus:bg-gray-400 focus:outline-none absolute bottom-1.5 right-0 ">
                  Buy
                </button>
              </div>
            </div>
          </div>
        </div>
      </ReactCardFlip>
    </>
  );
};

export default Card;
