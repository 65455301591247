import React, { useContext, useState } from 'react'
import AppNavbar from "../../components/app-navbar";
import AppFooter from "../../components/footer";
import { ThemeContext } from "../../App";
import img_background_light from "../../components/assets/backgrounds/Bg-light2.png";
import img_background_dark from "../../components/assets/backgrounds/Dark-desktop-1.png";
import Helmet from "./../../components/helmet";
import Type from "./components/type"
import Hero from "./components/hero"
import Blocks from "./components/blocks"
import Contact from "./components/contact"
import AccountModal from "./../../components/account-modal"

export default function CreatePage() {
    const { theme } = useContext(ThemeContext);
    const [modal, setModal] = useState("hidden");
    const [page, setPage] = useState("login");

    function changePage(page) {
        setPage(page);
    }

    return (
        <div
            className={`pt-16 overflow-hidden ${theme === "dark" ? "dark" : "light"
                } transition-all duration-300`}
            style={{
                backgroundImage:
                    theme === "dark"
                        ? `url(${img_background_dark})`
                        : `url(${img_background_light})`,
                backgroundSize: "cover",
                width: "100vw",
                minWidth: "100vw",
            }}
        >
            <Helmet title={"VIP NFT | Create"} />
            <AppNavbar openModal={setModal} modal={modal} />
            <AccountModal
                modal={modal}
                setModal={setModal}
                theme={theme}
                page={page}
                changePage={changePage}
            />
            <div className={modal === "hidden" ? "..." : "modal-bg-blur"}>
                <Hero />
                <Type />
                <Blocks />
                <Contact />
            </div>
            <AppFooter />
        </div>
    );
}
