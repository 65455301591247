import React from "react";
import workbg from "./../assets/workbg.png";
import icon_select from "./../assets/icon-select.png";
import icon_choose from "./../assets/icon-choose.png";
import icon_buy from "./../assets/icon-buy.png";
import line2 from "./../assets/line2.png";
import line3 from "./../assets/line3.png";

const worksbg = {
  backgroundImage: `url(${workbg})`,
  backgroundSize: "cover",
  //   height: "218px",
  backgroundRepeat: "no-repeat",
};

export default function Works() {
  return (
    <div className="grid my-60 container mx-auto text-center" style={worksbg}>
      <h1 className="font-bold text-3xl">How It Works</h1>

      <div className="rounded-lg drop-shadow-2xl bg-white p-24 mt-10">
        <div className="flex">
          <div className="w-1/5 text-center justify-center grid">
            <div className="border-2 border-[#2A4DD0] rounded-full p-0 w-7 mx-auto">
              <span className="text-[#2A4DD0]">1</span>
            </div>
            <img
              src={icon_select}
              alt=""
              className="text-center mt-20 mx-auto"
            />
            <h4 className="font-bold text-2xl mt-5">Select</h4>
            <p>VIP First Access Rights</p>
          </div>

          <div className="w-1/5">
            <img src={line2} alt="" className="text-center mt-36 mx-auto" />
          </div>

          <div className="w-1/5 text-center justify-center grid">
            <div className="border-2 border-[#2A4DD0] rounded-full p-0 w-7 mx-auto">
              <span className="text-[#2A4DD0]">2</span>
            </div>
            <img
              src={icon_choose}
              alt=""
              className="text-center mt-20 mx-auto"
            />
            <h4 className="font-bold text-2xl mt-5">Choose</h4>
            <p>Your digital content</p>
          </div>

          <div className="w-1/5">
            <img src={line2} alt="" className="text-center mt-36 mx-auto" />
          </div>

          <div className="w-1/5 text-center justify-center grid">
            <div className="border-2 border-[#2A4DD0] rounded-full p-0 w-7 mx-auto">
              <span className="text-[#2A4DD0]">3</span>
            </div>
            <img src={icon_buy} alt="" className="text-center mt-20 mx-auto" />
            <h4 className="font-bold text-2xl mt-5">Buy</h4>
            <p>Credit Card or web3</p>
          </div>
        </div>
      </div>
    </div>
  );
}
