import React, { useState } from "react";

export default function Request() {
  const [feedback, setFeedback] = useState(true);
  const [userfeedback, setUserfeedback] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    var data = {
      data: {
        feedback: userfeedback,
      },
    };

    var headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    fetch("https://api1.vipnft.fan/api/resources-feedbacks", {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data),
    })
      .then(function (response) {
        alert(
          "Thank you for reaching out and providing us with valuable feedback."
        );
        return response.json();
      })
      .then(function (data) {
        console.log(data);
      });
  };

  return (
    <div className="text-center mt-48 mb-8">
      {feedback ? (
        <div>
          <p className="py-2 dark:text-gray-200 text-gray-600">
            Was this article helpful?
          </p>
          <button className="bg-transparent border border-blue-700 rounded cursoir-pointer text-blue-700 px-5 py-1 m-3">
            Yes
          </button>
          <button
            className="bg-transparent border border-blue-700 rounded cursoir-pointer text-blue-700 px-5 py-1 m-3"
            onClick={() => setFeedback(false)}
          >
            No
          </button>
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <p className="py-2 dark:text-gray-200 text-gray-600">
            How can we make this page better for you?
          </p>
          <textarea
            className="form-textarea p-5 m-1 text-sm w-full text-gray-600 appearance-none focus:outline-none focus:placeholder-transparent rounded-lg"
            rows="3"
            placeholder="Your suggestions, ideads, complaints will greatly help us"
            value={userfeedback}
            onChange={(e) => setUserfeedback(e.target.value)}
          ></textarea>
          <input
            type="submit"
            value="Submit"
            className="w-full px-3 py-2 m-1 text-sm font-medium tracking-wider text-white transition-colors duration-200 transform bg-blue-700 rounded-full lg:w-auto hover:bg-gray-700"
          />
        </form>
      )}

      <p className="py-2 text-xs dark:text-gray-200 text-gray-600">
        76 out of 97 found this helpful
      </p>

      <p className="py-2 dark:text-gray-200 text-gray-600">
        Have more questions?{" "}
        <a
          href="/"
          className="font-bold text-md text-indigo-400 hover:text-blue-600"
        >
          Submit a request
        </a>
      </p>
    </div>
  );
}
