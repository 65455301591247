import React, { useContext } from "react";
import logo_palladium from "./assets/Logo-Diamond.png";
import logo_diamond from "./assets/logo/logo_blue_cap.png";
import { FaTwitter, FaGithub, FaFacebook, FaLinkedin } from "react-icons/fa";
import { ThemeContext } from "../App";
import img_background_dark_old from "./assets/backgrounds/LandingPage5Ver4.png";
import { BsFillTelephoneFill, BsFillEnvelopeFill } from "react-icons/bs";

export default function AppFooter() {
  const { theme } = useContext(ThemeContext);
  return (
    <footer className="text-gray-600 dark:text-gray-200 dark:bg-transparent/90 bg-gray-100/90 backdrop-blur-md shadow-lg text-md">
      <section
        id="header"
        className="grid grid-cols-1 lg:grid-cols-2 gap-4 items-center container px-6 py-10 mx-auto"
      >
        {/* LEFT */}
        <div className="... space-y-7">
          <div>
            <img
              src={logo_palladium}
              className="h-16 w-auto"
              alt="logo"
            />
          </div>
          <p className="mt-2 text-gray-600 dark:text-gray-200 w-3/4">
            The largest NFT marketplace for a VIP experience. NFTs verified by
            the creator, secured using Ethereum blockchain technology. Unique
            digital creation providing access and benefits to "Enhance the Fan
            Experience".
          </p>
          <div>
            <span className="gap-2 mt-2 hover:underline flex">
              <BsFillTelephoneFill /> +61 1300 889 739
            </span>
            <span className="gap-2 mt-2 hover:underline flex">
              <BsFillEnvelopeFill /> hello@vipnft.fan
            </span>
          </div>

        </div>

        {/* RIGHT */}
        <div className="... ">
          <div className="grid grid-cols-2 gap-6 sm:grid-cols-3 md:grid-cols-4 ">
            <div>
              <h3 className="uppercase font-bold">About</h3>
              <a href="/" className="block mt-2 hover:underline">
                Company
              </a>
              <a href="/" className="block mt-2 hover:underline">
                Community
              </a>
              <a href="/" className="block mt-2 hover:underline">
                Careers
              </a>
            </div>
            <div>
              <h3 className="uppercase font-bold">Blog</h3>
              <a href="/" className="block mt-2 hover:underline">
                Tech
              </a>
              <a href="/" className="block mt-2 hover:underline">
                Music
              </a>
              <a href="/" className="block mt-2 hover:underline">
                Videos
              </a>
            </div>

            <div>
              <h3 className="uppercase font-bold">Our Social Media</h3>
              <div className="flex mt-0 pt-0 -mx-2 space-x-5">
                <button className="p-2 m-1 tracking-wider text-white uppercase transition-colors duration-200 transform bg-[#2B4ED1] rounded-lg lg:w-auto hover:bg-blue-700 opacity-50">
                  <FaLinkedin />
                </button>
                <button className="p-2 m-1 tracking-wider text-white uppercase transition-colors duration-200 transform bg-[#2B4ED1] rounded-lg lg:w-auto hover:bg-blue-700 opacity-50">
                  <FaFacebook />
                </button>
                <button className="p-2 m-1 tracking-wider text-white uppercase transition-colors duration-200 transform bg-[#2B4ED1] rounded-lg lg:w-auto hover:bg-blue-700 opacity-50">
                  <FaTwitter />
                </button>
                <button className="p-2 m-1 tracking-wider text-white uppercase transition-colors duration-200 transform bg-[#2B4ED1] rounded-lg lg:w-auto hover:bg-blue-700 opacity-50">
                  <FaGithub />
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="pb-5">

        <div>
          <p className="text-right text-gray-600 dark:text-gray-200">
            © VIPNFT 2023 - All rights reserved
          </p>
        </div>
      </div>
    </footer>
  );
}
