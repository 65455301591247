import React, { useContext, useState } from "react";
import AppNavbar from "../../components/app-navbar";
import img_background_light from "../../components/assets/backgrounds/Bg-light2.png";
import img_background_dark_old from "../../components/assets/backgrounds/Dark-desktop-1.png";
import { ThemeContext } from "../../App";
import AppFooter from "../../components/footer";
import Hero from "./components/hero";
import SubscriberForm from "./../../components/subscriber-form";
import Helmet from "./../../components/helmet";
import img_PA from "./assets/PA_Home_About_Logo_image2.png";
import img_PA_2 from "./assets/e0c818cb13676261a98275e33d60d66d-2.jpeg";
import img_PA_3 from "./assets/Pacific_Airshow_Thunderbirds.jpg";
import img_T_CR from "./assets/cr.png";
import img_esl from "./assets/ESLProLeagueS11LAN.jpg";
import img_gilmour_space from "./assets/Gilmour-Space-Technologies-new-funding-for-domestic-launches.jpg";
import img_T_sd from "./assets/sd.png";
import Card from "./../../components/card";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import AccountModal from "./../../components/account-modal";
import Collections from "./components/collections";
import Access from "./components/access";
import Hotcollections from "./components/hotcollections";
import Howitworks from "./components/howitworks";

const responsive = {
  0: { items: 1 },
  568: { items: 2 },
  1024: { items: 2 },
  1280: { items: 3 },
  1536: { items: 4 },
};

export default function ExplorePage() {
  const { theme, setTheme } = useContext(ThemeContext);
  setTheme("light");
  const [modal, setModal] = useState("hidden");
  const [page, setPage] = useState("login");

  function changePage(page) {
    setPage(page);
  }

  return (
    <div
      className={`pt-32 overflow-hidden ${theme === "dark" ? "dark" : "light"
        } transition-all duration-300`}
      style={{
        backgroundImage:
          theme === "dark"
            ? `url(${img_background_dark_old})`
            : `url(${img_background_light})`,
        backgroundSize: "cover",
        width: "100vw",
        minWidth: "100vw",
      }}
    >
      <Helmet title={"VIP NFT | Explore"} />
      <AppNavbar openModal={setModal} modal={modal} />
      <AccountModal
        modal={modal}
        setModal={setModal}
        theme={theme}
        page={page}
        changePage={changePage}
      />

      <div className={modal === "hidden" ? "..." : "modal-bg-blur"}>
        <Hero />
        <Collections />
        <Access />
        <Hotcollections />
      </div>
      <AppFooter />
    </div>
  );
}

const CollectionCard = ({ children, provider, img, url }) => {
  return (
    <div
      className="flex md:h-96 h-32 md:w-full m-10 rounded-2xl hover:opacity-30 transition-all duration-500 shadow-lg shadow-gray-400 p-0 hover:cursor-pointer"
      style={{
        backgroundImage: `url(${img})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
      onClick={() => {
        window.location.href = `/creator/${url}`;
      }}
    >
      <div className="text-center relative z-10 w-full flex bg-black bg-opacity-20 rounded-2xl backdrop-blur-sm transition-all duration-500">
        <p className=" text-white items-center my-auto font-bold md:text-4xl mx-auto text-shadow">
          {children}
        </p>
        <p className="absolute md:bottom-5 bottom-0 right-5 text-white items-center my-auto font-normal md:text-xl mx-auto text-shadow">
          By {provider}
        </p>
      </div>
    </div>
  );
};
