import React from "react";
import rectangle39 from "./../assets/rectangle39.png";
import palogo from "./../assets/palogo.png";
import goldlogo from "./../assets/goldlogo.png";

export default function Header({ marketplace, loadingListings, listings }) {

  // Purchase NFT
  async function buyNft() {
    try {
      // Simple one-liner for buying the NFT
      if(!loadingListings) {
        await marketplace?.direct.buyoutListing(listings[0]?.id, 1);
        alert("NFT bought successfully!");
      }
    } catch (error) {
      console.error(error);
      alert(error);
    }
  }

  return (
    <>
      {!loadingListings && (
        <section className="container px-6 py-4 mt-4 mb-4 mx-auto">
          <div className="grid grid-cols-1 md:gap-8 mt-8 xl:mt-4 xl:gap-16 lg:grid-cols-4 xl:grid-cols-3">
            <div className="col-span-2">
              <div className="flex justify-center rounded-lg drop-shadow-2xl">
                {/* <img src={rectangle39} alt="rectangle39" className="rounded-lg drop-shadow-2xl" /> */}
                <img src={listings[0].asset?.image} alt="rectangle39" className="rounded-lg drop-shadow-2xl" />
              </div>
            </div>
            <div>
              <div className="rounded-lg drop-shadow-2xl bg-white p-8 mt-8 xl:mt-4">
                <div className="flex justify-between mb-4">
                  <img src={palogo} alt="" />
                  <img src={goldlogo} alt="" />
                </div>
                <h1 className="font-bold text-2xl text-[#212429]">
                  {/* Pacific Airshow GC 2022 Collection */}
                  {listings[0].asset?.name}
                </h1>
                <p className="text-base text-[#212429]">
                  {/* The backs of five beautiful angels on the sky */}
                  {listings[0].asset?.description}
                </p>
                <div class="hidden sm:block" aria-hidden="true">
                  <div class="py-5">
                    <div class="border-t border-gray-200"></div>
                  </div>
                </div>

                <div class="grid grid-cols-1 md:grid-cols-3">
                  <div class="col-span-2">
                    <h1 className="font-bold text-3xl text-[#2A4DD0]">
                      {/* Pacific Airshow GC Sky Angels #50 */}
                      {listings[0].asset?.attributes[0]?.value}
                    </h1>
                  </div>
                  <div class="">
                    <div>
                      <p className="text-base">Current Price</p>
                      <h4 className="font-bold text-3xl text-[#2A4DD0]">
                        {/* 3.16 ETH */}
                        {listings[0].buyoutCurrencyValuePerToken.displayValue} {listings[0].buyoutCurrencyValuePerToken.symbol}
                      </h4>
                      <p className="text-base">$4,990</p>
                    </div>
                  </div>
                </div>
                <div className="">
                  <button onClick={buyNft} className="p-4 rounded-lg text-white bg-[#2A4DD0] w-full mt-4 mb-2">
                    Buy Access
                  </button>
                  <button className="p-4 rounded-lg text-[#2A4DD0] bg-white border-2 border-[#2A4DD0] w-full mb-4 mt-2">
                    Offer
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}
