import React, { useState, useEffect } from "react";
import { IoPersonCircleOutline } from "react-icons/io5";
import { FiSliders } from "react-icons/fi";
import { FaInbox } from "react-icons/fa";
import { IoIosSettings } from "react-icons/io";
import axios from "axios";

const divStyles = {
  boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.25)",
  borderRadius: "25px",
  backdropFilter: "blur(2px)",
  background: "rgba(255, 255, 255, 0.5)",
};

export default function Profile({ publicKey }) {
  const [data, setData] = useState(null);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://api2.ezy3.io/api/users?filters[wallet][publicKey]=${publicKey}`
      );
      console.log("response 1", response.data[0].phoneNumber);
      setData({
        phoneNumber: response.data[0]?.phoneNumber,
      });
      return response.data;
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    publicKey && fetchData();
  }, []);
  return (
    <React.Fragment>
      <div
        className="flex-col space-y-3 h-fit hidden md:flex"
        style={divStyles}
      >
        <div className="items-center text-center">
          <h1 className="text-2xl font-bold text-gray-600 dark:text-gray-200 capitalize mb-6 mt-6">
            {data ? "+" + data.phoneNumber : "No phone number"}
          </h1>
          <div className="flex text-center justify-center my-8">
            <IoPersonCircleOutline className="inline-block h-32 w-32 rounded-full ring-8 ring-gray-500 text-gray-500" />
            {/* <img
              className="inline-block h-32 w-32 rounded-full ring-8 ring-white"
              src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
              alt=""
            /> */}
          </div>
          <div className="text-[#4C6474] dark:text-gray-200 rounded-lg">
            <a
              href={`/dashboard/overview/${publicKey ? publicKey : ""}`}
              className="inline-flex relative gap-4 items-center py-6 px-6 w-full text-xl font-medium hover:bg-[#D9D9D9] hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:border-gray-600 dark:hover:bg-white/30 dark:hover:text-white dark:focus:text-white duration-500 transition-all"
            >
              <IoPersonCircleOutline className="w-8 h-8" />
              Overview
            </a>
            <a
              href={`/dashboard/collections/${publicKey ? publicKey : ""}`}
              className="inline-flex relative gap-4 items-center py-6 px-6 w-full text-xl font-medium hover:bg-[#D9D9D9] hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:border-gray-600 dark:hover:bg-white/30 dark:hover:text-white dark:focus:text-white duration-500 transition-all"
            >
              <FiSliders className="w-8 h-8" />
              NFT Collection
            </a>
            <a
              href={`/dashboard/events/${publicKey ? publicKey : ""}`}
              className="inline-flex relative gap-4 items-center py-6 px-6 w-full text-xl font-medium hover:bg-[#D9D9D9] hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:border-gray-600 dark:hover:bg-white/30 dark:hover:text-white dark:focus:text-white duration-500 transition-all"
            >
              <FaInbox className="w-8 h-8" />
              Events
            </a>
            <a
              href={`/dashboard/settings/${publicKey ? publicKey : ""}`}
              className="inline-flex relative gap-4 items-center py-6 px-6 w-full text-xl font-medium hover:bg-[#D9D9D9] hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:border-gray-600 dark:hover:bg-white/30 dark:hover:text-white dark:focus:text-white duration-500 transition-all"
            >
              <IoIosSettings className="w-8 h-8" />
              Settings
            </a>
          </div>
          <div
            className="inline-flex rounded-full shadow-sm mb-6 mt-24 p-1 bg-gray-300 dark:bg-gray-600/30"
            role="group"
          >
            <button
              type="button"
              className="rounded-full inline-flex items-center py-3 px-6 text-sm font-medium text-gray-900 bg-white dark:bg-white/70 border-0 hover:bg-gray-900 hover:opacity-50 focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white duration-300 transition-all"
            >
              <svg
                aria-hidden="true"
                className="mr-2 w-4 h-4 fill-current text-[#7D96B3]"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="font-bold text-[#7D96B3]">Log out</span>
            </button>
            <button
              type="button"
              className="inline-flex items-center py-3 px-6 text-sm font-medium text-gray-900 bg-transparent rounded-r-md dark:text-white hover:opacity-50 transition-all duration-300"
            >
              <svg
                aria-hidden="true"
                className="mr-2 w-4 h-4 fill-current text-[#7D96B3]"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M2 9.5A3.5 3.5 0 005.5 13H9v2.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 15.586V13h2.5a4.5 4.5 0 10-.616-8.958 4.002 4.002 0 10-7.753 1.977A3.5 3.5 0 002 9.5zm9 3.5H9V8a1 1 0 012 0v5z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="font-bold text-[#7D96B3]">Support</span>
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
