import React from "react";
import collection1 from "./../assets/collection1.png";
import collection2 from "./../assets/collection2.png";
import collection3 from "./../assets/collection3.png";
import collection4 from "./../assets/collection4.png";
import CollectionCard from "./../../../components/collection-card";

export default function collections() {
  return (
    <React.Fragment>
      <section className="container-fluid md:container md:mx-auto xl:mt-60 mx-8">
        <h1 className="text-[#212429] text-4xl xl:text-6xl font-bold my-6">
          Collections
        </h1>
        <div className="grid xl:flex text-base text-black xl:justify-between xl:items-end">
          <p className="w-full xl:w-1/3">
            Unlock early ticket access and VIP experiences money can't buy.
            Connecting fans with artists, event organisers and content creators
            like never before.
          </p>
          <p className="align-bottom">Over 100+ Collections</p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 my-12 gap-8">
          <CollectionCard
            img={collection1}
            title={"Pacific Airshow GC 2023"}
            desc={
              "With stunning visuals, immersive content and exclusive benefits, the Pacific Airshow VIP..."
            }
            total={"22"}
          />
          <CollectionCard
            img={collection2}
            title={"Ticketsocket"}
            desc={
              "Exclusive benefits, from early access to tickets and Invitations to VIP experiences."
            }
            total={"8"}
          />

          <CollectionCard
            img={collection3}
            title={"Rewards or Pearls"}
            desc={
              "Perks redefined. Unlock access to earn rewards and receive unique digital content."
            }
            total={"32"}
          />

          <CollectionCard
            img={collection4}
            title={"Pacific Airshow HB 2023"}
            desc={
              "With stunning visuals, immersive content and exclusive benefits, the Pacific Airshow VIP..."
            }
            total={"28"}
          />
        </div>
        <div className="flex justify-center">
          <button
            className="bg-gradient-to-r from-[#2a4dd0] to-[#6e8cff] text-white font-semibold rounded-lg transition-all duration-300 py-4 px-24 shadow-md hover:opacity-50"
            onClick={() => {
              window.location.href = "/explore";
            }}
          >
            <p className="text-lg md:text-lg ">View More</p>
          </button>
        </div>
      </section>
    </React.Fragment>
  );
}
