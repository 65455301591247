import React, { useContext, useState } from "react";
import AppNavbar from "./../../components/app-navbar";
import AppFooter from "./../../components/footer";
import img_background_light from "./../../components/assets/backgrounds/Bg-light.png";
import img_background_dark from "./../../components/assets/backgrounds/Dark-desktop-1.png";
import { ThemeContext } from "./../../App";
import Helmet from "../../components/helmet";
import Header from "./components/header";
import Benefits from "./components/benefits";
import Schedule from "./components/schedule";
import Activity from "./components/activity";
import iconown from "./../../components/assets/iconown.png";

import {
  MediaRenderer,
  useActiveListings,
  useContract,
} from "@thirdweb-dev/react";

const layer = {
  background:
    "linear-gradient(199.38deg, rgba(255, 255, 255, 0) -2.34%, rgba(42, 77, 208, 0.74) 91.22%)",
  backgroundSize: "cover",
  height: "372px",
  backgroundRepeat: "no-repeat",
};

export default function NftoverviewPages() {
  const { theme, setTheme } = useContext(ThemeContext);
  setTheme("light");
  const [submenu, setSubmenu] = useState(false);

  // Initialize the marketplace contract
  const address = process.env.REACT_APP_MARKETPLACE_ADDR;

  const { contract: marketplace } = useContract(
    address, // Your marketplace contract address here
    "marketplace"
  );

  const { data: listings, isLoading: loadingListings } = useActiveListings(marketplace);

  // console.log("marketplace: ", marketplace);
  // console.log("listings: ", listings);

  return (
    <div
      className={`pt-32 overflow-hidden ${
        theme === "dark" ? "dark" : "light"
      } transition-all duration-300`}
      style={{
        backgroundColor: "#DEDEDE",
        backgroundSize: "cover",
        width: "100vw",
        minWidth: "100vw",
      }}>
      <Helmet title={"VIP NFT | NFT Overview"} />
      <AppNavbar />
      <Header marketplace={marketplace} loadingListings={loadingListings} listings={listings}/>
      <Benefits loadingListings={loadingListings} listings={listings}/>
      <Schedule loadingListings={loadingListings} listings={listings}/>
      <Activity marketplace={marketplace}/>
      <section className="container px-6 py-4 mt-4 mb-4 mx-auto">
        <h1 className="font-bold text-xl text-[#2A4DD0] mb-4">
          More from this collections
        </h1>

        {/* {
          // If the listings are loading, show a loading message
          loadingListings ? (
            <div>Loading listings...</div>
          ) : (
            // Otherwise, show the listings
            <div className={"grid grid-cols-3 gap-32"}>
              {listings?.map((listing) => (
                <div
                  key={listing.id}
                  className="sm:mb-0 mb-6 hover:cursor-pointer mr-6 md:mr-0"
                  // onClick={() => window.open(`marketplace/listing/${listing.id}`)}
                >
                  <div className="bg-white shadow-2xl dark:shadow-2xl md:h-full rounded-3xl dark:hover:shadow-white transition-all duration-300">
                    <div
                      className="rounded-2xl body-font"
                      style={{
                        //  backgroundImage: `url(${img})`,
                        backgroundSize: "cover",
                        height: "372px",
                        backgroundRepeat: "no-repeat",
                      }}>
                      <div
                        className="p-3 rounded-2xl body-font relative"
                        style={layer}>
                        <div className="grid flex-auto absolute right-5 pl-4">
                          <div className="flex justify-end">
                            <button
                              className="bg-gradient-to-r from-[#2A4DD0] to-[#6E8CFF] mt-2 h-10 text-white w-28 hover:border-2 font-semibold rounded-full transition-all duration-300 py-1 shadow-md hover:opacity-60"
                              onClick={() => {
                                window.open(
                                  `marketplace/listing/${listing.id}`
                                );
                              }}>
                              <p className="text-sm md:text-sm ">3 for sale</p>
                            </button>
                          </div>
                        </div>
                        <MediaRenderer
                          src={listing.asset.image}
                          style={{
                            borderRadius: 16,
                            // Fit the image to the container
                            width: "100%",
                            height: "100%",
                          }}
                          // className="absolute inset-0 w-full h-full object-cover object-center z-30"
                        />
                        <div className="flex justify-start absolute gap-4 bottom-0">
                          <img src={iconown} alt="" />
                          <h2 className="text-xs font-medium text-left title-font dark:text-white text-gray-600 mt-2">
                            Owned by Lorem ipsum
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="px-2 m-8">
                      <h2 className="text-xl font-medium text-left title-font dark:text-black text-gray-600 mt-10">
                        <a href={`/listing/${listing.id}`}>
                          {listing.asset.name}
                        </a>
                      </h2>
                      <div className="flex mt-5 justify-between">
                        <h2 className="text-xl font-medium text-left title-font dark:text-black text-gray-600">
                          {listing.buyoutCurrencyValuePerToken.displayValue}{" "}
                          {listing.buyoutCurrencyValuePerToken.symbol}
                        </h2>
                        <p className="text-[#ACB5BD]">Starting bid</p>
                      </div>

                      <button
                        className="bg-gradient-to-r from-[#2A4DD0] to-[#6E8CFF] mt-10 mb-10 md:mb-0 p-4 text-white hover:border-2 border-white/70 w-full border-2 font-semibold rounded-lg transition-all duration-300 py-3 shadow-md hover:opacity-60"
                        onClick={buyNft}>
                        <p className="text-md md:text-md ">Purchase Now</p>
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )
        } */}
      </section>
      <AppFooter />
    </div>
  );
}
