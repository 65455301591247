import React from 'react'

export default function relatedarticle() {
  return (
    <div>
      <h1 className="text-md font-bold dark:text-gray-200 text-gray-600">
        Related articles
      </h1>
      <p className="mt-2 mb-2 text-md text-indigo-400 hover:text-blue-600"><a href="">How do you “Enhance the Fan Experience”?</a></p>
      <p className="mt-2 mb-2 text-md text-indigo-400 hover:text-blue-600"><a href="">What is the legality behind VIPNFT?</a></p>
      <p className="mt-2 mb-2 text-md text-indigo-400 hover:text-blue-600"><a href="">How can I buy a VIPNFT?</a></p>
      <p className="mt-2 mb-2 text-md text-indigo-400 hover:text-blue-600"><a href="">How does VIPNFT verify I can have “Early Access”?</a></p>
      <p className="mt-2 mb-2 text-md text-indigo-400 hover:text-blue-600"><a href="">How do I access the tickets with the ticket provider?</a></p>
    </div>
  )
}
