import React from "react";
import checkmark from "./../assets/checkmark.png";
import spotlight1 from "./../assets/spotlight1.png";
import spotlight2 from "./../assets/spotlight2.png";
import spotlight3 from "./../assets/spotlight3.png";
import spotlight4 from "./../assets/spotlight4.png";

import { BsPersonCircle } from "react-icons/bs";

const ListDesc = (props) => {
  return (
    <li className="flex gap-1">
      <img src={checkmark} alt="checkmark" className="h-full mt-1" />{" "}
      {props.desc}
    </li>
  );
};

const SpotlightCard = (props) => {
  return (
    <div
      className={`border-8 rounded-lg border-[#E5E9F6] ${
        !props.sold && "hover:scale-105"
      } duration-300 relative h-full`}
    >
      <div
        className="h-[278px] rounded-tl-lg rounded-tr-lg p-4 relative"
        style={{
          backgroundImage: `url(${props.img})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "100%",
          height: "278px",
        }}
      >
        <div className="flex justify-end">
          <div className="text-white text-xs rounded-full uppercase bg-gradient-to-r from-[#2A4DD0] to-[#6E8CFF] py-3 px-8 font-semibold">
            3 for sale
          </div>
        </div>
        <div className="flex gap-1 absolute bottom-4">
          <BsPersonCircle className="text-white text-2xl" />
          <h1 className="text-white text-xs font-normal mt-1">
            Owned by {props.owner}
          </h1>
        </div>
      </div>
      <div className="p-6 bg-white">
        <div className="flex justify-between my-2">
          <h4 className="text-[#212429] font-semibold text-base">
            {props.title}
          </h4>
          <SpotlightTag tag={props.tag} />
        </div>
        {/* <p className="text-xs text-black mb-4">{props.desc}</p> */}
        {props.extra && <p className="text-xs text-black">{props.extra}</p>}
        <ul className="list-none text-xs text-black leading-loose">
          {props.list.map((item) => (
            <ListDesc desc={item} />
          ))}
        </ul>
      </div>
      {/* <div className="px-4 absolute bottom-4 w-full">
        <button className="text-xs text-white rounded-lg bg-gradient-to-r from-[#2A4DD0] to-[#6E8CFF] py-3 font-semibold w-full">
          Purchase Now
        </button>
      </div> */}
    </div>
  );
};

const SpotlightTag = (props) => {
  if (props.tag == "gold") {
    return (
      <div
        className="cursor-pointer rounded-full text-white uppercase font-semibold h-8 py-2 w-[100px] text-center text-xs"
        style={{
          background:
            "linear-gradient(90deg, rgba(183,148,53,1) 29%, rgba(183,148,53,0.2945553221288515) 100%)",
        }}
      >
        Gold
      </div>
    );
  }

  if (props.tag == "diamond") {
    return (
      <div
        className="cursor-pointer rounded-full text-white uppercase font-semibold h-8 py-2 w-[100px] text-xs text-center"
        style={{
          background:
            "linear-gradient(90deg, rgba(143,172,179,1) 29%, rgba(144,173,182,0.2861519607843137) 100%)",
        }}
      >
        Diamond
      </div>
    );
  }

  if (props.tag == "palladium") {
    return (
      <div
        className="cursor-pointer rounded-full text-white uppercase font-semibold h-8 py-2 w-[100px] text-xs text-center"
        style={{
          background:
            "linear-gradient(90deg, rgba(180,111,97,1) 6%, rgba(185,115,98,0.2777485994397759) 100%)",
        }}
      >
        Palladium
      </div>
    );
  }
};

export default function spotlight() {
  return (
    <React.Fragment>
      <section className="container-fluid md:container md:mx-auto mt-24 xl:mt-60 mx-8">
        <h1 className="text-[#212429] text-6xl font-bold my-6">
          Spotlight Series
        </h1>
        <div className="flex text-base text-black">
          <p className="w-full xl:w-1/3">
            Whilst every single NFT is a one-of-a-kind Digital Collectible, the
            following are in high demand, so get in quick and secure first
            access to tickets.
          </p>
        </div>
        <p className="w-full xl:w-1/3 mt-12 mb-4 font-bold text-xl text-[#EF0255]">
          Pacific Airshow
        </p>
        <div className="grid grid-cols-1 xl:grid-cols-4 gap-8 mb-12">
          <SpotlightCard
            img={spotlight2}
            tag={"gold"}
            title={
              <p>
                Pacific Airshow GC 23 <br /> Sky Angels #3
              </p>
            }
            owner={"johnbrady.eth"}
            desc={
              "Beat the Queue with Early Ticket Access as a Gold Vip NFT holder. Your NFT gives you the First Right of Refusal to purchase General Entry Tickets. Receive a 10% discount on ticket purchase. Access private community chat for NFT holders only. Receive invitations to exclusive events."
            }
            list={[
              "Never miss your favourite event",
              "First right to purchase general entry tickets",
              "10% discount on tickets (limits apply)",
              "Access private community chat",
              "Invitations to exclusive VIP events",
              "Limited series of unique digital collectibles",
            ]}
          />
          <SpotlightCard
            img={spotlight3}
            tag={"palladium"}
            title={
              <p>
                Pacific Airshow GC 23 <br /> Sky Angels #16
              </p>
            }
            owner={"taylorbeer.eth"}
            desc={
              "This Premium NFT unlocks First Access to premium tickets plus all the Gold NFT benefits. Be the first to receive NFT content offered by performers and invitations to special events. 10% off premium ticket prices (limits apply)."
            }
            extra={"Gold VIP benefits plus:"}
            list={[
              <p>
                First right to purchase{" "}
                <span className="font-bold">premium tickets</span>
              </p>,
              "First access to additional content by performers",
              <p>
                10% discount on{" "}
                <span className="font-bold">premium tickets</span> (limits
                apply)
              </p>,
              'More exciting features coming for these "Dynamic NFTs"',
            ]}
          />
          <SpotlightCard
            img={spotlight1}
            tag={"diamond"}
            title={
              <p>
                Pacific Airshow GC 23 <br /> Sky Angels #25
              </p>
            }
            owner={"jamesaviationbuff.eth"}
            extra={"Palladium VIP benefits plus:"}
            desc={
              "The ultimate VIP NFT from Pacific Airshow. Besides first access to Cabanas, you receive invitations to private VIP-only events and a locked community chat forum. All the features of Palladium and 10% off all tickets (limits apply)."
            }
            list={[
              "The ultimate VIP NFT from Pacific Airshow",
              "Unlock first access to Cabanas",
              "Exclusive invitations to private VIP-only events",
              "Behind the scenes VIP Access",
              "1 of only 15 Diamond NFTs!",
            ]}
          />
          {/* <a
            className="text-indigo-400 dark:text-gray-200 inline-flex items-center text-center hover:text-blue-600 duration-200 text-base mb-5"
            href="/explore"
          >
            See more collection of Pacific Airshow
            <svg
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              className="w-4 h-4 ml-2"
              viewBox="0 0 24 24"
            >
              <path d="M5 12h14M12 5l7 7-7 7" />
            </svg>
          </a> */}
          <div className="opacity-50 grayscale relative">
            <p className="absolute top-0 right-0 bottom-0 left-0 m-auto w-fit h-fit bg-gray-800 border-gray-400 z-20 text-3xl grayscale-0 text-white">
              SOLD OUT
            </p>
            <SpotlightCard
              img={spotlight1}
              tag={"diamond"}
              title={
                <p>
                  Pacific Airshow GC 23 <br /> Sky Angels #99
                </p>
              }
              owner={"jamesaviationbuff.eth"}
              desc={
                "The ultimate VIP NFT from Pacific Airshow. Besides first access to Cabanas, you receive invitations to private VIP-only events and a locked community chat forum. All the features of Palladium and 10% off all tickets (limits apply)."
              }
              sold
              list={[
                "The ultimate VIP NFT from Pacific Airshow",
                "Unlock first access to Cabanas",
                "Exclusive invitations to private VIP-only events",
                "All the features of Palladium and 10% off all tickets (limits apply)",
                "Behind the scenes VIP Access",
                "1 of only 15 Diamond NFTs ever",
              ]}
            />
          </div>
          {/* <button
            className="text-xs text-white rounded-lg bg-gradient-to-r from-[#2A4DD0] to-[#6E8CFF] py-3 font-semibold w-full hover:scale-105 duration-300"
            onClick={() => window.open("/explore", "_blank")}
          >
            <div className="inline-flex items-center text-center duration-200 text-base mb-5">
              See more collection of Pacific Airshow
              <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                className="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
              >
                <path d="M5 12h14M12 5l7 7-7 7" />
              </svg>
            </div>
          </button> */}
          {/* <div className="px-4 absolute bottom-4 w-full"></div> */}
          {/* <SpotlightCard
            img={spotlight4}
            tag={"diamond"}
            title={"Pacific Airshow GC Sky Angels #28"}
            owner={"harryjoe.eth"}
            desc={
              "The ultimate VIP NFT from Pacific Airshow. Besides first access to Cabanas, you receive invitations to private VIP-only events and a locked community chat forum. All the features of Palladium and 10% off all tickets (limits apply)."
            }
            list={[
              "The ultimate VIP NFT from Pacific Airshow",
              "Unlock first access to Cabanas",
              "Exclusive invitations to private VIP-only events",
              'All the features of Palladium and 10% off all tickets (limits apply)',
              "Behind the scenes VIP Access",
              "1 of only 15 Diamond NFTs ever",
            ]}
          /> */}
        </div>

        <div className="flex justify-center">
          <button
            className="bg-gradient-to-r from-[#2a4dd0] to-[#6e8cff] text-white font-semibold rounded-lg transition-all duration-300 py-4 px-24 shadow-md hover:opacity-50"
            onClick={() => window.open("/explore", "_blank")}
          >
            <p className="text-lg md:text-lg ">See more</p>
          </button>
        </div>
      </section>
    </React.Fragment>
  );
}
