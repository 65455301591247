import React from "react";
import { useNavigate } from 'react-router-dom';

const CollectionCard = ({ img, title, desc, total }) => {
  const navigate = useNavigate();

  return (
    <>
      <div
        className="h-[579px] relative rounded-xl p-6 hover:cursor-pointer duration-300 hover:opacity-75"
        style={{
          backgroundImage: `url(${img})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          width: "100%",
        }}
        onClick={() => {
          // window.open("/collection/pacific-airshow", "_blank");
          navigate("/collection/pacific-airshow");
        }}
      >
        <div className=" bg-white absolute right-6 top-6 px-6 h-[37px] rounded-full">
          <p className="text-[#2645BB] mt-1 font-semibold">{total} Items</p>
        </div>
        <div className="grid grid-cols-1 content-end h-full gap-4">
          <p className="text-[22px] text-white font-semibold">{title}</p>
          <p className="text-white text-[18px] font-extralight">{desc}</p>
          <p className="text-white text-[14px] font-semibold">
            View Collection
          </p>
        </div>
      </div>
    </>
  );
};
export default CollectionCard;
