import React from "react";
import { Helmet } from "react-helmet";

const helmet = ({ title }) => {
  return (
    <div className="application">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <link rel="canonical" href="" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#0FE0D3" />
        <meta name="description" content="Largest Ticketing NFT" />
      </Helmet>
    </div>
  );
};

export default helmet;
