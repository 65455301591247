import React, { useContext } from "react";
import AppNavbar from "../../../components/app-navbar";
import AppFooter from "../../../components/footer";
import { ThemeContext } from "../../../App";
import Helmet from "../../../components/helmet";
import HeroPage from "./components/hero";
import HowPage from "./components/how";
import LevelsPage from "./components/levels";
import img_bg from "./assets/Purkle-Background.png";

export default function PurklePage() {
  const { theme } = useContext(ThemeContext);
  return (
    <div
      className={`pt-16 overflow-hidden bg-cover ${
        theme === "dark" ? "dark" : "light"
      } transition-all duration-300`}
      style={{
        // background: "radial-gradient(91.2% 81% at 50% 50%, #296381 0%, #14303E 100%)",
        backgroundImage: `url(${img_bg})`,
        width: "100vw",
        minWidth: "100vw",
      }}
    >
      <Helmet title={"VIP NFT | Ticketek"} />
      <AppNavbar />
      <HeroPage />
      <HowPage />
      <LevelsPage />

      <AppFooter />
    </div>
  );
}
