import React, { useState, useContext } from "react";
import { MdOutlineAccountCircle } from "react-icons/md";
import logo_dark from "./assets/logo/logo_diamond_cap.png";
import logo_light from "./assets/logo/logo_blue_cap.png";
import { TiThMenu } from "react-icons/ti";
import { ThemeContext } from "../App";
import { BsSunFill, BsMoonFill } from "react-icons/bs";

import { ConnectWallet } from "@thirdweb-dev/react";

const AppNavbar = ({ openModal, modal }) => {
  const [navbar, setNavbar] = useState(false);
  const { theme, setTheme } = useContext(ThemeContext);

  const toggleTheme = () => {
    setTheme((curr) => (curr === "dark" ? "light" : "dark"));
  };

  return (
    <nav
      className={`py-2 2xl:py-3 dark:bg-transparent bg-slate-50/70
      backdrop-blur-md shadow-md w-full md:px-10 px-5
      fixed top-0 left-0 right-0 z-10 duration-300 transition-all`}
    >
      <div className="justify-between px-4 mx-auto md:items-center md:flex md:px-8">
        <div>
          <div className="flex items-center justify-between md:block">
            <div className="flex items-center">
              <a
                className="text-2xl font-bold dark:text-gray-200 transition-colors duration-200 transform  lg:text-3xl hover:text-gray-700 "
                href="/"
              >
                <div className="flex items-center font-normal">
                  {/* <IoTicket /> */}
                  <img
                    src={theme === "dark" ? logo_dark : logo_light}
                    alt="VIPNFT"
                    className=" h-12"
                  />
                  {/* VIPNFT */}
                </div>
              </a>
              <div className="md:relative md:block hidden md:ml-7">
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <svg
                    className="w-5 h-5 dark:text-gray-400 text-gray-600"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
                <input
                  type="text"
                  className="py-2 pl-10 pr-4 dark:text-gray-700 border dark:border-0 dark:bg-white/5 bg-white/300 rounded-full border-gray-300  focus:border-blue-400  focus:outline-none focus:ring focus:ring-opacity-40 focus:ring-blue-300 w-96"
                  placeholder="Search"
                />
              </div>
            </div>

            <div className="md:hidden">
              <button
                className="p-2 dark:text-gray-200 text-gray-600 rounded-md outline-none focus:border-gray-400 "
                onClick={() => setNavbar(!navbar)}
              >
                <TiThMenu className="w-6 h-6" />
              </button>
            </div>
          </div>
        </div>

        <div>
          <div
            className={`flex-1 justify-self-center pb-3 mt-8 md:block md:pb-0 md:mt-0 ${
              navbar ? "block" : "hidden"
            }`}
          >
            <ul className="items-center justify-center space-y-8 md:flex md:space-x-6 md:space-y-0 dark:text-gray-200 text-gray-600">
              <li
                className="hover:text-blue-500
              cursor-pointer transition-colors duration-300 font-semibold"
              >
                <a href="/explore">Explore</a>
              </li>
              <li
                className="hover:text-blue-500
              cursor-pointer transition-colors duration-300 font-semibold"
              >
                <a href="/faqs">Faqs</a>
              </li>
              <li
                className="hover:text-blue-500
              cursor-pointer transition-colors duration-300 font-semibold"
              >
                <a href="/create">Create</a>
              </li>
              <li
                className="hover:text-blue-500
              cursor-pointer transition-colors duration-300 font-semibold"
              >
                <a href="/">Insights</a>
              </li>
              <li
                className="hover:text-blue-500
              cursor-pointer transition-colors duration-300 font-semibold"
                onClick={() => {
                  openModal(modal === "hidden" ? "block" : "hidden");
                }}
              >
                <MdOutlineAccountCircle size={30} className="hidden md:block" />
                <p className="md:hidden">Account</p>
              </li>
              <li
                className="hover:text-blue-500
              cursor-pointer duration-300 transition-all font-semibold"
                onClick={toggleTheme}
              >
                <BsSunFill
                  size={20}
                  className="duration-300 transition-all block dark:hidden"
                />
                <BsMoonFill
                  size={20}
                  className="duration-300 transition-all hidden dark:block"
                />
              </li>
              <li
                className="hover:text-blue-500
              cursor-pointer transition-colors duration-300 font-semibold"
              >
                <ConnectWallet />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default AppNavbar;
