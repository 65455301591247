import React from "react";

const divStyles = {
  boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.25)",
  borderRadius: "25px",
  backdropFilter: "blur(2px)",
  background: "rgba(255, 255, 255, 0.5)",
};

export default function Activity() {
  return (
    <div className="flex flex-col p-6" style={divStyles}>
      <h1 className="text-2xl font-bold text-black dark:text-gray-200 capitalize mb-4 mt-4">
        Hi!
      </h1>
      <p className="text-black text-base font-medium">
        You have 6 networking events to finish in this months. With 5 first
        access privilages are coming to you.
      </p>
      <div className="flex items-center space-x-4 my-2 p-4">
        <div className="flex-shrink-0">
          <div
            x-data="scrollProgress"
            className="inline-flex items-center justify-center rounded-full"
          >
            <svg className="w-20 h-20">
              <circle
                className="text-gray-300"
                stroke-width="10"
                stroke="currentColor"
                fill="transparent"
                r="30"
                cx="40"
                cy="40"
              />
              <circle
                className="text-[#6BC07E]"
                stroke-width="10"
                stroke-dasharray="circumference"
                stroke-dashoffset="circumference - percent / 100 * circumference"
                stroke-linecap="round"
                stroke="currentColor"
                fill="transparent"
                r="30"
                cx="40"
                cy="40"
              />
            </svg>
            <span
              className="absolute text-lg text-[#6BC07E] font-bold dark:text-white"
              x-text="`${percent}%`"
            >
              100
            </span>
          </div>
        </div>
        <div className="flex-1 min-w-0">
          <p className="text-black text-base mt-8">Your Progress</p>
          <h1 className="text-4xl font-bold text-black dark:text-gray-200 capitalize mb-4">
            Awesome
          </h1>
        </div>
      </div>
    </div>
  );
}
