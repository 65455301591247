import React from "react";
import type1 from "./../assets/type1.png";
import type2 from "./../assets/type2.png";
import type3 from "./../assets/type3.png";
import il_1 from "./../assets/are-you-ready1.png";
import il_2 from "./../assets/depositphotos_12945191-Concert-audience-blurred-background.jpg";

export default function type() {
  return (
    <React.Fragment>
      <div className="mt-24 text-gray-600 dark:text-gray-600 text-center px-10 md:px-20 lg:px-96">
        <h1 className="text-[#4C6474] font-bold text-4xl text-center mb-10">
          Create
        </h1>
        <p className="font-normal text-lg text-center text-gray-600 dark:text-gray-600">
          Build lasting connections with fans, provide early ticket access and
          distribute unique digital content with your own NFT series. By
          empowering fans with ownership of unique content and control over this
          asset, VIP NFTs offer a new way to connect with like-minded community
          members. Generate additional up-front revenue as well as ongoing
          royalties while providing an "enhanced fan experience".
        </p>

        <p className="font-normal text-lg mt-5 text-center text-gray-600 dark:text-gray-600">
          We've made the process simple, select your creator type and start
          building your NFT collection in just a few minutes.
        </p>
      </div>

      <div className="flex flex-wrap justify-center gap-20 -mx-2 my-20">
        <a
          className="flex flex-wrap h-72 w-3/12 relative rounded-2xl hover:opacity-30 transition-all duration-500 shadow-lg"
          style={{
            backgroundImage: `url(${il_1})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
          href="/create/build"
        >
          <div className="text-center relative z-10 w-full flex bg-black bg-opacity-20 rounded-2xl">
            <p className=" text-white items-center my-auto font-bold text-4xl mx-auto">
              Start building your NFT
            </p>
          </div>
        </a>
        <a
          className="flex flex-wrap h-72 w-3/12 relative rounded-2xl hover:opacity-30 transition-all duration-500 shadow-lg"
          style={{
            backgroundImage: `url(${il_2})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
          href="/create/apply"
          target={"_blank"}
        >
          <div className="text-center relative z-10 w-full flex bg-black bg-opacity-20 rounded-2xl">
            <p className=" text-white items-center my-auto font-bold text-4xl mx-auto">
              VIPNFT right for you?
            </p>
          </div>
        </a>
      </div>
    </React.Fragment>
  );
}
