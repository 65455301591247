import React, { useContext, useEffect } from "react";
import AppNavbar from "../../components/app-navbar";
import AppFooter from "../../components/footer";
import img_background_light from "../../components/assets/backgrounds/Bg-light2.png";
import img_background_dark from "../../components/assets/backgrounds/Dark-desktop-1.png";
import { ThemeContext } from "../../App";
import Helmet from "./../../components/helmet";
import Profile from "./../../components/profile";
import { HiSwitchHorizontal } from "react-icons/hi";
import ProfileResponsive from "../../components/profile-responsive";
import Hero from "./components/hero";
import Benefits from "./components/benefits";
import Solution from "./components/solution";
import Technology from "./components/technology";
import Future from "./components/future";
import Community from "./components/community";

const bg = {
  background:
    "linear-gradient(90deg, rgba(78,173,253,1) 0%, rgba(100,150,246,1) 100%)",
};

export default function TicketingPage() {
  // const [theme, setTheme] = React.useState("light");
  const { theme, setTheme } = useContext(ThemeContext);

  useEffect(() => {
    setTheme("light");
  }, []);

  return (
    <div
      className={`pt-20 overflow-hidden ${
        theme === "dark" ? "dark" : "light"
      } transition-all duration-300`}
      style={bg}
    >
      <Helmet title={"Smartticket"} />
      <AppNavbar />
      <Hero />
      <Benefits />
      <Solution />
      <Technology />
      <Future />
      <Community />
      <AppFooter />
    </div>
  );
}
