import React from 'react'

export default function AboutPage() {
    return (
        <section className="grid grid-cols-1 container mx-auto gap-16 my-32 py-32 px-4">
            <div>
                <h1 className="dark:text-gray-200 text-[#4C6474] font-bold text-4xl text-center">For you, and your community</h1>
            </div>
            <div>
                <p className="dark:text-gray-200 text-[#4C6474] font-normal text-2xl text-center">The Ticketek NFTs is here for you, to help you to be a part of the community. Every Ticketek NFT will give you many benefits as a community member, from getting early access to securing the early ticket release to play, hang out and have a drink together with the artist or other community members, and much more! </p>
            </div>
        </section>
    )
}
