import React from "react";
import technology1 from "./../assets/technology1.png";
import technology2 from "./../assets/technology2.png";
import technology3 from "./../assets/technology3.png";
import technology4 from "./../assets/technology4.png";

const line = {
  width: 0,
  height: "100%",
  bottom: 0,
  borderBottom: "250px solid #EAF3FD",
  borderRightWidth: "98vw",
  borderRightStyle: "solid",
  borderRightColor: "transparent",
  left: 0,
  right: 0,
  top: 0,
  position: "absolute",
  zIndex: "1",
};

export default function Technology() {
  return (
    <section className="bg-[#7388F2] relative">
      <img
        src={technology4}
        className="absolute right-0 -bottom-20 z-10"
        alt="/"
      />
      <div id="triangle-topleft" style={line} className="hidden md:block"></div>
      <div className="container px-6 py-10 mx-auto md:pb-80">
        <h1 className="text-3xl font-bold text-center text-white lg:text-4xl">
          Technology
        </h1>

        <div className="grid grid-cols-1 mt-24 md:gap-4 xl:gap-16 md:grid-cols-2 xl:grid-cols-3">
          {/* LEFT */}
          <div className="my-10 md:my-0 md:mx-16">
            <img src={technology1} alt="" />
            <p className="text-3xl font-semibold text-white my-4">Blockchain</p>
            <p className="my-4 text-white">
              By using "Smart Contracts", VIP NFT creates a truly decentralized
              ticketing experience on the world's most widely used and accepted
              web3 network, Ethereum.
            </p>
          </div>

          {/* MIDDLE */}
          <div className="my-10 md:my-0 md:mx-16">
            <img src={technology2} alt="" />
            <p className="text-3xl font-semibold text-white my-4">Wallet</p>
            <p className="my-4 text-white">
              Secure your NFT with either credit card or your own existing web3
              wallet. New to Digital Collectibles? Don't worry, access the
              benefits of NFT ownership with our secure and simple solution.
            </p>
          </div>

          {/* RIGHT */}
          <div className="my-10 md:my-0 md:mx-16">
            <img src={technology3} alt="" />
            <p className="text-3xl font-semibold text-white my-4">
              Marketplace
            </p>
            <p className="my-4 text-white">
              Control your Digital Asset (VIPNFT or SmartTicket). List for sale
              or rent it out on our marketplace or any Ethereum NFT compatible
              marketplace.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
