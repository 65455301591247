import React from "react";
import ethereum from "./../assets/ethereum.png";

// import { HiArrowsRightLeft } from "react-icons/hi2";
import { BiEnvelope } from "react-icons/bi";

const divStyles = {
  boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.25)",
  borderRadius: "25px",
  backdropFilter: "blur(2px)",
  background: "rgba(255, 255, 255, 0.5)",
};

export default function Notifications({ publicKey }) {
  return (
    <div className="flex p-6" style={divStyles}>
      <img src={ethereum} alt="eth" className="w-12" />
      <span className="sr-only">Info</span>
      <div className="ml-3 text-2xl font-bold text-black dark:text-gray-300 mt-1">
        {publicKey
          ? publicKey.slice(0, 5) + "..." + publicKey.slice(-5)
          : "No wallet connected"}
      </div>
      <div className="inline-flex rounded-md ml-auto gap-12" role="group">
        <BiEnvelope className="text-4xl text-[#4C6474] cursor-pointer" />
        {/* <HiArrowsRightLeft className="text-4xl text-[#4C6474] cursor-pointer" /> */}
      </div>
    </div>
  );
}
