import React from 'react'
import { MdOutlineCreditCard } from "react-icons/md"
import { GiPlantRoots } from "react-icons/gi"
import { FaCalendarAlt } from "react-icons/fa"
import { IoIosPeople } from "react-icons/io"

const divStyles = {
    boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.25)',
    borderRadius: '25px',
    backdropFilter: 'blur(2px)',
    background: 'rgba(255, 255, 255, 0.5)'
};

export default function Total() {
    return (
        <div className="flex flex-col p-10" style={divStyles}>
            <div className="grid grid-cols-2 gap-12">
                <div className="flex flex-col bg-blue-200 rounded-xl shadow-2xl p-4">
                    <div className="grid grid-rows-1 grid-flow-col">
                        <div className="row-span-3">
                            <div className="rounded-full bg-blue-600 w-20 h-20 flex justify-center items-center"><MdOutlineCreditCard className="text-white h-12 w-12" /></div>
                        </div>
                        <div className="col-span-2 text-center"><span className="text-4xl font-bold text-blue-600 text-center">10</span></div>
                        <div className="row-span-2 col-span-2 text-center"><span className="text-xl font-semibold text-blue-600 text-center">Passes</span></div>
                    </div>
                </div>
                <div className="flex flex-col bg-red-200 rounded-xl shadow-2xl p-4">
                    <div className="grid grid-rows-1 grid-flow-col">
                        <div className="row-span-3">
                            <div className="rounded-full bg-red-600 w-20 h-20 flex justify-center items-center"><FaCalendarAlt className="text-white h-12 w-12" /></div>
                        </div>
                        <div className="col-span-2 text-center"><span className="text-4xl font-bold text-red-600 text-center">16</span></div>
                        <div className="row-span-2 col-span-2 text-center"><span className="text-xl font-semibold text-red-600 text-center">Events</span></div>
                    </div>
                </div>
                <div className="flex flex-col bg-purple-200 rounded-xl shadow-2xl p-4">
                    <div className="grid grid-rows-1 grid-flow-col">
                        <div className="row-span-3">
                            <div className="rounded-full bg-purple-600 w-20 h-20 flex justify-center items-center"><GiPlantRoots className="text-white h-12 w-12" /></div>
                        </div>
                        <div className="col-span-2 text-center"><span className="text-4xl font-bold text-purple-600 text-center">80</span></div>
                        <div className="row-span-2 col-span-2 text-center"><span className="text-xl font-semibold text-purple-600 text-center">Benefits</span></div>
                    </div>
                </div>
                <div className="flex flex-col bg-green-200 rounded-xl shadow-2xl p-4">
                    <div className="grid grid-rows-1 grid-flow-col">
                        <div className="row-span-3">
                            <div className="rounded-full bg-green-600 w-20 h-20 flex justify-center items-center"><IoIosPeople className="text-white h-12 w-12" /></div>
                        </div>
                        <div className="col-span-2 text-center"><span className="text-4xl font-bold text-green-600 text-center">5</span></div>
                        <div className="row-span-2 col-span-2 text-center"><span className="text-xl font-semibold text-green-600 text-center">Attended</span></div>
                    </div>
                </div>
            </div>
        </div>
    )
}