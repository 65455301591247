import React, { useState, useEffect } from "react";

const EventDiv = (props) => {
  return (
    <div className={"bg-[#F6F1E5] p-4 rounded-lg flex"}>
      <div className="bg-white py-4 px-6 rounded-full font-bold text-xl text-[#2A4DD0] mr-4 h-14">
        {props.date}
      </div>
      <div
        className={"border-r border-2 border-[#9B8343] rounded-full mr-4"}
      ></div>
      <div className="grid">
        <h1 className={"font-semibold text-xl text-[#9B8343]"}>
          {props.event}
        </h1>
        <p className={"font-normal text-sm text-[#9B8343]"}>{props.time}</p>
      </div>
    </div>
  );
};

export default function Schedule({ loadingListings, listings }) {

  const [listing, setListing] = useState([]);

  const groupByMonth = (listing) => {
    return listing.reduce((acc, item) => {
      if (!acc[item.month]) {
        acc[item.month] = [];
      }
      acc[item.month].push(item);
      return acc;
    }, {});
  }

  useEffect(() => {
    if(!loadingListings && listings[0].asset?.attributes[2]?.value) {
      const listingValue = JSON.parse(listings[0].asset?.attributes[2]?.value);
      const groupedByMonth = groupByMonth(listingValue);
      setListing(groupedByMonth);
    }
  }, [listings, loadingListings]);

  return (
    <>
      {!loadingListings &&  (
        <section className="container px-6 py-4 mt-4 mb-4 mx-auto">
          <div className="grid grid-cols-1 md:gap-8 mt-8 xl:mt-4 xl:gap-16 lg:grid-cols-4 xl:grid-cols-3">
            <div className="col-span-2">
              <div className="rounded-lg drop-shadow-2xl bg-white p-8 mt-8 xl:mt-4">
                <h1 className="font-bold text-xl text-[#2A4DD0] mb-4">
                  Upcoming schedule for holders of that NFT
                </h1>
                
                {Object.keys(listing).map((month, index) =>
                  <div key={index}>
                    <h1 className="font-bold text-xl text-[#212429] my-4">{month}</h1>
                    <div className="grid grid-cols-1 gap-4">
                      {listing[month].map((item, i) =>
                        <EventDiv
                          key={i}
                          date={item.date}
                          event={item.event}
                          time={item.time}
                          textcolor={"#9B8343"}
                          bgcolor={"#F6F1E5"}
                        />
                      )}
                    </div>
                  </div>
                )}

                {/* Existing Static Code */}
                {/* <h1 className="font-bold text-xl text-[#212429] mb-4">January</h1>
                <div className="grid grid-cols-1 gap-4">
                  <EventDiv
                    date={"5"}
                    event={"New Year Huntington Beach Party"}
                    time={"12:00 - 13:00"}
                    textcolor={"#9B8343"}
                    bgcolor={"#F6F1E5"}
                  />

                  <EventDiv
                    date={"6"}
                    event={"Learn how to fly with Matt Hall"}
                    time={"12:00 - 13:00"}
                    textcolor={"#9B8343"}
                    bgcolor={"#F6F1E5"}
                  />
    
                  <EventDiv
                    date={"5"}
                    event={"Release new feature"}
                    time={"12:00 - 13:00"}
                    textcolor={"#9B8343"}
                    bgcolor={"#F6F1E5"}
                  />
                </div>

                <h1 className="font-bold text-xl text-[#212429] mb-4 mt-4">February</h1>
                <div className="grid grid-cols-1 gap-4">
                  <EventDiv
                    date={"5"}
                    event={"New Year Huntington Beach Party"}
                    time={"12:00 - 13:00"}
                    textcolor={"#9B8343"}
                    bgcolor={"#F6F1E5"}
                  />
  
                  <EventDiv
                    date={"6"}
                    event={"Learn how to fly with Matt Hall"}
                    time={"12:00 - 13:00"}
                    textcolor={"#9B8343"}
                    bgcolor={"#F6F1E5"}
                  />
  
                  <EventDiv
                    date={"5"}
                    event={"Release new feature"}
                    time={"12:00 - 13:00"}
                    textcolor={"#9B8343"}
                    bgcolor={"#F6F1E5"}
                  /> 
                </div> */}

              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}
