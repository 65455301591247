import React, { useContext } from "react";
import AppNavbar from "../../components/app-navbar";
import AppFooter from "../../components/footer";
import img_background_light from "../../components/assets/backgrounds/Bg-light2.png";
import img_background_dark from "../../components/assets/backgrounds/Dark-desktop-1.png";
import { ThemeContext } from "../../App";
import Helmet from "./../../components/helmet";
import Profile from "./../../components/profile";
import { HiSwitchHorizontal } from "react-icons/hi";
import ProfileResponsive from "../../components/profile-responsive";
import { useParams } from "react-router-dom";

export default function SettingsPage() {
  const { theme } = useContext(ThemeContext);
  const { param } = useParams();
  return (
    <div
      className={`pt-32 overflow-hidden ${theme === "dark" ? "dark" : "light"
        } transition-all duration-300`}
      style={{
        backgroundImage:
          theme === "dark"
            ? `url(${img_background_dark})`
            : `url(${img_background_light})`,
        backgroundSize: "cover",
        width: "100vw",
        minWidth: "100vw",
      }}
    >
      <Helmet title={"VIP NFT | Overview"} />
      <AppNavbar />

      <section className="container px-6 py-4 mt-4 mb-4 mx-auto">
        <div className="grid grid-cols-1 gap-8 mt-8 xl:mt-4 xl:gap-16 md:grid-cols-2 xl:grid-cols-4">
          <ProfileResponsive publicKey={param} />
          <Profile publicKey={param} />
          <div className="col-span-3 ...">
            <div className="flex bg-white/5 rounded-xl shadow-2xl p-6">
              <h1 className="text-4xl font-bold text-gray-600 dark:text-gray-200 lg:text-5xl ">
                Settings
              </h1>
              <div
                className="inline-flex rounded-md shadow-sm ml-auto"
                role="group"
              >
                <div className="ml-3 text-2xl font-bold text-gray-700 dark:text-gray-300 mt-4">
                  0x21cD...A120{" "}
                  <HiSwitchHorizontal className="inline-flex h-10 w-10 cursor-pointer" />
                </div>
              </div>
            </div>
            <div className="mt-6 md:col-span-2 dark:bg-slate-800/50 rounded-xl shadow-2xl">
              <form action="#" method="POST">
                <div className="overflow-hidden sm:rounded-md">
                  <div className="px-4 py-5 sm:p-6">
                    <div className="grid grid-cols-6 gap-10">
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          for="first-name"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          First name
                        </label>
                        <input
                          type="text"
                          name="first-name"
                          id="first-name"
                          autocomplete="given-name"
                          className="mt-1 px-3 py-2 bg-transparent border shadow-sm border-slate-300 placeholder-slate-400 dark:placeholder-gray-200 dark:text-white focus:outline-none focus:border-orange-300 focus:ring-orange-300 block w-full rounded-lg sm:text-sm focus:ring-1"
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label
                          for="last-name"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Last name
                        </label>
                        <input
                          type="text"
                          name="last-name"
                          id="last-name"
                          autocomplete="family-name"
                          className="mt-1 px-3 py-2 bg-transparent border shadow-sm border-slate-300 placeholder-slate-400 dark:placeholder-gray-200 dark:text-white focus:outline-none focus:border-orange-300 focus:ring-orange-300 block w-full rounded-lg sm:text-sm focus:ring-1"
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label
                          for="first-name"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Phone Number
                        </label>
                        <input
                          type="text"
                          name="first-name"
                          id="first-name"
                          autocomplete="given-name"
                          className="mt-1 px-3 py-2 bg-transparent border shadow-sm border-slate-300 placeholder-slate-400 dark:placeholder-gray-200 dark:text-white focus:outline-none focus:border-orange-300 focus:ring-orange-300 block w-full rounded-lg sm:text-sm focus:ring-1"
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label
                          for="last-name"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Email Address
                        </label>
                        <input
                          type="text"
                          name="last-name"
                          id="last-name"
                          autocomplete="family-name"
                          className="mt-1 px-3 py-2 bg-transparent border shadow-sm border-slate-300 placeholder-slate-400 dark:placeholder-gray-200 dark:text-white focus:outline-none focus:border-orange-300 focus:ring-orange-300 block w-full rounded-lg sm:text-sm focus:ring-1"
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label
                          for="first-name"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Surburb/City
                        </label>
                        <input
                          type="text"
                          name="first-name"
                          id="first-name"
                          autocomplete="given-name"
                          className="mt-1 px-3 py-2 bg-transparent border shadow-sm border-slate-300 placeholder-slate-400 dark:placeholder-gray-200 dark:text-white focus:outline-none focus:border-orange-300 focus:ring-orange-300 block w-full rounded-lg sm:text-sm focus:ring-1"
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label
                          for="last-name"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          State/Province
                        </label>
                        <input
                          type="text"
                          name="last-name"
                          id="last-name"
                          autocomplete="family-name"
                          className="mt-1 px-3 py-2 bg-transparent border shadow-sm border-slate-300 placeholder-slate-400 dark:placeholder-gray-200 dark:text-white focus:outline-none focus:border-orange-300 focus:ring-orange-300 block w-full rounded-lg sm:text-sm focus:ring-1"
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label
                          for="first-name"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Postcode
                        </label>
                        <input
                          type="text"
                          name="first-name"
                          id="first-name"
                          autocomplete="given-name"
                          className="mt-1 px-3 py-2 bg-transparent border shadow-sm border-slate-300 placeholder-slate-400 dark:placeholder-gray-200 dark:text-white focus:outline-none focus:border-orange-300 focus:ring-orange-300 block w-full rounded-lg sm:text-sm focus:ring-1"
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label
                          for="last-name"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Country
                        </label>
                        <input
                          type="text"
                          name="last-name"
                          id="last-name"
                          autocomplete="family-name"
                          className="mt-1 px-3 py-2 bg-transparent border shadow-sm border-slate-300 placeholder-slate-400 dark:placeholder-gray-200 dark:text-white focus:outline-none focus:border-orange-300 focus:ring-orange-300 block w-full rounded-lg sm:text-sm focus:ring-1"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-4 sm:px-6 justify-end flex">
                    <button
                      type="submit"
                      className="bg-gray-200 p-4 dark:text-gray-200 hover:bg-gray-300 dark:bg-sky-500/30 dark:hover:border-blue-100 hover:border-2 dark:border-white/70 border-transparent border-2 hover:shadow-md text-gray-600 font-semibold rounded-full transition-all duration-300 py-1 shadow-md hover:opacity-50"
                    >
                      Update
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      <AppFooter />
    </div>
  );
}
