import React from "react";
import card_gold from "./../assets/SpinningCard-Gold.gif";
import card_palladium from "./../assets/SpinningCard-Palladium.gif";
import card_diamond from "./../assets/SpinningCard-Diamond.gif";
import logo_gold from "../assets/logo/logo_gold_nocap.png";
import logo_palladium from "../assets/logo/logo_palladium_nocap.png";
import logo_diamond from "../assets/logo/Logo-Diamond.png";
import GifPlayer from "react-gif-player";

export default function Levels() {
  // handle react giv to paused on last frame
  const [paused, setPaused] = React.useState(true);
  const handleClick = () => {
    setPaused(!paused);
  };
  return (
    <section>
      {/* GOLD */}
      <div className="grid md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-2 items-center px-6 mx-auto mt-24">
        <div className="flex text-center justify-center md:justify-center">
          <GifPlayer
            gif={card_gold}
            autoplay={true}
            loop={true}
            className="h-96"
            pausedRef={setPaused}
          />
        </div>

        <div className="gap-2 grid lg:pr-64 lg:pl-20">
          <div className="flex gap-8 justify-between items-center">
            <p className="text-2xl text-gray-600 dark:text-gray-300 font-mono font-bold">
              Gold VIPNFT
            </p>
            <img src={logo_gold} alt="Logo" className="w-1/12 " />
          </div>
          <p className="font-normal text-gray-600 dark:text-gray-300  text-xl">
            Beat the Queue with Early Ticket Access as a Gold Vip NFT holder.
            Your NFT gives you the First Right of Refusal to purchase General
            Entry Tickets. Receive a 10% discount on ticket purchase. Access
            private community chat for NFT holders only. Receive invitations to
            exclusive events.
          </p>
          <ul className=" list-disc font-normal text-gray-600 dark:text-gray-300  text-xl  text-xlml-10">
            <li>Each NFT holder owns unique Pacific Airshow content</li>
            <li>
              First Right of Refusal to purchase up to 6 General Entry tickets
            </li>
            <li>
              More exciting features to be revealed for these "Dynamic" NFTs
            </li>
            <li>Ability to rent out the NFT for future events</li>
            <li>Upgrade to VIP Palladium for Premium Tickets</li>
          </ul>
          <div className="flex gap-8 justify-between items-center mt-7">
            <div className="...">
              <p className="text-yellow-500 font-bold text-xl text-center">
                400
              </p>
              <p className="text-gray-200 font-semibold text-xl text-center">
                NFTs
              </p>
            </div>

            <div className="...">
              <p className="text-yellow-500 font-bold text-xl">$499</p>
              <p className="text-gray-200 font-semibold text-xl text-center">
                USD
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* PALLADIUM */}
      <div className="grid md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-2 items-center px-6 mx-auto mt-24">
        <div className="gap-2 grid lg:pl-64 lg:pr-20">
          <div className="flex gap-8 justify-between items-center">
            <p className="text-2xl text-gray-600 dark:text-gray-300 font-mono font-bold">
              Palladium VIPNFT
            </p>
            <img src={logo_palladium} alt="Logo" className="w-1/12 " />
          </div>
          <p className="font-normal text-gray-600 dark:text-gray-300  text-xl">
            This Premium NFT unlocks First Access to premium tickets plus all
            the Gold NFT benefits. Be the first to receive NFT content offered
            by performers and invitations to special events. 10% off premium
            ticket prices (limits apply).
          </p>
          <p className="mr-10 font-normal text-gray-600 dark:text-gray-300  text-xl">
            Gold VIP Benefits plus:
          </p>
          <ul className=" list-disc font-normal text-gray-600 dark:text-gray-300  text-xl  text-xlml-10">
            <li>10% discount on Premium Entry Tickets</li>
            <li>NFT with unique Pacific Airshow content</li>
            <li>
              More exciting features to be revealed for these "Dynamic" NFTs
            </li>
            <li>Upgrade to VIP Diamond for Chalets</li>
          </ul>
          <div className="flex gap-8 justify-between items-center mt-7">
            <div className="...">
              <p className="text-yellow-500 font-bold text-xl text-center">
                40
              </p>
              <p className="text-gray-200 font-semibold text-xl text-center">
                NFTs
              </p>
            </div>
            <div className="...">
              <p className="text-yellow-500 font-bold text-xl">$4,990</p>
              <p className="text-gray-200 font-semibold text-xl text-center">
                USD
              </p>
            </div>
          </div>
        </div>
        <div className="flex text-center justify-center md:justify-center">
          {/* <img
            src={card_palladium}
            alt="Cellphone Illustration"
            className="h-96"
          /> */}
          <GifPlayer
            gif={card_palladium}
            autoplay={true}
            loop={true}
            className="h-96"
          />
        </div>
      </div>
      {/* DIAMOND */}
      <div className="grid md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-2 items-center px-6 mx-auto mt-24">
        <div className="flex text-center justify-center md:justify-center">
          {/* <img
            src={card_diamond}
            alt="Cellphone Illustration"
            className="h-96"
          /> */}
          <GifPlayer
            gif={card_diamond}
            autoplay={true}
            loop={true}
            className="h-96"
          />
        </div>

        <div className="gap-2 grid lg:pr-64 lg:pl-20">
          <div className="flex gap-8 justify-between items-center">
            <p className="text-2xl text-gray-600 dark:text-gray-300 font-mono font-bold">
              Diamond VIPNFT
            </p>
            <img src={logo_diamond} alt="Logo" className="w-1/12 " />
          </div>
          <p className="font-normal text-gray-600 dark:text-gray-300  text-xl">
            The ultimate VIP NFT from Pacific Airshow. Besides first access to
            Cabanas, you receive invitations to private VIP-only events and a
            locked community chat forum. All the features of Palladium and 10%
            off all tickets (limits apply).
          </p>

          <p className="mr-10 font-normal text-gray-600 dark:text-gray-300  text-xl">
            Palladium VIP benefits plus:
          </p>
          <ul className=" list-disc font-normal text-gray-600 dark:text-gray-300  text-xl  text-xlml-10">
            <li>Behind the scenes VIP Access</li>
            <li>NFT with unique Pacific Airshow content</li>
            <li>Private Chat for NFT holders only</li>
            <li>1 of only 15 Diamond NFTs ever</li>
            <li>
              More exciting features to be revealed for these "Dynamic" NFTs
            </li>
          </ul>
          <div className="flex gap-8 justify-between items-center mt-7">
            <div className="...">
              <p className="text-yellow-500 font-bold text-xl text-center">
                15
              </p>
              <p className="text-gray-200 font-semibold text-xl text-center">
                NFTs
              </p>
            </div>

            <div className="...">
              <p className="text-yellow-500 font-bold text-xl">$14,990</p>
              <p className="text-gray-200 font-semibold text-xl text-center">
                USD
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center my-32">
        <button
          className="bg-[#EF0255] p-4 text-white hover:border-2 border-white/70 border-2 font-semibold rounded-full transition-all duration-300 py-1 shadow-md hover:opacity-60"
          onClick={() => {
            window.location.href = "/explore";
          }}
        >
          <p className="text-lg md:text-lg ">Explore Marketplace</p>
        </button>
      </div>
    </section>
  );
}
