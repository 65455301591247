import React from "react";
import { FaPlay } from "react-icons/fa";
import heroimgvipnft from "./../assets/VIPNFT-animation.gif";
import herobg from "./../assets/herobg.png";

export default function Hero() {

  return (
    <div
      style={{
        backgroundImage: `url(${herobg})`,
        backgroundSize: "cover",
        width: "100vw",
        minWidth: "100vw",
      }}
    >
      <section
        id="hero"
        className="md:grid md:grid-cols-2 gap-16 items-center container px-6 py-10 mx-auto pb-10 "
      >
        <div className="grid h-[18rem] md:h-96 w-5/6 max-w-xl place-items-center mx-auto md:ml-auto md:order-2">
          <img src={heroimgvipnft} className="w-10/12 md:order-2" alt="/" />
        </div>
        <div className="... ">
          <div className="flex flex-col static dark:text-gray-200 text-gray-600 md:text-4xl xl:text-6xl 2xl:text-6xl xl:mt-16 xl:mr-0">
            <div className="flex flex-wrap md:justify-start justify-center drop-shadow-xl subpixel-antialiased">
              <h1 className="text-[#212429] dark:text-white text-[60px] md:text-[80px] font-bold">
                Fans with{" "}
                <span className="text-[60px] md:text-[80px] font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-[#2a4dd0] to-[#6e8cff]">
                  Benefits
                </span>{" "}
              </h1>
            </div>

            <p className="my-8 md:my-10 md:place-self-start text-xl md:text-2xl font-base">
              Skip the queue with{" "}
              <span className="font-extrabold">First Access</span> to Tickets
            </p>
            <div className="grid grid-cols-2 gap-5 p-2 pl-0 md:mt-10 mb-5 md:w-3/5">
              <button
                className="bg-gradient-to-r from-[#2a4dd0] to-[#6e8cff] text-white font-semibold rounded-lg transition-all duration-300 py-4 px-6 shadow-md hover:opacity-50"
                onClick={() => {
                  window.location.href = "/explore";
                }}
              >
                <p className="text-lg md:text-lg ">Explore</p>
              </button>
              <button
                className="bg-white border-[#2a4dd0] border-2 text-[#2a4dd0] hover:shadow-md font-semibold rounded-lg transition-all duration-300 py-1 shadow-md hover:opacity-50"
                onClick={() => {
                  window.location.href = "/create";
                }}
              >
                <p className="text-lg md:text-lg ">Create</p>
              </button>
            </div>
            <a
              className="text-indigo-400 dark:text-gray-200 inline-flex items-center text-center hover:text-blue-600 duration-200 text-base mb-5"
              href="/explore"
            >
              The marketplace for VIP experiences
              <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                className="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
              >
                <path d="M5 12h14M12 5l7 7-7 7" />
              </svg>
            </a>
          </div>
        </div>
        {/* <div className="h-96 md:w-5/6 max-w-xl shadow-xl rounded-xl grid place-items-center bg-slate-900/40 shadow-sky-900 md:ml-auto mx-auto">
          <span className="text-slate-100/60 text-xl font-normal italic items-center text-center">
            <FaPlay size={50} className="mx-auto my-auto mb-3" />
            Explainer Video Coming Soon
          </span>
        </div> */}
      </section>
    </div>
  );
}
