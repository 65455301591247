import React from "react";
import bgimagehiw from "./../assets/bgimagehiw.png";
import nftcard from "./../assets/nftcard.png";

export default function dynamic() {
  return (
    <React.Fragment>
      <div
        className="py-20"
        style={{
          backgroundImage: `url(${bgimagehiw})`,
          backgroundSize: "cover",
          width: "100vw",
          minWidth: "100vw",
        }}
      >
        <section className="container-fluid md:container md:mx-auto xl:mt-60 mx-8">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-36">
            <div>
              <p className="text-base uppercase font-semibold tracking-widest text-transparent bg-clip-text bg-gradient-to-r from-[#2a4dd0] to-[#6e8cff]">
                Create
              </p>
              <h1 className="text-[#212429] text-6xl font-bold mb-6">
                Dynamic NFT
              </h1>
              <div className="grid gap-8 text-base text-black">
                <p className="">
                  The layers of this Dynamic VIPNFT enable ownership of unique
                  content, early access to events and an option to load rewards
                  into the NFT. Creators and artists design these features to
                  enhance the fan experience. Start building your NFT series
                  today.
                </p>
              </div>
              <div className="flex justify-start mt-8 mb-4">
                <button
                  className="bg-gradient-to-r from-[#2a4dd0] to-[#6e8cff] text-white font-semibold rounded-lg transition-all duration-300 py-4 px-24 shadow-md hover:opacity-50"
                  onClick={() => {
                    window.location.href = "/create";
                  }}
                >
                  <p className="text-lg md:text-lg ">Start Building</p>
                </button>
              </div>
              <p>
                Stay tuned for our{" "}
                <a
                  className="text-black font-bold dark:text-gray-200 inline-flex items-center text-center hover:text-blue-600 duration-200 text-base"
                  href="/ticketing"
                >
                  Smart Ticket
                </a>{" "}
                NFTs coming soon.{" "}
              </p>
              <a
                className="text-indigo-400 dark:text-gray-200 inline-flex items-center text-center hover:text-blue-600 duration-200 text-base mb-5"
                href="/ticketing"
              >
                Learn more
              </a>
            </div>
            {/* <div> */}
            <img src={nftcard} alt="nftcard" />
            {/* </div> */}
          </div>
        </section>
      </div>
    </React.Fragment>
  );
}
