import React from 'react'


const title = ({ logo, title, color, bg }) => {
    return (

        <div className="rounded-xl shadow-2xl justify-center level-title" style={bg}>
            <div className="flex justify-center">
                <img src={logo} alt="" className="" />
                <p className={"font-medium text-4xl mt-6 " + color}>{title}</p>
            </div>
        </div>

    )
}

export default title;
