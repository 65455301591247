import React from "react";
import pa from "./../assets/ezgif.com-gif-maker-2.gif";
import pa_logo from "./../assets/pa_logo.png";
import pa_bg_1 from "./../assets/pa_bg_1.png";
import pa_bg_2 from "./../assets/pa_bg_2.png";
import Card from "../../../../components/card";
import { HiPaperAirplane } from "react-icons/hi";
import ic_plane from "./../assets/f22.png";

export default function HeroPage() {
  return (
    <section id="header" className="px-6 bg-[#EF0255] relative py-20">
      <img
        src={pa_bg_1}
        className="absolute top-0 left-0 hidden md:block z-0"
        alt="/"
      />
      <img
        src={pa_bg_2}
        className="absolute bottom-0 right-0 hidden md:block z-0"
        alt="/"
      />

      <div class="grid md:flex justify-center z-10 relative">
        <div class="item w-auto md:pr-52 bgye order-last md:order-first">
          <h1 className="grid justify-center md:inline-flex items-center text-end duration-200 align-text-bottom text-white mt-10 text-2xl md:text-5xl font-bold lg:ml-10">
            <img src={pa_logo} alt="Pacific Airshow" className="mr-3 md:max-w-full md:h-auto" />
            <span className="font-extrabold ">VIPNFT &nbsp;</span> landing
            soon...
          </h1>
          <h1 className="text-gray-200 text-3xl mt-20 font-semibold text-center md:text-left">
            We've re-defined the Airshow experience,
            <br />
            <div className="md:ml-20 md:mt-5 inline-block align-middle">
              now{" "}
              <span className="bg-white text-[#EF0255] rounded px-1 mx-1">
                own
              </span>{" "}
              the ultimate fan experience...
            </div>
          </h1>
          <p className="text-gray-200 font-normal text-2xl mt-16">
            {/* <ul className="">
              <li>- First Access Rights to Events</li>
              <li>- Exclusive Content Ownership</li>
            </ul> */}
            <table className="bg-gray-200 shadow-none text-gray-200 text-xl font-semibold">
              <tbody>
                <tr className="bg-[#EF0255]">
                  <td className="px-10 py-2">
                    <p
                      className="text-gray-200 inline-flex items-center text-center duration-200 align-text-bottom"
                      href="/creator/pacific-airshow"
                    >
                      <img src={ic_plane} alt="/" className="w-8 h-8 mr-1" />
                      First access rights to events
                    </p>
                  </td>
                  <td className="px-10 py-2">
                    <p
                      className="text-gray-200 inline-flex items-center text-center duration-200 align-text-bottom"
                      href="/creator/pacific-airshow"
                    >
                      <img src={ic_plane} alt="/" className="w-8 h-8 mr-1" />
                      Invitations to VIP events
                    </p>
                  </td>
                </tr>
                <tr className="bg-[#EF0255]">
                  <td className="px-10 py-2">
                    <p
                      className="text-gray-200 inline-flex items-center text-center duration-200 align-text-bottom"
                      href="/creator/pacific-airshow"
                    >
                      <img src={ic_plane} alt="/" className="w-8 h-8 mr-1" />
                      Exclusive content ownership
                    </p>
                  </td>
                  <td className="px-10 py-2">
                    <p
                      className="text-gray-200 inline-flex items-center text-center duration-200 align-text-bottom"
                      href="/creator/pacific-airshow"
                    >
                      <img src={ic_plane} alt="/" className="w-8 h-8 mr-1" />
                      Gamification & sweepstakes
                    </p>
                  </td>
                </tr>
                <tr class="bg-[#EF0255]">
                  <td class="px-10 py-2">
                    <p
                      className="text-gray-200 inline-flex items-center text-center duration-200 align-text-bottom"
                      href="/creator/pacific-airshow"
                    >
                      <img src={ic_plane} alt="/" className="w-8 h-8 mr-1" />
                      Interaction with performers
                    </p>
                  </td>
                  <td class="px-10 py-2">
                    <p
                      className="text-gray-200 inline-flex items-center text-center duration-200 align-text-bottom"
                      href="/creator/pacific-airshow"
                    >
                      <img src={ic_plane} alt="/" className="w-8 h-8 mr-1" />
                      Community access
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </p>
        </div>

        <div class="item w-auto bg-white dark:bg-transparent h-fit rounded-lg md:block px-2 md:px-0">
          <Card
            title={"Pacific Airshow Huntington Beach"}
            level={"Diamond"}
            description={
              "First Access to VIP Tickets, Cabanas and VIP events with unique NFT content and benefits."
            }
            picture={pa}
            price={"490"}
            color={"#F90254"}
            width={"md:w-80 xl:w-96"}
            company={"PA"}
            extra={"1 of 15"}
          >
            <ul className="text-left list-disc py-5 px-3 space-y-2">
              <li>
                10% discount on
                <span className="font-bold"> chalets</span>
              </li>
              <li>Behind the scenes VIP Access.</li>
              <li>NFT with unique Pacific Airshow content.</li>
              <li>Private Chat for NFT holders only.</li>
              <li>1 of only 15 Diamond NFTs ever.</li>
              <li>
                More exciting features to be revealed for these "Dynamic" NFTs.
              </li>
            </ul>
          </Card>
        </div>
      </div>
      {/* <p className="text-center text-gray-200 text-lg mt-5">
        Explore Pacific Airshow VIPNFT options below
      </p> */}
    </section>
  );
}
