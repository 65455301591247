import React, { useEffect, useState } from "react";
import imgcollecitble from "./../assets/imgcollectible.png";
import iconown from "./../assets/iconown.png";
import collectiblebg from "./../assets/collectiblebg.png";
import collectible1 from "./../../home/assets/spotlight1.png";
import collectible2 from "./../../home/assets/spotlight2.png";
import collectible3 from "./../../home/assets/spotlight3.png";
import collectible4 from "./../../home/assets/spotlight4.png";
import collectible5 from "./../assets/collectible5.png";
import ReactPaginate from "react-paginate";
import accessdiamond from "./../../explore/assets/accessdiamond.png";
import accessgold from "./../../explore/assets/accessgold.png";
import accesspalladium from "./../../explore/assets/accesspalladium.png";

import {
  MediaRenderer,
  useActiveListings,
  useContract,
} from "@thirdweb-dev/react";

const imgcard = {
  backgroundImage: `url(${imgcollecitble})`,
  backgroundSize: "cover",
  height: "372px",
  backgroundRepeat: "no-repeat",
  // backgroundColor: "#8FACB3",
  // boxShadow: "inset 0 0 0 1000px rgba(0,0,0,.2)",
};

const collectbg = {
  backgroundImage: `url(${collectiblebg})`,
  backgroundSize: "cover",
  //   height: "218px",
  backgroundRepeat: "no-repeat",
};

const layer = {
  background:
    "linear-gradient(199.38deg, rgba(255, 255, 255, 0) -2.34%, rgba(42, 77, 208, 0.74) 91.22%)",
  backgroundSize: "cover",
  height: "372px",
  backgroundRepeat: "no-repeat",
};

const diamond1 = {
  id: 1,
  title: "Pacific Airshow GC Sky Angels #25",
  category: "diamond",
  owner: "jamessaviationbuff.eth",
  img: collectible1,
};
const diamond2 = {
  id: 4,
  title: "Pacific Airshow GC Sky Angels #28",
  category: "diamond",
  owner: "harryjoe.eth",
  img: collectible4,
};

const diamond3 = {
  id: 1,
  title: "Pacific Airshow GC Sky Angels #25",
  category: "diamond",
  owner: "jamessaviationbuff.eth",
  img: collectible1,
};

const gold1 = {
  id: 2,
  title: "Pacific Airshow GC Sky Angels #3",
  category: "gold",
  owner: "johnbrady.eth",
  img: collectible2,
};
const gold2 = {
  id: 2,
  title: "Pacific Airshow GC Sky Angels #3",
  category: "gold",
  owner: "johnbrady.eth",
  img: collectible2,
};
const gold3 = {
  id: 2,
  title: "Pacific Airshow GC Sky Angels #3",
  category: "gold",
  owner: "johnbrady.eth",
  img: collectible2,
};

const palladium1 = {
  id: 3,
  title: "Pacific Airshow GC Sky Angels #16",
  category: "palladium",
  owner: "taylorbeer.eth",
  img: collectible3,
};
const palladium2 = {
  id: 3,
  title: "Pacific Airshow GC Sky Angels #16",
  category: "palladium",
  owner: "taylorbeer.eth",
  img: collectible3,
};
const palladium3 = {
  id: 3,
  title: "Pacific Airshow GC Sky Angels #16",
  category: "palladium",
  owner: "taylorbeer.eth",
  img: collectible3,
};

const itemsArray = [
  gold1,
  palladium1,
  diamond1,
  palladium2,
  palladium3,
  diamond2,
  diamond3,
  gold2,
  gold3,
];

const SpotlightTag = (props) => {
  if (props.tag == "gold") {
    return (
      <div
        className="cursor-pointer rounded-full text-white uppercase font-semibold h-8 p-2 w-[100px] text-center text-xs"
        style={{
          background:
            "linear-gradient(90deg, rgba(183,148,53,1) 29%, rgba(183,148,53,0.2945553221288515) 100%)",
        }}>
        Gold
      </div>
    );
  }

  if (props.tag == "diamond") {
    return (
      <div
        className="cursor-pointer rounded-full text-white uppercase font-semibold h-8 p-2 w-[100px] text-xs text-center"
        style={{
          background:
            "linear-gradient(90deg, rgba(143,172,179,1) 29%, rgba(144,173,182,0.2861519607843137) 100%)",
        }}>
        Diamond
      </div>
    );
  }

  if (props.tag == "palladium") {
    return (
      <div
        className="cursor-pointer rounded-full text-white uppercase font-semibold h-8 p-2 w-[100px] text-xs text-center"
        style={{
          background:
            "linear-gradient(90deg, rgba(180,111,97,1) 6%, rgba(185,115,98,0.2777485994397759) 100%)",
        }}>
        Palladium
      </div>
    );
  }
};

function Items({ currentItems }) {
  return (
    <>
      {currentItems &&
        currentItems.map((item) => (
          <CollectibleCards img={collectible1} data={item} />
        ))}
    </>
  );
}

export default function Collectibles() {
  // // Initialize the marketplace contract
  // const address = process.env.REACT_APP_MARKETPLACE_ADDR;

  // const { contract: marketplace } = useContract(
  //   address, // Your marketplace contract address here
  //   "marketplace"
  // );

  // const { data: listings, isLoading: loadingListings } =
  //   useActiveListings(marketplace);

  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const [items, setItems] = useState(itemsArray);
  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const endOffset = itemOffset + 3;
  // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = items.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(items.length / 3);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * 3) % items.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  const sortByCategory = (category) => {
    const filtered = itemsArray.filter((obj) => {
      return obj.category === category;
    });

    console.log(filtered);
    setItems(filtered);
  };

  return (
    <React.Fragment>
      <div
        className="grid my-40 lg:mx-auto lg:my-60 container text-center"
        style={collectbg}>
        <div className="mx-5 lg:mx-0">
          <h1 className="font-bold text-3xl text-black mb-8">
            Choose your VIPNFT Access Level
          </h1>

          <div className="flex flex-col lg:flex-row lg:justify-center mx-auto gap-8 my-8">
            <div className="relative">
              <div
                className="flex justify-center cursor-pointer"
                onClick={(e) => sortByCategory("gold")}>
                <img
                  src={accessgold}
                  alt="accessgold"
                  className="hover:drop-shadow-2xl"
                />
              </div>
            </div>
            <div className="relative">
              <div
                className="flex justify-center cursor-pointer"
                onClick={(e) => sortByCategory("palladium")}>
                <img
                  src={accesspalladium}
                  alt="accesspalladium"
                  className="hover:drop-shadow-2xl"
                />
              </div>
            </div>
            <div className="relative">
              <div
                className="flex justify-center cursor-pointer"
                onClick={(e) => sortByCategory("diamond")}>
                <img
                  src={accessdiamond}
                  alt="accessdiamond"
                  className="hover:drop-shadow-2xl"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-wrap container">
          <Items currentItems={currentItems} />
        </div>
        <div className="grid">
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="<"
            pageClassName="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
            pageLinkClassName="page-link"
            previousClassName="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
            previousLinkClassName="page-link"
            nextClassName="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
            nextLinkClassName="page-link"
            breakClassName="page-item"
            breakLinkClassName="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700"
            containerClassName="isolate inline-flex -space-x-px rounded-md shadow-sm justify-center mt-10"
            activeClassName="relative z-10 inline-flex items-center border border-indigo-500 bg-indigo-50 px-4 py-2 text-sm font-medium text-indigo-600 focus:z-20"
            renderOnZeroPageCount={null}
          />
        </div>
        {/* {
          // If the listings are loading, show a loading message
          loadingListings ? (
            <div>Loading listings...</div>
          ) : (
            // Otherwise, show the listings
            <div className={"grid grid-cols-3 gap-32"}>
              {listings?.map((listing) => (
                <div
                  key={listing.id}
                  className="sm:mb-0 mb-6 hover:cursor-pointer mr-6 md:mr-0"
                  onClick={() =>
                    window.open(`marketplace/listing/${listing.id}`)
                  }>
                  <div className="bg-white shadow-2xl dark:shadow-2xl md:h-full rounded-3xl dark:hover:shadow-white transition-all duration-300">
                    <div
                      className="rounded-2xl body-font"
                      style={{
                        //  backgroundImage: `url(${img})`,
                        backgroundSize: "cover",
                        height: "372px",
                        backgroundRepeat: "no-repeat",
                      }}>
                      <div
                        className="p-3 rounded-2xl body-font relative"
                        style={layer}>
                        <div className="grid flex-auto absolute right-5 pl-4">
                          <div className="flex justify-end">
                            <button
                              className="bg-gradient-to-r from-[#2A4DD0] to-[#6E8CFF] mt-2 h-10 text-white w-28 hover:border-2 font-semibold rounded-full transition-all duration-300 py-1 shadow-md hover:opacity-60"
                              onClick={() => {
                                window.open(
                                  `marketplace/listing/${listing.id}`
                                );
                              }}>
                              <p className="text-sm md:text-sm ">3 for sale</p>
                            </button>
                          </div>
                        </div>
                        <MediaRenderer
                          src={listing.asset.image}
                          style={{
                            borderRadius: 16,
                            // Fit the image to the container
                            width: "100%",
                            height: "100%",
                          }}
                          // className="absolute inset-0 w-full h-full object-cover object-center z-30"
                        />
                        <div className="flex justify-start absolute gap-4 bottom-0">
                          <img src={iconown} alt="" />
                          <h2 className="text-xs font-medium text-left title-font dark:text-white text-gray-600 mt-2">
                            Owned by Lorem ipsum
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="px-2 m-8">
                      <h2 className="text-xl font-medium text-left title-font dark:text-black text-gray-600 mt-10">
                        <a href={`/listing/${listing.id}`}>
                          <a className={""}>{listing.asset.name}</a>
                        </a>
                      </h2>
                      <div className="flex mt-5 justify-between">
                        <h2 className="text-xl font-medium text-left title-font dark:text-black text-gray-600">
                          {listing.buyoutCurrencyValuePerToken.displayValue}{" "}
                          {listing.buyoutCurrencyValuePerToken.symbol}
                        </h2>
                        <p className="text-[#ACB5BD]">Starting bid</p>
                      </div>

                      <button
                        className="bg-gradient-to-r from-[#2A4DD0] to-[#6E8CFF] mt-10 mb-10 md:mb-0 p-4 text-white hover:border-2 border-white/70 w-full border-2 font-semibold rounded-lg transition-all duration-300 py-3 shadow-md hover:opacity-60"
                        onClick={() => {
                          window.open(`marketplace/listing/${listing.id}`);
                        }}>
                        <p className="text-md md:text-md ">Purchase Now</p>
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )
        } */}
      </div>
    </React.Fragment>
  );
}

const CollectibleCards = ({ img, data }) => {
  return (
    <div
      className="p-8 w-full sm:w-1/2 lg:w-1/3 sm:mb-0 mb-6 hover:cursor-pointer md:mr-0"
      onClick={() => {
        window.location.href = "/collection/nft1";
      }}>
      <div className="bg-white shadow-2xl dark:shadow-2xl md:h-full rounded-3xl dark:hover:shadow-white transition-all duration-300">
        <div
          className="rounded-2xl body-font"
          style={{
            backgroundImage: `url(${data.img})`,
            backgroundSize: "cover",
            height: "372px",
            backgroundRepeat: "no-repeat",
          }}>
          <div className="p-3 rounded-2xl body-font" style={layer}>
            <div className="flex relative h-full">
              <div className="grid flex-auto pl-4">
                <div className="flex justify-end">
                  <button
                    className="bg-gradient-to-r from-[#2A4DD0] to-[#6E8CFF] mt-2 h-10 text-white w-28 hover:border-2 font-semibold rounded-full transition-all duration-300 py-1 shadow-md hover:opacity-60"
                    onClick={() => {
                      window.location.href = "/marketplace";
                    }}>
                    <p className="text-sm md:text-sm ">3 for sale</p>
                  </button>
                </div>
                <div className="flex justify-start absolute gap-4 bottom-0">
                  <img src={iconown} alt="" />
                  <h2 className="text-xs font-medium text-left title-font text-white mt-2">
                    Owned by {data.owner}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="px-2 m-8">
          <div className="flex justify-between gap-4 mt-10">
            <h2 className="text-xl font-medium text-left title-font dark:text-black text-gray-600 ">
              {data.title}
            </h2>
            <SpotlightTag tag={data.category} />
          </div>
          <div className="flex mt-5 justify-between">
            <h2 className="text-xl font-medium text-left title-font dark:text-black text-gray-600">
              3.29 ETH
            </h2>
            <p className="text-[#ACB5BD]">Starting bid</p>
          </div>

          <button
            className="bg-gradient-to-r from-[#2A4DD0] to-[#6E8CFF] mt-10 mb-10 md:mb-0 p-4 text-white hover:border-2 border-white/70 w-full border-2 font-semibold rounded-lg transition-all duration-300 py-3 shadow-md hover:opacity-60"
            onClick={() => {
              window.location.href = "/collection/nft1";
            }}>
            <p className="text-md md:text-md ">Purchase Now</p>
          </button>
        </div>
      </div>
    </div>
  );
};
