import React, { useContext, useState } from "react";
import { ThemeContext } from "../../../App";
import AppNavbar from "../../../components/app-navbar";
import img_background_light from "../../../components/assets/backgrounds/Bg-light2.png";
import img_background_dark from "../../../components/assets/backgrounds/Dark-desktop-1.png";
import AppFooter from "../../../components/footer";
import { SiEthereum } from "react-icons/si";

export default function LoginPage() {
  const [showPassPlaceholder, setShowPassPlaceholder] = useState(false);
  const { theme } = useContext(ThemeContext);
  return (
    <div
      className={`pt-16 overflow-hidden ${
        theme === "dark" ? "dark" : "light"
      } transition-all duration-300`}
      style={{
        backgroundImage:
          theme === "dark"
            ? `url(${img_background_dark})`
            : `url(${img_background_light})`,
        backgroundSize: "cover",
        width: "100vw",
        minWidth: "100vw",
        minHeight: "100vh",
      }}
    >
      <AppNavbar />
      <div className="flex justify-center items-center h-screen">
        <div className="items-center p-20 bg-white/20 w-fit rounded-xl shadow-lg dark:shadow-yellow-500/50">
          <p className="text-3xl text-center text-gray-700 dark:text-gray-200 font-bold">
            Welcome Back!
          </p>
          <div className="bg-white/10 rounded-full border dark:border-0 border-gray-400 mt-16 mx-auto lg:mx-0  focus-within:ring focus-within:border-blue-400 focus-within:ring-blue-300 focus-within:ring-opacity-40 dark:focus-within:border-blue-300">
            <div className="flex flex-nowrap justify-between md:flex-row md:text-center">
              <input
                type="email"
                className="p-2 m-1 text-sm w-full md:w-auto text-gray-600 dark:text-gray-200 bg-transparent appearance-none focus:outline-none focus:placeholder-transparent"
                placeholder="Email"
                aria-label="Email"
              />
              {!showPassPlaceholder && (
                <button
                  onClick={() => setShowPassPlaceholder(!showPassPlaceholder)}
                  className="px-3 py-2 m-1 text-sm font-medium tracking-wider text-white transition-colors duration-200 transform bg-blue-700 rounded-full w-auto hover:bg-gray-700"
                >
                  Login
                </button>
              )}
            </div>
          </div>
          {showPassPlaceholder && (
            <div className="transition-all duration-500">
              <div className="bg-white/10 rounded-full border dark:border-0 border-gray-400 mt-10 mx-auto lg:mx-0  focus-within:ring focus-within:border-blue-400 focus-within:ring-blue-300 focus-within:ring-opacity-40 dark:focus-within:border-blue-300">
                <div className="flex flex-nowrap justify-between md:flex-row md:text-center">
                  <input
                    type="email"
                    className="p-2 m-1 text-sm w-full md:w-auto text-gray-600 dark:text-gray-200 bg-transparent appearance-none focus:outline-none focus:placeholder-transparent"
                    placeholder="Password"
                    aria-label="Password"
                  />
                </div>
              </div>
              <div className="flex items-center">
                <button className="px-3 py-2 m-1 mt-10 text-sm font-medium tracking-wider text-white transition-colors duration-200 transform bg-blue-700 rounded-full hover:bg-gray-700 mx-auto w-full">
                  Login
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <section className="px-20 grid grid-cols-5 py-20"></section>
      <AppFooter />
    </div>
  );
}
