import React from "react";
import heroimg from "./../assets/heroimg.png";

const bg = {
  background:
    "linear-gradient(90deg, rgba(78,173,253,1) 0%, rgba(100,150,246,1) 100%)",
  overflow: "hidden",
};

const line = {
  width: 0,
  height: "100%",
  bottom: 0,
  borderBottom: "250px solid #EAF3FD",
  borderRightWidth: "98vw",
  borderRightStyle: "solid",
  borderRightColor: "transparent",
  left: 0,
  right: 0,
  top: 0,
  position: "absolute",
  zIndex: "1",
};

export default function Hero() {
  return (
    <div style={bg}>
      <img
        src={heroimg}
        className="absolute top-80 md:top-40 md:right-0 md:w-2/3 md:w-fit"
        alt="/"
      />
      <section
        id="hero"
        className="md:grid md:grid-cols-2 gap-4 items-center container px-6 py-10 mx-auto md:pb-10 h-[91vh]"
      >
        <div
          id="triangle-topleft"
          className="hidden md:block"
          style={line}
        ></div>
        <div className="... ">
          <div className="flex flex-col static text-gray-200 text-2xl md:text-4xl xl:text-6xl 2xl:text-6xl md:mr-20 ">
            <div className="flex flex-wrap md:justify-start justify-center drop-shadow-xl subpixel-antialiased"></div>
            <p className="text-2xl md:text-5xl lg:text-8xl font-medium inline-block align-text-bottom text-center md:text-left">
              SmartTicket
            </p>
            {/* <p className="text-2xl md:text-5xl font-medium inline-block align-text-bottom">Wrap your tickets in NFTs.</p> */}
            <p className="mt-4 md:mt-10 md:place-self-start text-xl md:text-2xl font-base text-center md:text-left">
              NFT Technology solutions for events
            </p>
            {/* <p className="md:place-self-start text-xl md:text-2xl font-base">
              First Access NFTs and Smart Tickets.
            </p> */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mx-20 md:mx-0 md:p-2 pl-0 mt-4 md:mt-10 mb-5 md:w-2/5 z-20">
              <button
                className="bg-[#7048E8] hover:shadow-md text-white font-semibold rounded-full transition-all duration-300 py-1 shadow-md hover:opacity-50"
                onClick={() => {
                  window.location.href = "/resources";
                }}
              >
                <p className="text-lg md:text-lg ">Learn More</p>
              </button>
            </div>
          </div>
        </div>

        <div className="grid"></div>
      </section>
    </div>
  );
}
