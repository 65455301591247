import React from "react";
import banner from "./../assets/hamilton-cards.png";

export default function Hero() {
  return (
    <section
      id="header"
      className="md:grid md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-2 items-center px-6 py-6 relative container mx-auto"
    >
      <div className="mb-24 lg:mb-0">
        <h1 className="dark:text-gray-200 text-gray-600 font-bold text-7xl text-center md:text-left">
          Engage <br />
          Fans with <br />
          Digital <br />
          Collectibles
        </h1>
      </div>
      <div className="flex text-center justify-center md:justify-start">
        <img src={banner} alt="Banner" />
      </div>
    </section>
  );
}
