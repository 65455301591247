import React, { useRef, useContext } from "react";
import logo_dark from "./../components/assets/logo/logo_diamond_cap.png";
import logo_light from "./../components/assets/logo/logo_blue_cap.png";
import { UserContext } from "../App";

const AccountModal = ({ modal, setModal, theme, page, changePage }) => {
  const emailInput = useRef("");
  const passwordInput = useRef("");
  const confirmPasswordInput = useRef("");
  const usernameInput = useRef("");
  const fullNameInput = useRef("");
  const phoneNumberInput = useRef("");

  const { user, setUser } = useContext(UserContext);

  const handleLoginSubmit = (event) => {
    event.preventDefault();

    const url = "https://api1.vipnft.fan/api/auth/local";
    const fetchData = async () => {
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            identifier: emailInput.current,
            password: passwordInput.current,
          }),
        });
        const jsonResponse = await response.json();
        console.log(jsonResponse);
        const json = JSON.stringify(jsonResponse);
        console.log(json);
        handleErrorResponse(jsonResponse);

        setUser(json);

        localStorage.setItem("VIPNFT_user", json);
        //window.location.reload(true);
      } catch (error) {
        console.log("error", error);
        alert(error.message);
      }
    };
    fetchData();
  };

  const handleSignUpSubmit = (event) => {
    event.preventDefault();
    const splitName = fullNameInput.current.split(" ");
    const firstName = splitName[0];
    const lastName = splitName[1];

    const url = "https://api1.vipnft.fan/api/auth/local/register";
    const fetchData = async () => {
      try {
        if (confirmPasswordInput.current !== passwordInput.current) {
          throw new Error("ValidationError: Passwords must be the same.");
        }
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: usernameInput.current,
            email: emailInput.current,
            password: passwordInput.current,
            fistName: firstName,
            lastName: lastName,
            mobileNumber: phoneNumberInput.current,
          }),
        });

        const jsonResponse = await response.json();
        console.log(jsonResponse);

        handleErrorResponse(jsonResponse);
        window.location.reload(true);
      } catch (error) {
        console.log("error", error);
        alert(error.message);
      }
    };
    fetchData();
  };

  const handleErrorResponse = (jsonResponse) => {
    if ("error" in jsonResponse) {
      throw new Error(
        `${jsonResponse.error.name}: ${jsonResponse.error.message}`
      );
    }
  };

  if (page === "login") {
    return (
      <React.Fragment>
        <div
          id="defaultModal"
          tabindex="-1"
          className={
            modal +
            " overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full justify-center items-center flex"
          }
          aria-modal="true"
          role="dialog"
        >
          <div className="relative p-4 w-full max-w-md h-full md:h-auto">
            <div className="relative rounded-lg shadow bg-white">
              <button
                type="button"
                className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                data-modal-toggle="authentication-modal"
                onClick={() =>
                  setModal(modal === "hidden" ? "block" : "hidden")
                }
              >
                <svg
                  aria-hidden="true"
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
              <div className="py-6 px-6 lg:px-8">
                <div className="flex items-center font-normal justify-center">
                  {/* <IoTicket /> */}
                  <img
                    src={theme === "dark" ? logo_dark : logo_light}
                    alt="VIPNFT"
                    className=" h-12"
                  />
                  {/* VIPNFT */}
                </div>
                <h3 className="text-xl font-medium dark:text-gray-900 text-white text-center">
                  Welcome
                </h3>
                <div className="text-sm font-medium text-center text-gray-500 dark:text-gray-600 mb-4">
                  Please login to proceed to{" "}
                  <a
                    href="#"
                    className="text-blue-700 hover:underline dark:text-blue-500"
                  >
                    VIPNFT
                  </a>
                </div>
                <form className="space-y-6" onSubmit={handleLoginSubmit}>
                  <div>
                    <label
                      htmlFor="email"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-600"
                    >
                      Email Address
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-white dark:border-gray-500 dark:placeholder-gray-400 dark:text-gray-600"
                      placeholder="name@company.com"
                      required
                      onChange={(e) => (emailInput.current = e.target.value)}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="password"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-600"
                    >
                      Password
                    </label>
                    <input
                      type="password"
                      name="password"
                      id="password"
                      placeholder="••••••••"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-white dark:border-gray-500 dark:placeholder-gray-400 dark:text-gray-600"
                      required
                      onChange={(e) => (passwordInput.current = e.target.value)}
                    />
                  </div>
                  <div className="flex justify-between">
                    <a
                      href="#"
                      className="text-sm text-blue-700 hover:underline dark:text-blue-500 mb-8"
                    >
                      Forgot Password?
                    </a>
                  </div>
                  <button
                    type="submit"
                    className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    Continue
                  </button>
                  <div className="text-sm font-medium text-gray-500 dark:text-gray-600">
                    Don't have an account?{" "}
                    <a
                      href="#"
                      className="text-blue-700 hover:underline dark:text-blue-500"
                      onClick={() => changePage("register")}
                    >
                      Sign up
                    </a>
                  </div>
                  <div className="text-sm font-medium text-center text-gray-500 dark:text-gray-300">
                    OR
                  </div>
                  <button
                    type="submit"
                    onClick={() => window.open("/dashboard/overview", "_blank")}
                    className="w-full border-solid border duration-300 hover:text-white text-black bg-white hover:bg-red-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                  >
                    See the dashboard
                  </button>
                  <button
                    type="submit"
                    onClick={() => window.alert("Coming Soon!")}
                    className="w-full text-white border-solid border duration-300 border-black hover:border-gray-200 bg-black hover:bg-white hover:text-black focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-black dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    Continue with Apple
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  if (page === "register") {
    return (
      <React.Fragment>
        <div
          id="defaultModal"
          tabIndex="-1"
          className={
            modal +
            " overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full justify-center items-center flex"
          }
          aria-modal="true"
          role="dialog"
        >
          <div className="relative p-4 w-full max-w-xl h-full md:h-auto">
            <div className="relative bg-gray-700 rounded-lg shadow dark:bg-white">
              <button
                type="button"
                className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                data-modal-toggle="authentication-modal"
                onClick={() =>
                  setModal(modal === "hidden" ? "block" : "hidden")
                }
              >
                <svg
                  aria-hidden="true"
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
              <div className="py-6 px-6 lg:px-8">
                <div className="flex items-center font-normal justify-center">
                  {/* <IoTicket /> */}
                  <img
                    src={theme === "dark" ? logo_dark : logo_light}
                    alt="VIPNFT"
                    className=" h-12"
                  />
                  {/* VIPNFT */}
                </div>
                <h3 className="text-xl font-medium dark:text-gray-900 text-white text-center">
                  Welcome
                </h3>
                <div className="text-sm font-medium text-center text-gray-500 dark:text-gray-600 mb-4">
                  Please login to proceed to{" "}
                  <a
                    href="#"
                    className="text-blue-700 hover:underline dark:text-blue-500"
                  >
                    VIPNFT
                  </a>
                </div>
                <form className="space-y-6" onSubmit={handleSignUpSubmit}>
                  <div className="grid grid-cols-2 gap-4 mt-8 mb-16">
                    <div>
                      <label
                        for="text"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-600"
                      >
                        Full Name*
                      </label>
                      <input
                        type="text"
                        name="text"
                        id="text"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-white dark:border-gray-500 dark:placeholder-gray-400 dark:text-gray-600"
                        placeholder="John Smith"
                        required
                        onChange={(e) =>
                          (fullNameInput.current = e.target.value)
                        }
                      />
                    </div>
                    <div>
                      <label
                        for="password"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-600"
                      >
                        Phone Number*
                      </label>
                      <input
                        type="password"
                        name="password"
                        id="password"
                        placeholder="••••••••"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-white dark:border-gray-500 dark:placeholder-gray-400 dark:text-gray-600"
                        required
                        onChange={(e) =>
                          (phoneNumberInput.current = e.target.value)
                        }
                      />
                    </div>

                    <div>
                      <label
                        for="text"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-600"
                      >
                        Username*
                      </label>
                      <input
                        type="text"
                        name="text"
                        id="text"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-white dark:border-gray-500 dark:placeholder-gray-400 dark:text-gray-600"
                        placeholder="Username"
                        required
                        onChange={(e) =>
                          (usernameInput.current = e.target.value)
                        }
                      />
                    </div>
                    <div>
                      <label
                        for="password"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-600"
                      >
                        Password*
                      </label>
                      <input
                        type="password"
                        name="password"
                        id="password"
                        placeholder="••••••••"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-white dark:border-gray-500 dark:placeholder-gray-400 dark:text-gray-600"
                        required
                        onChange={(e) =>
                          (passwordInput.current = e.target.value)
                        }
                      />
                    </div>

                    <div>
                      <label
                        for="email"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-600"
                      >
                        Email Address*
                      </label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-white dark:border-gray-500 dark:placeholder-gray-400 dark:text-gray-600"
                        placeholder="name@company.com"
                        required
                        onChange={(e) => (emailInput.current = e.target.value)}
                      />
                    </div>
                    <div>
                      <label
                        for="password"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-600"
                      >
                        Confirm Password*
                      </label>
                      <input
                        type="password"
                        name="password"
                        id="password"
                        placeholder="••••••••"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-white dark:border-gray-500 dark:placeholder-gray-400 dark:text-gray-600"
                        required
                        onChange={(e) =>
                          (confirmPasswordInput.current = e.target.value)
                        }
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-2 gap-4 mt-8 mb-16">
                    <div>
                      <button
                        type="submit"
                        className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                      >
                        Continue
                      </button>
                    </div>

                    <div>
                      <button
                        type="submit"
                        className="w-full border-solid border-2 dark:border-gray-900  text-black bg-white hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-white dark:focus:ring-blue-800"
                      >
                        Continue with Google
                      </button>
                    </div>

                    <div>
                      <div className="text-sm font-medium text-gray-500 dark:text-gray-600">
                        Already have an account?{" "}
                        <a
                          href="#"
                          className="text-blue-700 hover:underline dark:text-blue-500"
                          onClick={() => changePage("login")}
                        >
                          {" "}
                          Log in
                        </a>
                      </div>
                    </div>

                    <div>
                      <button
                        type="submit"
                        className="w-full text-white bg-black hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-black dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                      >
                        Continue with Apple
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  if (page === "fingerprint") {
    return (
      <React.Fragment>
        <div
          id="defaultModal"
          tabindex="-1"
          className={
            modal +
            " overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full justify-center items-center flex"
          }
          aria-modal="true"
          role="dialog"
        >
          <div className="relative p-4 w-full max-w-md h-full md:h-auto">
            <div className="relative bg-gray-700 rounded-lg shadow dark:bg-white">
              <button
                type="button"
                className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                data-modal-toggle="authentication-modal"
                onClick={() =>
                  setModal(modal === "hidden" ? "block" : "hidden")
                }
              >
                <svg
                  aria-hidden="true"
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
              <div className="py-6 px-6 lg:px-8">
                <div className="flex items-center font-normal justify-center">
                  {/* <IoTicket /> */}
                  <img
                    src={theme === "dark" ? logo_dark : logo_light}
                    alt="VIPNFT"
                    className=" h-12 mb-12"
                  />
                  {/* VIPNFT */}
                </div>
                <h3 className="text-xl font-medium dark:text-gray-900 text-white text-center">
                  Use Fingerprint
                </h3>
                <h3 className="text-xl font-medium dark:text-gray-900 text-white text-center">
                  or Face Recognition to
                </h3>
                <h3 className="text-xl font-medium dark:text-gray-900 text-white text-center">
                  Verify Your Identity
                </h3>

                <div className="text-sm font-medium text-center text-gray-500 dark:text-gray-600 my-8">
                  Follow your browser's steps to continue to{" "}
                  <a
                    href="#"
                    className="text-blue-700 hover:underline dark:text-blue-500"
                  >
                    VIPNFT
                  </a>
                </div>
                <div className="space-y-6 mt-16">
                  <button
                    type="submit"
                    className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    Continue
                  </button>
                  <div className="text-sm font-medium text-gray-500 dark:text-gray-600">
                    Don't have an access?{" "}
                    <span className="font-bold inline">
                      Use email or phone instead
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
};

export default AccountModal;
