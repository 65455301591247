import React, { useState } from "react";
import img_cards from "./assets/subscribe.png";

export default function SubscriberForm() {
  const [email, setEmail] = useState("");

  const isValidEmail = (email) => {
    // Check if the email is valid using a regex
    const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return pattern.test(email);
  };

  const handleSubscription = async () => {
    if (email && isValidEmail(email)) {
      // URL for the Google Cloud Function
      const url =
        "https://australia-southeast1-smart-ticket-394606.cloudfunctions.net/smart-ticket-prod-set-email-nt";
      const data = { email: email };

      try {
        const response = await fetch(url, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        });

        if (response.ok) {
          // Handle success
          alert("Subscription successful");
        } else {
          // Handle error
          alert("User already registered");
        }
      } catch (err) {
        console.log("Error:", err);
      }
    } else {
      // Handle invalid email input
      alert("Please enter a valid email address");
    }
  };

  return (
    <section
      id="header"
      className="md:grid md:grid-cols-1 xl:grid-cols-2 gap-4 items-center container px-6 py-10 mx-auto mt-[600px] md:mt-0 mb-48">
      <div className="...">
        <img src={img_cards} alt="Angled Cards" />
      </div>
      <div className="... ">
        <div className="md:text-6xl text-2xl mt-8 md:mt-0 space-y-5 font-normal text-black dark:text-gray-200 text-center lg:text-left">
          <p className="font-bold">Subscribe Now!</p>
          <p className="text-xl md:text-2xl font-base">
            Never miss your favourite event
          </p>
        </div>
        <div className="bg-white/10 rounded-lg border dark:border-0 border-gray-400 mt-16 mx-auto lg:mx-0 focus-within:ring w-10/12 lg:w-8/12 focus-within:border-blue-400 focus-within:ring-blue-300 focus-within:ring-opacity-40 dark:focus-within:border-blue-300">
          <div className="flex flex-nowrap justify-between md:flex-row md:text-center">
            <input
              type="email"
              className="p-2 m-1 text-sm w-full md:w-auto text-gray-600 dark:text-gray-200 bg-transparent appearance-none focus:outline-none focus:placeholder-transparent"
              placeholder="Enter your email"
              aria-label="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button
              className="px-3 md:px-6 py-2 m-1 text-sm font-medium tracking-wider text-white transition-colors duration-200 transform bg-gradient-to-r from-[#2a4dd0] to-[#6e8cff] rounded-lg w-auto"
              onClick={handleSubscription}>
              Subscribe
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}
