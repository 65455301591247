import React from "react";
import dualipa from "./../assets/dualipa.png";
import ticketek from "./../assets/ticketek_logo.png";
import herobg from "./../assets/hero_bg_ticketek.png";

export default function HeroPage() {
  return (
    <section
      id="header"
      className="md:grid md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-2 items-center px-6 py-32 bg-[#011728] relative"
    >
      <img src={herobg} className="absolute bottom-0 right-0 hidden md:block" />
      <div className="flex text-center justify-center md:justify-start">
        <img src={dualipa} alt="Dua Lipa" />
      </div>
      <div className="order-las md:order-first">
        <h1 className="text-gray-200 font-bold text-6xl text-center md:text-right">
          VIP Experience
        </h1>
        <h1 className="text-gray-200 font-bold text-6xl text-center md:text-right">
          {" "}
          Money Can't Buy
        </h1>
        <p className="text-gray-200 font-bold text-3xl flex gap-4 justify-center md:justify-end mt-8">
          by <img src={ticketek} alt="Dua Lipa" />
        </p>
        <div className="flex gap-8 justify-center md:justify-end mt-24">
          <div className="...">
            <p className="text-yellow-500 font-semibold text-4xl">10K+</p>
            <p className="text-gray-200 font-semibold text-3xl">Passes</p>
          </div>
          <div className="...">
            <p className="text-yellow-500 font-semibold text-4xl">70K+</p>
            <p className="text-gray-200 font-semibold text-3xl">Solds</p>
          </div>
          <div className="...">
            <p className="text-yellow-500 font-semibold text-4xl">15K+</p>
            <p className="text-gray-200 font-semibold text-3xl">NFTs</p>
          </div>
        </div>
      </div>
    </section>
  );
}
