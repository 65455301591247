import React, { useState, useContext, useEffect } from "react";
import AppNavbar from "../../components/app-navbar";
import AppFooter from "../../components/footer";
import ArticleList from "./components/articlelist";
import Article from "./components/article";
import Request from "./components/request";
import Relatedarticle from "./components/relatedarticle";
import StickyBox from "react-sticky-box";
import img_background_light from "../../components/assets/backgrounds/Bg-light2.png";
import img_background_dark from "./assets/background/ResourcePageBackground-dark6.png";
import { ThemeContext } from "../../App";
import { toBeInTheDocument } from "@testing-library/jest-dom/dist/matchers";
import Helmet from "./../../components/helmet";
import article from "./articledata.json";
import axios from "axios";
import { GiConsoleController } from "react-icons/gi";
import AccountModal from "./../../components/account-modal"

export default function ResourcesPage() {
  const { theme } = useContext(ThemeContext);
  const [articles, setArticles] = useState("");
  const [grouparticles, setGrouparticles] = useState("");
  const [title, setTitle] = useState("VIPNFT FAQ");
  const [description, setDescription] = useState(
    "We are creating new use cases for NFTs to allow fans, artists and event organisers to create additional value for all parties. Persons investing in these products should seek independent legal, financial and specialist advice."
  );

  const [modal, setModal] = useState("hidden");
  const [page, setPage] = useState("login");

  function changePage(page) {
    setPage(page);
  }

  useEffect(() => {
    const url = "https://api1.vipnft.fan/api/resources-articles?sort=id";

    const fetchData = async () => {
      try {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + process.env.REACT_APP_ARTICLE_KEY,
          },
        });
        const json = await response.json();
        setArticles(json.data);
      } catch (error) {
        console.log("error", error);
      }
    };

    const urlGroupArticle =
      "https://api1.vipnft.fan/api/resources-groups?fields=group&populate[resources_articles][fields]=title,article&sort=level";
    const fetchGroupArticle = async () => {
      try {
        const response = await fetch(urlGroupArticle, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + process.env.REACT_APP_ARTICLE_KEY,
          },
        });
        const json = await response.json();
        setGrouparticles(json.data);
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchData();
    fetchGroupArticle();
  }, []);

  function clickArticle([title, description]) {
    setTitle(title);
    if (!description) {
      setDescription(
        Array.from(articles).find((x) => x.attributes.title === title)
          .attributes.article
      );
    } else {
      setDescription(description);
    }
  }

  const articleList = Array.from(articles).map(({ attributes }) => (
    <div key={attributes.title.replace(" ", "").toLowerCase()}>
      <ArticleList
        title={attributes.title}
        onChildClick={(e) =>
          clickArticle([attributes.title, attributes.description])
        }
      />
    </div>
  ));

  const groupArticleList = Array.from(grouparticles).map(({ attributes }) => {
    return (
      <div>
        <h1 className="text-xl font-bold dark:text-gray-200 text-gray-600 mt-8">
          {attributes.group}
        </h1>

        {Array.from(attributes.resources_articles.data).map(
          ({ attributes }) => {
            return (
              <div key={attributes.title.replace(" ", "").toLowerCase()}>
                <ArticleList
                  title={attributes.title}
                  onChildClick={(e) =>
                    clickArticle([attributes.title, attributes.description])
                  }
                />
              </div>
            );
          }
        )}
      </div>
    );
  });

  const articleListDropdown = Array.from(articles).map(({ attributes }) => (
    <option
      value={attributes.title}
      key={attributes.title.replace(" ", "").toLowerCase()}
    >
      {attributes.title}
    </option>
  ));

  return (
    <div
      className={`pt-32 overflow-hidden ${theme === "dark" ? "dark" : "light"
        } transition-all duration-300`}
      style={{
        backgroundImage:
          theme === "dark"
            ? `url(${img_background_dark})`
            : `url(${img_background_light})`,
        backgroundSize: "110%",
        width: "100vw",
        minWidth: "100vw",
      }}
    >
      <div className="bg-yellow-500s">
        <Helmet title={"VIP NFT | FAQS"} />
        <AppNavbar openModal={setModal} modal={modal} />
        <AccountModal
          modal={modal}
          setModal={setModal}
          theme={theme}
          page={page}
          changePage={changePage}
        />
        <div className={modal === "hidden" ? "..." : "modal-bg-blur"}>
          <section
            id="hero"
            className="grid grid-cols-2 container mx-auto mb-4 p-4 px-20"
          >
            <div className="col-start-1 col-end-3">
              <a
                href="/"
                className="font-bold text-md text-indigo-400 hover:text-blue-600"
              >
                Home
              </a>
              <span className="dark:text-gray-200 text-gray-600"> • </span>
              <a
                href="/faqs"
                className="font-bold text-md text-indigo-400 hover:text-blue-600"
              >
                Faqs
              </a>
            </div>
          </section>

          <section
            id="header"
            className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-3 container mx-auto mb-4 gap-0 px-14"
          >
            <div className="p-4 block md:hidden xl:hidden">
              <select
                name="cars"
                id="cars"
                onChange={(e) => clickArticle([e.target.value])}
                className="w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 dark:bg-gray-900/50 dark:text-gray-200"
              >
                {articleListDropdown}
              </select>
            </div>
            <div className="p-4 hidden md:block xl:block">
              <StickyBox offsetTop={120} offsetBottom={120}>
                <div className="p-2">
                  <h1 className="text-sm font-bold dark:text-gray-200 text-gray-600">
                    Articles in this section
                  </h1>
                  <div className="max-w-sm mt-2 mb-2">{groupArticleList}</div>
                </div>
              </StickyBox>
            </div>
            <div className="p-4 col-span-2">
              <Article title={title} description={description} />
              <Request />
              <Relatedarticle />
            </div>
          </section>
        </div>
        <AppFooter />
      </div>
    </div>
  );
}
