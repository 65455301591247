import { ethers } from "ethers";

export const formatDateTime = (timestamp) => {
  const date = new Date(timestamp * 1000);
  return date.toLocaleString();
};

export const formatDate = (timestamp) => {
  const date = new Date(timestamp * 1000);
  return date.toLocaleDateString();
};

export const formatEtherValue = (weiValue) => {
  const ethValue = ethers.utils.formatEther(weiValue);
  return `${ethValue} ETH`;
};

export const shortenAddress = (address) => {
  if (address && address.length > 6) {
    return `${address.substring(0, 6)}...${address.substring(address.length - 4)}`;
  }
  return address;
};