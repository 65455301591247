import React from "react";
import solution1 from "./../assets/solution1.png";

const line2 = {
  width: 0,
  height: "100%",
  bottom: 0,
  borderBottom: "250px solid #7388F2",
  borderRightWidth: "98vw",
  borderRightStyle: "solid",
  borderRightColor: "transparent",
  left: 0,
  right: 0,
  top: 0,
  position: "absolute",
  zIndex: "1",
};

const dot = {
  height: "25px",
  width: "25px",
  backgroundColor: "#7048E7",
  borderRadius: "50%",
  display: "inline-block",
};

export default function Solution() {
  return (
    <div className="bg-[#EAF3FD] blocked relative">
      <section
        id="hero"
        className="items-center container px-6 py-10 mx-auto pb-40 md:h-[91vh]"
      >
        <img
          src={solution1}
          className="absolute right-0 -bottom-44 z-10"
          alt="/"
        />
        <div style={line2} className="hidden md:block"></div>

        <p className="text-3xl md:text-4xl font-bold text-center text-gray-600">
          Our Solution
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-20 max-w-7xl mx-auto mt-10">
          <div className="bg-gradient-to-r from-[#A591F1] to-[#7953E9]  p-10 text-white rounded-3xl shadow-lg">
            <div className="flex justify-left text-xl gap-2 font-semibold">
              <div style={dot}></div>
              <p>VIPNFT (First Access Rights) </p>
            </div>
            <p className="mt-4 ml-8">
              Create your VIPNFT for dedicated fans, so they never miss their
              favorite event again! Build stronger connections between fans and
              artists through "ownership", not just membership.
              <br />
              <br />
              Unlock early ticket access, VIP-NFT-only offers. Increase the
              value of this asset through interaction with artists, gamification
              and unique digital content.
            </p>
          </div>
          <div className="bg-gradient-to-r from-[#A591F1] to-[#7953E9]  p-10 text-white rounded-3xl shadow-lg">
            <div className="flex justify-left text-xl gap-2 font-semibold">
              <div style={dot}></div>
              <p>SmartTicket (Digital Collectable)</p>
            </div>
            <p className="mt-4 ml-8">
              Future proof your ticket offerings through blockchain secured
              tickets. Remove scalping and fraudulent ticket sales. Create
              lasting connections with fans, artists and event organisers
              through streamlined technology.
              <br />
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}
