import React from "react";
import pa from "./../assets/pasection.png";

export default function BenefitsPage() {
  return (
    <section className="mt-48 md:mt-24">
      <div>
        <h1 className="text-3xl font-bold text-center text-gray-600 dark:text-gray-200 lg:text-4xl mb-10 mt-44">
          Select your NFT. &nbsp;Select your level. <br />
          Define your VIP experience.
        </h1>
      </div>
      <div className="grid md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-2 items-center px-6 relative mx-auto">
        <div className="gap-8 grid px-4 lg:px-24 lg:mr-64">
          <p className="font-normal text-gray-600 dark:text-gray-300  text-xl">
            The Pacific Airshow NFTs have something for everyone. Ensuring you
            never miss this iconic event by guaranteeing you the right, but not
            the obligation, to purchase tickets for your preferred experience.
            <br />
            <br /> Purchase your preferred NFT, hold onto it, sell it or rent it
            out for periods you no longer wish to use it. Join us on this
            journey to enhance the fan experience and bring content ownership to
            true fans.
          </p>
        </div>
        <div className="order-last md:order-first flex text-center justify-center md:justify-end">
          <img src={pa} alt="Dua Lipa" className="w-2/3 h-2/3" />
        </div>
      </div>
      <div className="text-center my-32">
        <button
          className="bg-[#EF0255] p-4 text-white hover:border-2 border-white/70 border-2 font-semibold rounded-full transition-all duration-300 py-1 shadow-md hover:opacity-60"
          onClick={() => {
            window.location.href = "/explore";
          }}
        >
          <p className="text-lg md:text-lg ">Browse Collection</p>
        </button>
      </div>
    </section>
  );
}
