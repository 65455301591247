import React from "react";
import hotcollection1 from "./../assets/hotcollection1.png";
import hotcollection2 from "./../assets/hotcollection2.png";
import hotcollection3 from "./../assets/hotcollection3.png";
import hotcollection4 from "./../assets/hotcollection4.png";

export default function Hotcollections() {
  return (
    <div className="bg-white pt-20">
      <section
        id="collections"
        className="md:grid md:grid-cols-1 gap-4 items-center container px-6 py-2 mx-auto pb-40 my-30"
      >
        <div className="flex justify-center gap-4 items-center">
          <h1 className="text-[56px] text-black font-bold text-center">
            Hot Collections
          </h1>
        </div>

        <div className="grid grid-cols-4 gap-8 mt-20">
          <CollectionCards
            img={hotcollection1}
            title={"Pacific Airshow 2023"}
            desc={
              "With stunning visuals and immersive content and exclusive benefits, the Pacific Airshow VIP..."
            }
          />
          <CollectionCards
            img={hotcollection2}
            title={"Pacific Airshow 2023"}
            desc={
              "With stunning visuals and immersive content and exclusive benefits, the Pacific Airshow VIP..."
            }
          />
          <CollectionCards
            img={hotcollection3}
            title={"Pacific Airshow 2023"}
            desc={
              "With stunning visuals and immersive content and exclusive benefits, the Pacific Airshow VIP..."
            }
          />
          <CollectionCards
            img={hotcollection4}
            title={"Pacific Airshow 2023"}
            desc={
              "With stunning visuals and immersive content and exclusive benefits, the Pacific Airshow VIP..."
            }
          />
        </div>
      </section>
    </div>
  );
}

const CollectionCards = (props) => {
  return (
    <div
      className="h-[579px] relative rounded-xl p-6"
      style={{
        backgroundImage: `url(${props.img})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        width: "100%",
      }}
    >
      <div className=" bg-white absolute right-6 top-6 px-6 h-[37px] rounded-full">
        <p className="text-[#2645BB] mt-1 font-semibold">28 Items</p>
      </div>
      <div className="grid grid-cols-1 content-end h-full gap-4">
        <p className="text-[22px] text-white font-semibold">{props.title}</p>
        <p className="text-white text-[18px] font-extralight">{props.desc}</p>
        <p className="text-white text-[14px] font-semibold">View Collection</p>
      </div>
    </div>
  );
};
