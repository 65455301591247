import React from 'react'
import dualipa from "./../assets/dualipaqr.png"

export default function BenefitsPage() {
    return (
        <section className="mt-48 md:mt-24">
            <div>
                <h1 className="dark:text-gray-200 text-[#4C6474] font-bold text-4xl text-center mb-8">Different levels to cover what you need</h1>
            </div>
            <div className="grid md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-2 items-center px-6 relative mx-auto">
                <div className="gap-8 grid px-4 lg:px-24">
                    <p className="dark:text-gray-200 text-[#4C6474] font-normal text-2xl">Everyone has their own preference and need.
                        The Ticketek NFTs are built with different levels that have different perks and benefits, all to cover what you really need.</p>
                    <p className="dark:text-gray-200 text-[#4C6474] font-normal text-2xl">
                        With this, you can tailor what you really need, the benefits you want to get, and your contribution to your community.</p>
                </div>
                <div className="order-last md:order-first flex text-center justify-center md:justify-end"><img src={dualipa} alt="Dua Lipa" className="w-2/3 h-2/3" /></div>
            </div>
            <div className="text-center my-32">
                <button className="bg-[#011728] p-4 text-white hover:border-2 border-white/70 border-2 font-semibold rounded-full transition-all duration-300 py-1 shadow-md">
                    <p className="text-lg md:text-lg ">Browse Collection</p>
                </button>
            </div>
        </section>
    )
}
