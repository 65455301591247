import React from "react";
import contact1 from "./../assets/contact.png";

export default function Contact() {
  return (
    <section className="mb-32">
      <div className="grid md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-8 lg:gap-2 items-center px-6 relative container mx-auto">
        <div className="flex text-center justify-center md:justify-center">
          <img src={contact1} alt="Contact 1" className="" />
        </div>
        <div className="order-last lg:order-first gap-4 grid px-4 lg:px-24">
          <p className="text-2xl font-bold text-left dark:text-gray-200 text-gray-600">
            Need more info? We would love to hear from you.
          </p>

          <input
            type="email"
            className="p-2 text-sm w-full lg:w-auto text-gray-600 dark:text-gray-200 appearance-none focus:outline-none focus:placeholder-transparent bg-white/10 rounded border dark:border-0 border-gray-400 mx-auto lg:mx-0  focus-within:ring focus-within:border-blue-400 focus-within:ring-blue-300 focus-within:ring-opacity-40 dark:focus-within:border-blue-300"
            placeholder="Name"
            aria-label="Enter your email"
          />

          <input
            type="email"
            className="p-2 text-sm w-full lg:w-auto text-gray-600 dark:text-gray-200 appearance-none focus:outline-none focus:placeholder-transparent bg-white/10 rounded border dark:border-0 border-gray-400 mx-auto lg:mx-0  focus-within:ring focus-within:border-blue-400 focus-within:ring-blue-300 focus-within:ring-opacity-40 dark:focus-within:border-blue-300"
            placeholder="Email"
            aria-label="Enter your email"
          />

          <textarea
            type="email"
            className="p-2 text-sm w-full lg:w-auto text-gray-600 dark:text-gray-200 appearance-none focus:outline-none focus:placeholder-transparent bg-white/10 rounded border dark:border-0 md:h-40 border-gray-400 mx-auto lg:mx-0  focus-within:ring focus-within:border-blue-400 focus-within:ring-blue-300 focus-within:ring-opacity-40 dark:focus-within:border-blue-300"
            placeholder="Comment"
            aria-label="Enter your email"
          />

          <button className="px-3 py-2 text-sm font-medium tracking-wider text-white transition-colors duration-200 transform bg-blue-700 rounded w-auto hover:bg-gray-700">
            Send
          </button>
        </div>
      </div>
    </section>
  );
}
