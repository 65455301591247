import React, { useState, useEffect } from "react";

import { useContractEvents } from "@thirdweb-dev/react";
import { ethers } from "ethers";
import { formatEtherValue, shortenAddress, formatDate } from "./../utils/formatting.utils";

export default function Activity({ marketplace }) {

  const { data: events, isLoading: eventsLoading, error: eventErrors } = useContractEvents(marketplace, "NewSale");

  console.log("events: ", events);

  // Store the block timestamps for each event
  const [blockTimestamps, setBlockTimestamps] = useState([]);

  // Fetch the block timestamps for each event
  useEffect(() => {
    const fetchBlockTimestamps = async () => {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const timestamps = [];

      for (const event of events) {
        const block = await provider.getBlock(event.transaction?.blockNumber);
        timestamps.push(block.timestamp);
      }

      setBlockTimestamps(timestamps);
    };

    if (events && events.length > 0) {
      fetchBlockTimestamps();
    }
  }, [events]);

  return (
    <section className="container px-6 py-4 mt-4 mb-4 mx-auto">
      <div className="grid grid-cols-1 md:gap-8 mt-8 xl:mt-4 xl:gap-16 lg:grid-cols-4 xl:grid-cols-3">
        <div className="col-span-2">
          <div className="rounded-lg drop-shadow-2xl bg-white p-8 mt-8 xl:mt-4">
            <h1 className="font-bold text-xl text-[#2A4DD0] mb-4">
              Activity List
            </h1>

            <input
              type="text"
              name="filter"
              id="filter"
              autoComplete="off"
              placeholder="Filter"
              className="p-2 mb-2 mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />

            <table class="table-fixed w-full">
              <thead className="text-left">
                <tr className="bg-[#F2F2FF] text-xs lg:text-base">
                  <th className="p-4 rounded-t-lg">Event</th>
                  <th className="p-4">Price</th>
                  <th className="p-4">From</th>
                  <th className="p-4">To</th>
                  <th className="p-4 rounded-t-lg">Date</th>
                </tr>
              </thead>
              <tbody>
                {!eventsLoading &&
                  events &&
                  events.length > 0 &&
                  events.map((event, index) => (
                    <tr
                      className={index % 2 === 0 ? "text-xs lg:text-base" : "bg-[#F2F2FF] text-xs lg:text-base"}
                      key={index}
                    >
                      <td className="p-4">{event.eventName}</td>
                      <td>{formatEtherValue(event.data.totalPricePaid)}</td>
                      <td>{shortenAddress(event.data.lister.toString())}</td>
                      <td>{shortenAddress(event.data.buyer.toString())}</td>
                      <td>{formatDate(blockTimestamps[index])}</td>
                    </tr>
                  ))}
                {!eventsLoading && events && events.length === 0 && (
                  <tr>
                    <td>No events found.</td>
                  </tr>
                )}

                {/* Existing Static Code */}
                {/* <tr className="text-xs lg:text-base">
                  <td className="p-4">List</td>
                  <td>0.252 ETH</td>
                  <td>Guy Hawkins</td>
                  <td>Wade Warren</td>
                  <td>7 hours ago</td>
                </tr>
                <tr className="bg-[#F2F2FF] text-xs lg:text-base">
                  <td className="p-4">List</td>
                  <td>0.252 ETH</td>
                  <td>Guy Hawkins</td>
                  <td>Wade Warren</td>
                  <td>7 hours ago</td>
                </tr>
                <tr className="text-xs lg:text-base">
                  <td className="p-4">List</td>
                  <td>0.252 ETH</td>
                  <td>Guy Hawkins</td>
                  <td>Wade Warren</td>
                  <td>7 hours ago</td>
                </tr>
                <tr className="bg-[#F2F2FF] text-xs lg:text-base">
                  <td className="p-4">List</td>
                  <td>0.252 ETH</td>
                  <td>Guy Hawkins</td>
                  <td>Wade Warren</td>
                  <td>7 hours ago</td>
                </tr>
                <tr className="text-xs lg:text-base">
                  <td className="p-4">List</td>
                  <td>0.252 ETH</td>
                  <td>Guy Hawkins</td>
                  <td>Wade Warren</td>
                  <td>7 hours ago</td>
                </tr> */}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  );
}
