import React, { useContext, useState } from "react";
import AppNavbar from "../../components/app-navbar";
import Hero from "./components/hero";
import AppFooter from "../../components/footer";
import Benefits from "./components/benefits";
import img_background_light from "../../components/assets/backgrounds/Bg-light2.png";
import img_background_dark from "../../components/assets/backgrounds/Dark-desktop-1.png";
import SubscriberForm from "./../../components/subscriber-form";
import { ThemeContext } from "../../App";
import Helmet from "./../../components/helmet";
import AccountModal from "./../../components/account-modal";
import Collections from "./components/collections";
import Spotlight from "./components/spotlight";
import Howitworks from "./components/howitworks";
import Dynamic from "./components/dynamic";
import Vip from "./components/vip"

export default function HomePage() {
  const { theme, setTheme } = useContext(ThemeContext);
  setTheme("light");
  const [modal, setModal] = useState("hidden");
  const [page, setPage] = useState("login");

  function changePage(page) {
    setPage(page);
  }

  return (
    <React.Fragment>
      <div
        className={`pt-10 md:pt-32 overflow-hidden ${theme === "dark" ? "dark" : "light"
          } transition-all duration-300 `}
        style={{
          backgroundImage:
            theme === "dark"
              ? `url(${img_background_dark})`
              : `url(${img_background_light})`,
          backgroundSize: "cover",
          width: "100vw",
          minWidth: "100vw",
        }}
      >
        <Helmet title={"VIP NFT"} />
        <AppNavbar openModal={setModal} modal={modal} />
        <AccountModal
          modal={modal}
          setModal={setModal}
          theme={theme}
          page={page}
          changePage={changePage}
        />

        <div className={modal === "hidden" ? "..." : "modal-bg-blur"}>
          <Hero />
          <Collections />
          <Spotlight />
          <Howitworks />
          <Dynamic />
          <Benefits />
          <Vip />
          <SubscriberForm />
        </div>
        <AppFooter />
      </div>
    </React.Fragment>
  );
}
