import React, { useContext, useState } from "react";
import AppNavbar from "../../components/app-navbar";
import AppFooter from "../../components/footer";
import img_background_light from "../../components/assets/backgrounds/Bg-light2.png";
import img_background_dark from "../../components/assets/backgrounds/Dark-desktop-1.png";
import { ThemeContext } from "../../App";
import Helmet from "./../../components/helmet";
import Profile from "./../../components/profile";
import Notifications from "./components/notifications";
import Activity from "./components/activity";
import Total from "./components/total";
import Calendar from "./components/Calendar/calendar";
import ProfileResponsive from "../../components/profile-responsive";
import overviewbg from "./assets/overviewbg.png";
import { useParams } from "react-router-dom";

export default function OverviewPage() {
  const { theme, setTheme } = useContext(ThemeContext);
  setTheme("light");
  const [submenu, setSubmenu] = useState(false);
  const { param } = useParams();
  console.log("param", param);
  return (
    <div
      className={`pt-24 overflow-hidden ${
        theme === "dark" ? "dark" : "light"
      } transition-all duration-300`}
      style={{
        backgroundImage: `url(${overviewbg})`,
        backgroundSize: "cover",
        width: "100vw",
        minWidth: "100vw",
      }}
    >
      <Helmet title={"VIP NFT | Overview"} />
      <AppNavbar />
      <section className="container px-6 mb-8 mx-auto">
        <div className="grid grid-cols-1 md:gap-8 mt-8 xl:mt-4 xl:gap-16 lg:grid-cols-4 xl:grid-cols-4">
          <ProfileResponsive publicKey={param} />
          <Profile publicKey={param} />
          <div className="col-span-3 ...">
            <Notifications publicKey={param} />
            <div className="grid grid-cols-1 xl:grid-cols-2 mt-4">
              <div className="grid grid-cols-1 xl:grid-cols-1 mt-4 xl:mr-4 gap-4">
                <Activity />

                <Total />
              </div>
              <div className="grid grid-cols-1 xl:grid-cols-1 mt-4 xl:ml-4 gap-4">
                <Calendar />
              </div>
            </div>
          </div>
        </div>
      </section>

      <AppFooter />
    </div>
  );
}
