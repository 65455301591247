import React from "react";
import block1 from "./../assets/block1.png";
import block2 from "./../assets/block2.png";
import block3 from "./../assets/block3.png";
import block5 from "./../assets/block5.png";
import ci_white from "./../assets/final-white.png";
import ci_black from "./../assets/final-black.png";
import accessWhite from "./../assets/access-white.png";
import threeDLevels from "./../assets/3d-logo-w-diamond.png";

export default function blocks() {
  return (
    <section className=" mt-64 mb-24">
      <div>
        <h1 className="dark:text-gray-200 text-[#4C6474] font-bold text-4xl text-center mb-16 px-4 lg:px-24">
          Dynamic NFT layers to build your audience
        </h1>
      </div>
      <div className="grid md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-2 items-center px-6 relative container mx-auto">
        <div className="flex text-center justify-center md:justify-center">
          <img src={block1} alt="Block 1" className="" />
        </div>
        <div className="order-last md:order-first gap-8 grid px-4 lg:px-24 dark:text-gray-200 text-gray-600 ">
          <p className="text-2xl font-bold text-left">Unique Content</p>
          <p className="font-normal">
            The most fundamental layer of an NFT is its Digital Content. Each
            creator assigns ownership of this content to the buyer. This can be
            an image, video or 3D graphic. This unique content must be owned by
            the creator and is uploaded to decentralised storage. This ownership
            transfer through blockchain lies at the heart of NFT technology.
          </p>
        </div>
      </div>
      <div className="grid md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-2 items-center px-6 relative container mx-auto my-24">
        <div className="gap-8 grid px-4 lg:px-24">
          <p className="text-2xl font-bold text-left dark:text-gray-200 text-gray-600">
            Access
          </p>
          <p className="dark:text-gray-200 text-[#4C6474] font-normal">
            Most events have access rights to at least three categories, so we
            encourage creating first access rights to tickets using VIPNFT{" "}
            <span className="bg-[#E09932] rounded px-1 mx-0">Gold</span>,
            <span className="bg-[#C2D4DD] rounded px-1 mx-1 text-gray-800">
              Palladium
            </span>
            and
            <span className="bg-[#3DECFF] rounded px-1 mx-1 text-gray-800">
              Diamond
            </span>
            . Each creator determines additional benefits such as discounts and
            VIP access through these levels. This is only a suggestion, creators
            can define access based on their requirements.
          </p>
        </div>
        <div className="order-first flex text-center justify-center md:justify-center">
          <img src={threeDLevels} alt="Block 2" className="w-2/3 h-2/3" />
          {/* <img
            src={block2}
            alt="Block 2"
            className="w-2/3 h-2/3 lg:w-full lg:h-full block dark:hidden"
          />
          <img
            src={accessWhite}
            alt="Block 2"
            className="w-2/3 h-2/3 lg:w-full lg:h-full dark:block hidden"
          /> */}
        </div>
      </div>
      <div className="grid md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-2 items-center px-6 relative container mx-auto my-24">
        <div className="flex text-center justify-center md:justify-center">
          <img src={block3} alt="Block 3" className="" />
        </div>
        <div className="order-last md:order-first gap-8 grid px-4 lg:px-24">
          <p className="text-2xl font-bold text-left dark:text-gray-200 text-gray-600">
            Rewards and Gamification
          </p>
          <p className="dark:text-gray-200 text-[#4C6474] font-normal">
            Building a rewards system into this dynamic third layer of the NFT
            provides additional value for owners. There's almost no limit on how
            creators can utilize this mechanism to engage with their audience.
            Gamification through puzzles or treasure hunts, sweepstakes or any
            other enhanced customer experience is possible through this
            technology.
          </p>
        </div>
      </div>
      <div className="grid md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-2 items-center px-6 relative container mx-auto my-24">
        <div className="gap-8 grid px-4 lg:px-24">
          <p className="text-3xl font-bold text-left dark:text-gray-200 text-gray-600 lg:text-3xl">
            NFT Unlock
          </p>
          <p className="dark:text-gray-200 text-[#4C6474] font-normal">
            Create NFT-Gated pages or access points. Lock content away and only
            allow owners of NFTs to access unique content you have curated. This
            also applies to community chat forums and special offers. Stay tuned
            for more exciting developments as we build the mobile experience.
          </p>
        </div>
        <div className="order-first flex text-center justify-center md:justify-center">
          <img src={block5} alt="Block 2" className=" w-3/6" />
        </div>
      </div>
      <p className="text-4xl mt-64 mb-10 font-bold text-center dark:text-gray-200 text-gray-600">
        VIP NFT at a glance
      </p>
      <div className="container mx-auto text-center justify-center flex">
        <img
          src={ci_white}
          alt="Block 4"
          className="hidden dark:block w-8/12 text-center justify-center"
        />
        <img
          src={ci_black}
          alt="Block 4"
          className="block dark:hidden w-8/12 text-center justify-center"
        />
      </div>
    </section>
  );
}
