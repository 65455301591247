import React from 'react'

export default function list() {
    return (
        <React.Fragment>
            <ul class="list-none text-left my-16 px-8">
                <li>Up to 6 General Entry tickets per event</li>
                <li>NFT with unique Pacific Airshow content</li>
                <li>Private Chat for NFT holders only</li>
                <li>1 of only 400 Gold NFTs</li>
                <li>More exciting features to be revealed for these "Dynamic" NFTs.</li>
                <li>Upgrade to VIP Palladium for Premium Tickets</li>
            </ul>
            <div className="my-16">
                <p className="text-left px-8">Lorem ipsum dolor sit amet, consectitur adipisci elit, sed eismod tempor incidunt ut labore et dolore magna aliqua</p>
                <ul class="list-decimal text-left px-8">
                    <li>Ut enim ad minim veniam, quis nostrum</li>
                    <li>Ipsum dolor sit amet, consectitur adipsici elit</li>
                    <li>Ut enim ad minim veniam, quis nostrum</li>
                    <li>Tempor incidunt ut labore et dolore magna aliqua</li>
                </ul>
            </div>
        </React.Fragment>
    )
}
