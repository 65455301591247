import React, { useContext } from "react";
import { ThemeContext } from "../../App";
import AppNavbar from "../../components/app-navbar";
import img_background_light from "../../components/assets/backgrounds/Bg-light2.png";
import img_background_dark from "../../components/assets/backgrounds/Dark-desktop-1.png";
import img_ProfilePicture from "./assets/ProfilePicture.png";
import img_card_dua from "./assets/DuaLipaQR.png";
import AppFooter from "../../components/footer";
import { SiEthereum } from "react-icons/si";

export default function ProfilePage() {
  const { theme } = useContext(ThemeContext);
  return (
    <div
      className={`pt-16 overflow-hidden ${theme === "dark" ? "dark" : "light"
        } transition-all duration-300`}
      style={{
        backgroundImage:
          theme === "dark"
            ? `url(${img_background_dark})`
            : `url(${img_background_light})`,
        backgroundSize: "cover",
        width: "100vw",
        minWidth: "100vw",
        minHeight: "100vh",
      }}
    >
      <AppNavbar />
      <div className="relative">
        <div className="bg-gray-300 h-96 flex">
          <p className="mx-auto my-auto">Banner</p>
        </div>
        <img
          src={img_ProfilePicture}
          alt="/"
          className="absolute left-20 bottom-48 drop-shadow-lg z-10"
        />
        <div className="bg-gray-100 h-72 pt-28 pl-24 shadow-lg relative z-0 px-0 dark:bg-slate-800/50">
          <p className="text-2xl font-bold dark:text-gray-200">
            Anthony Ledger
          </p>
          <a
            className="w-fill flex p-3 pl-0 dark:text-white transition-all duration-300 text-xl"
            href="/"
          >
            <SiEthereum className="flex-none w-6 h-full" />
            <span className="ml-2 truncate">0x65fD...40Af</span>
            <span className="ml-10 truncate text-lg">Joined June 2022</span>
          </a>
          <div className="grid grid-cols-5 gap-72 absolute bottom-5 w-auto font-bold dark:text-gray-300">
            <button className="hover:text-white duration-300">Collected</button>
            <button className="hover:text-white duration-300">Created</button>
            <button className="hover:text-white duration-300">Favorited</button>
            <button className="hover:text-white duration-300">Activity</button>
            <button className="hover:text-white duration-300">More</button>
          </div>
        </div>
      </div>
      <section className="px-20 grid grid-cols-5 py-20">
        {Array.from({ length: 15 }, (_, i) => (
          <img
            src={img_card_dua}
            alt="/"
            className="hover:drop-shadow-lg shadow-blue-400 transition-all duration-300 hover:opacity-50"
          />
        ))}
      </section>
      <AppFooter />
    </div>
  );
}
