import React from "react";
import community1 from "./../assets/community1.png";
import heart1 from "./../assets/heart1.png";

const whiteDiv = {
  background:
    "linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.40379901960784315) 100%)",
};

export default function Community() {
  return (
    <div className="bg-[#7388F2] md:py-40">
      <img
        src={heart1}
        alt=""
        className="hidden md:block absolute left-80 z-10"
      />
      <section
        id="hero"
        className="items-center container mx-auto py-10 relative md:rounded-xl z-30"
        style={whiteDiv}
      >
        <div className="flex flex-col static text-white text-center text-2xl md:text-4xl xl:text-6xl 2xl:text-6xl md:mt-16 md:mr-20">
          <span className="mx-4 text-2xl md:text-5xl font-semibold">
            Create the ultimate NFT Ticketing solution with <br />
            VIPNFT and SmartTicket
          </span>

          <div className="grid grid-cols-1 grid-cols-2 p-2 pl-0 md:mt-10 mb-5 mx-auto gap-10">
            <button
              className="bg-[#4DB2FF] border-transparent hover:shadow-md text-white font-semibold rounded-full transition-all duration-300 py-1 shadow-md hover:opacity-50 p-5"
              onClick={() => {
                window.location.href = "/create";
              }}
            >
              <p className="text-lg md:text-lg ">Start Building</p>
            </button>
            <button
              className="bg-[#7048E8] border-transparent hover:shadow-md text-white font-semibold rounded-full transition-all duration-300 py-1 shadow-md hover:opacity-50 p-5"
              onClick={() => {
                window.location.href = "/resources";
              }}
            >
              <p className="text-lg md:text-lg ">Learn More</p>
            </button>
          </div>
        </div>
      </section>
    </div>
  );
}
