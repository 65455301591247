import "./App.css";
import React, { createContext, useMemo, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./pages/home";
import FaqsPage from "./pages/faqs";
import ExplorePage from "./pages/explore";
import OverviewPage from "./pages/overview";
import ProfilePage from "./pages/profile";
import CollectionsPage from "./pages/collections/collections";
import EventsPage from "./pages/events/events";
import SettingsPage from "./pages/settings";
import LoginPage from "./pages/auth/login";
import RegisterPage from "./pages/auth/register";
import TicketsocketPage from "./pages/creator/ticketsocket";
import PacificPage from "./pages/creator/pacific-airshow";
import CreatePage from "./pages/create";
import ApplyPage from "./pages/create/pages/apply";
import BuildPage from "./pages/build";
import NftoverviewPage from "./pages/vipnft";
import ComparisonPage from "./pages/comparison";
import PurklePage from "./pages/creator/purkle";
import TicketingPage from "./pages/ticketing";
import MarketplacePage from "./pages/marketplace";
import ListingPage from "./pages/marketplace/components/[listingId]";

export const ThemeContext = createContext();
export const UserContext = createContext();

function App() {
  const [theme, setTheme] = useState("light");
  const [user, setUser] = useState(null);
  const userValue = useMemo(() => ({ user, setUser }), [user, setUser]);

  return (
    <UserContext.Provider value={userValue}>
      <ThemeContext.Provider value={{ theme, setTheme }}>
        <BrowserRouter>
          <Routes>
            <Route path="/">
              <Route index element={<HomePage />} />
            </Route>
            <Route path="/explore">
              <Route index element={<ExplorePage />} />
            </Route>
            <Route path="/faqs">
              <Route index element={<FaqsPage />} />
            </Route>
            <Route path="/dashboard/overview/:param?">
              <Route index element={<OverviewPage />} />
            </Route>
            <Route path="/profile">
              <Route index element={<ProfilePage />} />
            </Route>
            <Route path="/dashboard/collections/:param?">
              <Route index element={<CollectionsPage />} />
            </Route>
            <Route path="/dashboard/events/:param?">
              <Route index element={<EventsPage />} />
            </Route>
            <Route path="/dashboard/settings/:param?">
              <Route index element={<SettingsPage />} />
            </Route>
            <Route path="/login">
              <Route index element={<LoginPage />} />
            </Route>
            <Route path="/register">
              <Route index element={<RegisterPage />} />
            </Route>
            <Route path="/creator/ticketsocket">
              <Route index element={<TicketsocketPage />} />
            </Route>
            <Route path="/creator/pacific-airshow">
              <Route index element={<PacificPage />} />
            </Route>
            <Route path="/create">
              <Route index element={<CreatePage />} />
            </Route>
            <Route path="/create/apply">
              <Route index element={<ApplyPage />} />
            </Route>
            <Route path="/create/build">
              <Route index element={<BuildPage />} />
            </Route>
            <Route path="/collection/:listingId">
              <Route index element={<NftoverviewPage />} />
            </Route>
            <Route path="/comparison">
              <Route index element={<ComparisonPage />} />
            </Route>
            <Route path="/creator/purkle">
              <Route index element={<PurklePage />} />
            </Route>
            <Route path="/ticketing">
              <Route index element={<TicketingPage />} />
            </Route>
            <Route path="/collection/pacific-airshow">
              <Route index element={<MarketplacePage />} />
            </Route>
            <Route path="/marketplace/listing/:listingId">
              <Route index element={<ListingPage />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </ThemeContext.Provider>
    </UserContext.Provider>
  );
}

export default App;
